import React, { useState } from "react";
import ModalComponent from "../modalcomponent/ModalComponent";
import { RecoverPasswordModalProps } from "../../../types/LoginTypes";
import { Row, Form, Col } from "react-bootstrap";
import * as formik from "formik";
import * as yup from "yup";

import Loader from "@/components/loader/Loader";

import { generateMaskedEmail } from "@/util/maskedEmail";
import { sendResetPasswordEmailAPI } from "@/util/http/auth/auth.index";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import EnvelopIcon from "../../../assets/envelop.svg";
import "./style.less";

type recoveryPasswordEmailValuesTypes = {
  email: string;
};

const RecoverPasswordModal = ({
  setOpenRecoverPasswordModal,
  openRecoverPasswordModal,
}: RecoverPasswordModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [recoveryEmail, setRecoveryEmail] = useState<string>("");

  const { Formik } = formik;

  const schema = yup.object().shape({
    email: yup.string().required("E-Mail-Adresse ist erforderlich"),
  });

  const sendRecoveryEmailHandler = async (values: recoveryPasswordEmailValuesTypes) => {
    sendRecoveryEmail(values.email);
  };

  const sendRecoveryEmail = async (email) => {
    setIsLoading(true);
    try {
      const res = await sendResetPasswordEmailAPI(email);
      if (res.status === 200) {
        setRecoveryEmail(email);
        setIsSuccess(true);
        setIsError(false);
      } else {
        setIsSuccess(false);
        setIsError(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        setIsError(true);
      }
      setIsLoading(false);
      logAPIErrorsFromUI(error);
    }
  };

  return (
    <ModalComponent
      modalShow={openRecoverPasswordModal}
      setModalShow={setOpenRecoverPasswordModal}
      title="Passwort wiederherstellen"
      redirectTo="/auth/login"
    >
      {isSuccess === false ? (
        <div className="recoverPassword-container-send-email">
          <div className="provide-email-text">
            Bitte geben Sie Ihre registrierte E-Mail-Adresse an und wir senden Ihnen eine E-Mail mit
            Anweisungen zum Zurücksetzen Ihres Passworts.
          </div>
          <Formik
            validationSchema={schema}
            onSubmit={(values) => sendRecoveryEmailHandler(values)}
            initialValues={{ email: "" }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="8" controlId="recover-email">
                    <Form.Control
                      size="sm"
                      required
                      type="email"
                      placeholder="Geben Sie Ihr E-Mail-Adresse"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!touched && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid" className="text-start">
                      <>{errors.email}</>
                    </Form.Control.Feedback>

                    {isError && (
                      <div className="error-message">Diese E-Mail-Adresse existiert nicht.</div>
                    )}
                  </Form.Group>
                </Row>

                <div className="button-container">
                  <button
                    className={`send-button ${!values.email ? "disable-button" : ""}`}
                    type="submit"
                    disabled={!values.email}
                  >
                    {isLoading ? <Loader /> : "Senden"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="recoverPassword-container-send-email-success">
          <div>
            <img src={EnvelopIcon} alt="email-verification icon" />
          </div>

          <div className="provide-email-text">
            Anweisungen zum Zurücksetzen Ihres Passworts haben Sie per E-Mail an{" "}
            {generateMaskedEmail(recoveryEmail)} erhalten. Bitte überprüfen Sie Ihren
            E-Mail-Posteingang!
          </div>

          <div className="content-2">
            <div className="didnt-receive-text">Sie haben die E-Mail nicht erhalten?</div>
            <button className="resend-btn" onClick={() => sendRecoveryEmail(recoveryEmail)}>
              {isLoading ? <Loader /> : "E-Mail erneut senden"}
            </button>
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

export default RecoverPasswordModal;
