import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

import { useAppSelector } from "@/redux/reduxHooks";

import PModelMap from "./googleMap/PModelMap";
import WeatherStationData from "./weatherStationData/WeatherStationData";
import SprayDistanceCalculator from "./sprayDistanceCalculator/SprayDistanceCalculator";
import CustomDropDown from "@/components/dropdowns/CustomDropDown";

import {
  getDiseaseListAPI,
  getListLocationDiseasesAPI,
  getNearbyStationTestAPI,
} from "@/util/http/home/home.index";
import {
  ISelectedGeoLocationType,
  ISelectedStationData,
  MarkerType,
  OptionType,
} from "@/types/HomeTypes";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import "./style.less";

const Home = () => {
  // const user = useAppSelector((state) => state.user);
  const geoDetails = useAppSelector((state) => state.geoDetails);

  const [options, setOptions] = useState<OptionType[]>();
  const [selectedStationData, setSelectedStationData] = useState<ISelectedStationData>({
    weatherStationName: "",
    stationId: null,
    latitude: null,
    longitude: null,
  });

  const [selectedTab, setSelectedTab] = useState<"1" | "2">(null);

  const [selectedOption, setSelectedOption] = useState<OptionType>();
  const [markers, setMarkers] = useState<MarkerType[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<MarkerType>();
  const [selectedGeoLocation, setSelectedGeoLocation] = useState<ISelectedGeoLocationType>({
    latitude: +geoDetails.latitude,
    longitude: +geoDetails.longitude,
    stationId: +geoDetails.stationId,
  });

  // const navigate = useNavigate();

  // useEffect(() => {
  //   const syngentaDetails = JSON.parse(localStorage.getItem("syngenta"));

  //   if (syngentaDetails?.userDetails?.email || user?.token) {
  //     navigate("/home", { replace: true });
  //   } else {
  //     navigate("/auth/login", { replace: true });
  //   }
  // }, []);

  const getDiseaseList = async () => {
    try {
      const res = await getDiseaseListAPI();

      const diseaseListArray: OptionType[] = res.data.map((disease) => ({
        id: disease.diseaseId,
        label: disease.diseaseName,
        value: disease.diseaseName,
      }));

      setOptions(diseaseListArray);
      setSelectedOption(diseaseListArray[0]);
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  const getListLocationDiseases = async () => {
    try {
      const attribute = selectedOption.id;
      const res = await getListLocationDiseasesAPI(attribute);
      const data: MarkerType[] = res.data;
      if (res.status == 200 && data.length > 0) {
        setMarkers(data);
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    getDiseaseList();
  }, []);

  useEffect(() => {
    if (!selectedOption) return;

    getListLocationDiseases();
    setSelectedMarker(null);
  }, [selectedOption]);

  useEffect(() => {
    if (!selectedTab) return;

    if (selectedTab === "1") {
      setSelectedOption(options.find((opt) => opt.id === 52));
    } else if (selectedTab === "2") {
      setSelectedOption(options.find((opt) => opt.id === 55));
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!geoDetails) return;

    setSelectedGeoLocation({
      latitude: +geoDetails.latitude,
      longitude: +geoDetails.longitude,
      stationId: +geoDetails.stationId,
    });
  }, [geoDetails]);

  useEffect(() => {
    if (!selectedMarker) return;

    const getNearbyStationTest = async () => {
      try {
        const res = await getNearbyStationTestAPI(
          selectedMarker.latitude,
          selectedMarker.longitude
        );
        if (res.status == 200 && res.data.latitude) {
          setSelectedGeoLocation({
            latitude: +selectedMarker.latitude,
            longitude: +selectedMarker.longitude,
            stationId: +res.data.geoReferenceID,
          });
        }
      } catch (error) {
        console.log(error);
        logAPIErrorsFromUI(error);
      }
    };

    getNearbyStationTest();
  }, [selectedMarker]);

  return (
    <div className="home-container">
      <div className="gmaps-header">
        <div className="gmaps-header-text">
          Bitte wählen Sie für die Kartenansicht Krankheit und Epidemiedruck oder Monitoring aus:
        </div>
        <div className="home-gmap-dropdown">
          <CustomDropDown
            options={options}
            onChange={(value) => setSelectedOption(value)}
            selectedOption={selectedOption}
          />
        </div>
      </div>

      <PModelMap
        markers={markers}
        setSelectedMarker={setSelectedMarker}
        selectedOption={selectedOption}
      />

      <div className="weather-station-data-div">
        <WeatherStationData
          selectedGeoLocation={selectedGeoLocation}
          setSelectedStationData={setSelectedStationData}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="spray-distance-calculator-div">
        <SprayDistanceCalculator selectedStationData={selectedStationData} />
      </div>
    </div>
  );
};

export default Home;
