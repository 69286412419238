import React from "react";
import { useNavigate } from "react-router-dom";

import Close from "../../../assets/close.svg";
import "./style.css";

type ModalComponentProps = {
  children: React.ReactNode;
  modalShow: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  size?: number;
  redirectTo?: string;
};

const ModalComponent = ({
  children,
  modalShow,
  setModalShow,
  title,
  size = 600,
  redirectTo = "",
}: ModalComponentProps) => {
  const navigate = useNavigate();

  if (!modalShow) return;

  return (
    <div className="custom-modal">
      <div className={`custom-modal-content ${size === 600 ? "w-600" : "w-800"}`}>
        <div className="custom-modal-header">
          <div className="custom-modal-title">{title}</div>
          <img
            src={Close}
            alt="close icon"
            className="close-btn"
            onClick={() => {
              setModalShow(false);
              if (redirectTo !== "") {
                navigate(redirectTo, { replace: true });
              }
            }}
          />
        </div>
        <div className="custom-modal-body">{children}</div>
      </div>
    </div>
  );
};

export default ModalComponent;
