import React from "react";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import "./style.less";

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
};

const formats = ["bold", "italic", "underline", "strike", "list", "bullet"];

type TextEditorPropTypes = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  fullHeight?: boolean;
  disableEditor?: boolean;
};

// react quill text editor
const TextEditor = ({
  value,
  setValue,
  fullHeight = false,
  disableEditor = false,
}: TextEditorPropTypes) => {
  return (
    <div
      className={`quill-text-editor ${fullHeight && "full-height"} ${
        disableEditor && "disable-custom-inp"
      }`}
    >
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
        className="editor-container"
        readOnly={disableEditor}
      />
    </div>
  );
};

export default TextEditor;
