import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./style.css";

type Tab = {
  title: string;
  value: string;
  content: React.ReactNode;
};

type TabsProps = {
  tabLinks: Tab[];
  pageSwitch: boolean;
};

const Tabs = ({ tabLinks, pageSwitch }: TabsProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<string>(
    tabLinks.find((link) => link.value === "login").value
  );

  const handleTabClick = (tab: Tab) => {
    setActiveTab(tab.value);

    if (pageSwitch) {
      navigate(`/auth/${tab.value}`, { replace: true });
    }
  };

  useEffect(() => {
    if (location.pathname === "/auth/login") {
      setActiveTab(tabLinks.find((link) => link.value === "login").value);
    } else if (location.pathname === "/auth/register") {
      setActiveTab(tabLinks.find((link) => link.value === "register").value);
    }
  }, [tabLinks]);

  return (
    <>
      <div className="tab">
        {tabLinks.map((tab, idx) => (
          <button
            key={idx}
            className={`tablinks${activeTab === tab.value ? " active" : ""}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab.title}
          </button>
        ))}
      </div>
    </>
  );
};

export default Tabs;
