// import marker_white from "../../../assets/markers/marker_white.svg";
import marker_green from "../../../assets/markers/marker_green.svg";
import marker_dark_green from "../../../assets/markers/marker_dark_green.svg";
import marker_yellow from "../../../assets/markers/marker_yellow.svg";
import marker_orange from "../../../assets/markers/marker_orange.svg";
import marker_red from "../../../assets/markers/marker_red.svg";

const epidemicPressureLegends = [
  // { key: "Kein", icon: marker_white, pressure: "0" },
  { key: "Sehr gering", icon: marker_green, pressure: "1" },
  { key: "Gering", icon: marker_dark_green, pressure: "2" },
  { key: "Mittel", icon: marker_yellow, pressure: "3" },
  { key: "Hoch", icon: marker_orange, pressure: "4" },
  { key: "Sehr hoch", icon: marker_red, pressure: "5" },
];

const phtMonitoringLegends = [
  { key: "Kein Befall", icon: marker_green, pressure: "0" },
  { key: "Befall in der Region", icon: marker_yellow, pressure: "2" },
  { key: "Befall im Spritzfenster", icon: marker_orange, pressure: "3" },
  { key: "Befall in Praxisflache", icon: marker_red, pressure: "5" },
];

const altMonitoringLegends = [
  { key: "Kein Befall", icon: marker_green, pressure: "0" },
  { key: "Geringer Befall", icon: marker_yellow, pressure: "2" },
  { key: "Mittlerer Befall", icon: marker_orange, pressure: "3" },
  { key: "Hoher Befall", icon: marker_red, pressure: "4" },
];

export const getMapLegends = (dropdownId: number) => {
  switch (dropdownId) {
    case 1000:
      return epidemicPressureLegends;
    case 1001:
      return epidemicPressureLegends;
    case 52:
      return phtMonitoringLegends;
    case 55:
      return altMonitoringLegends;
  }
};
