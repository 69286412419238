import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { loginUser } from "@/redux/reducers-or-slices/userSlice";
import { useAppSelector, useAppDispatch } from "@/redux/reduxHooks";

import LoginForm from "../../components/forms/login/LoginForm";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const syngentaUserDetails = JSON.parse(localStorage.getItem("syngenta"));

  // when user details are stored in localstorage, then redirect the user to home page
  useEffect(() => {
    if (syngentaUserDetails?.userDetails.email || syngentaUserDetails?.userDetails.token) {
      dispatch(loginUser(syngentaUserDetails.userDetails));
      navigate("/dashboard/users", { replace: true });
    }

    if (user.token || user.email) {
      navigate("/dashboard/users", { replace: true });
    }
  }, []);

  return (
    <>
      <LoginForm />
      <Outlet />
    </>
  );
};

export default Login;
