import ModalComponent from "../modalcomponent/ModalComponent";
import ProfileIcon from "../../../assets/profile.svg";

import { AccountActivationModalProps } from "../../../types/RegistrationTypes";

import "./style.css";

const AccountActivationModal = ({
  openAccountActivationModal,
  setOpenAccountActivationModal,
  firstName,
  lastName,
}: AccountActivationModalProps) => {
  return (
    <ModalComponent
      modalShow={openAccountActivationModal}
      setModalShow={setOpenAccountActivationModal}
      title=""
      redirectTo="/auth/login"
    >
      <div className="activation-content">
        <div className="activation-welcome-user">
          Willkommen {firstName} {lastName}! Ihr Konto ist aktiviert.{" "}
        </div>
        <div>
          <img src={ProfileIcon} alt="profile icon" />
        </div>
        <div>
          Ihr Konto wurde erfolgreich aktiviert. Sie können sich jetzt einloggen und den Service
          nutzen.
        </div>
      </div>
    </ModalComponent>
  );
};

export default AccountActivationModal;
