import React, { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";

import ModalComponent from "../modalcomponent/ModalComponent";
import { TandCModalProps } from "@/types/RegistrationTypes";
import Loader from "@/components/loader/Loader";

import "./style.less";

const TermsAndConditionsModal = ({
  openTandCModal,
  setOpenTandCModal,
  values,
  name,
  content,
  isFunctional = true,
}: TandCModalProps) => {
  const [termsText, setTermsText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const termsAndConditionsAcceptHandler: React.MouseEventHandler<HTMLButtonElement> = (): void => {
    // check both the checkboxes

    if (name === "openDPPModal") {
      values.dppCheckbox = true;
    }
    if (name === "openTandCModal") {
      values.tandcCheckbox = true;
    }

    //after few seconds, close the modal
    setTimeout(() => {
      setOpenTandCModal(false);
    }, 100);
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setTermsText(content);
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <ModalComponent
      modalShow={openTandCModal}
      setModalShow={setOpenTandCModal}
      title={name === "openTandCModal" ? "Allgemeine Geschäftsbedingungen" : "Datenschutzerklärung"}
      size={800}
    >
      <div className="terms-and-conditions-container">
        <div className="terms-header">
          <span className="header-text">
            {name === "openTandCModal"
              ? "Akzeptieren der allgemeinen Geschäftsbedingungen"
              : "Akzeptieren der Datenschutzerklärung"}
          </span>
          <hr className="terms-ruler" />
        </div>

        <div className="terms-content">
          <div
            className={`terms-content-text ${
              isLoading ? "h-100 d-flex justify-content-center align-items-center" : ""
            }`}
          >
            {isLoading && <Loader />}
            {!isLoading && HTMLReactParser(termsText)}
          </div>
        </div>
        <div className="terms-footer">
          <button className="close-button" onClick={() => setOpenTandCModal(false)}>
            Schließen
          </button>

          {isFunctional && (
            <button className="accept-button" onClick={termsAndConditionsAcceptHandler}>
              Annehmen
            </button>
          )}
        </div>
      </div>
    </ModalComponent>
  );
};

export default TermsAndConditionsModal;
