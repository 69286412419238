export const generateMaskedEmail = (email: string): string => {
  let maskedEmail = "";
  if (!email) return maskedEmail;

  const splitEmail = email.split("@");

  //first half
  const firstPart = splitEmail[0];
  maskedEmail += firstPart[0] + "*".repeat(firstPart.length - 1) + "@";

  //second half
  const secondPart = splitEmail[1];
  const secondDotPart = splitEmail[1].split(".");

  maskedEmail += secondPart[0] + "*".repeat(secondDotPart[0].length - 1);
  maskedEmail += "." + secondDotPart[1];

  return maskedEmail;
};
