import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from "axios";

import { API_BASE_URL, TIME_OUT } from "./config";

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "text/html,application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Credentials": true,
  "X-Requested-With": "XMLHttpRequest",
  "Access-Control-Allow-Origin": true,
};

enum ServerType {
  ServerNormal,
}

// code to accept token in req headers

class Http {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  private configRequestPayloadFormat = (
    config: InternalAxiosRequestConfig
  ): InternalAxiosRequestConfig => {
    try {
      const syngenta = JSON.parse(localStorage.getItem("syngenta"));
      const pToken = sessionStorage.getItem("pmodel-token");

      const token = syngenta?.userDetails?.token || pToken;

      config.headers.Authorization = `Bearer ${token}`;

      return config;
    } catch (error) {
      return error;
    }
  };

  private initHttp() {
    const baseURL = API_BASE_URL;

    const http = axios.create({
      baseURL,
      headers,
      // this properties should open after the login is finish
    });

    http.interceptors.request.use(this.configRequestPayloadFormat, (error) => {
      return Promise.reject(error);
    });

    http.defaults.timeout = TIME_OUT;
    this.instance = http;

    return http;
  }

  public get<T, R = AxiosResponse<T>>(url: string, config?: any): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  public post<T, R = AxiosResponse<T>>(url: string, config?: any): Promise<R> {
    return this.http.post<T, R>(url, config);
  }

  public put<T, R = AxiosResponse<T>>(url: string, config?: any): Promise<R> {
    return this.http.put<T, R>(url, config);
  }

  public delete<T, R = AxiosResponse<T>>(url: string, config?: any): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }
}

const http = new Http();

export { http, ServerType };
