import React from "react";

import Loader from "@/components/loader/Loader";

import "./style.less";

type RedirectComponentPropTypes = {
  count: number;
  label: string;
};

const RedirectComponent = ({ count, label }: RedirectComponentPropTypes) => {
  return (
    <div className="custom-redirect-background">
      <div className="custom-redirect-body">
        <Loader />
        <div className="loader-text">
          {label} Sie werden in {count} Sekunden zur Anmeldeseite weitergeleitet.
        </div>
      </div>
    </div>
  );
};

export default RedirectComponent;
