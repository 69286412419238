import React, { useEffect, useState } from "react";

import SprayCalculator from "./sprayCalculator/SprayCalculator";
import EpidemicPressureDevelopment from "./epidemicPressureDevelopment/EpidemicPressureDevelopment";
import InfestationMaps from "./infestationMaps/InfestationMaps";

import { IChartsType, IMapsType, ISelectedStationData } from "@/types/HomeTypes";
import { getMapAndChartImagesAPI } from "@/util/http/home/home.index";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import "./style.less";

type SprayDistanceCalculatorPropTypes = {
  selectedStationData: ISelectedStationData;
};

type ImageDataType = {
  charts: IChartsType;
  maps: IMapsType;
};

const SprayDistanceCalculator = ({ selectedStationData }: SprayDistanceCalculatorPropTypes) => {
  const [imageData, setImageData] = useState<ImageDataType>({
    charts: { phytophthora: { image: "", date: "" }, alternaria: { image: "", date: "" } },
    maps: { phytophthora: { image: "", date: "" }, alternaria: { image: "", date: "" } },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getImageFromDB = async () => {
    setIsLoading(true);
    try {
      // const resp = await getMapAndChartImagesAPI(selectedStationData.stationId);
      const resp = await getMapAndChartImagesAPI(
        selectedStationData.latitude,
        selectedStationData.longitude
      );

      if (resp.status === 200) {
        const { charts, maps } = resp.data;
        const data = {
          charts: {
            phytophthora: {
              image: charts?.phytophthora?.image || "",
              date: charts?.phytophthora?.date || "",
            },
            alternaria: {
              image: charts?.alternaria?.image || "",
              date: charts?.alternaria?.date || "",
            },
          },
          maps: {
            phytophthora: {
              image: maps?.phytophthora?.image || "",
              date: maps?.phytophthora?.date || "",
            },
            alternaria: {
              image: maps?.alternaria?.image || "",
              date: maps?.alternaria?.date || "",
            },
          },
        };
        setImageData(data);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    if (!selectedStationData.stationId) return;

    getImageFromDB();
  }, [selectedStationData.stationId]);

  return (
    <div className="spray-distance-calculator">
      <SprayCalculator />

      {/* showing epidemic pressure development charts  */}
      {imageData.charts?.phytophthora?.image && (
        <EpidemicPressureDevelopment
          selectedStationData={selectedStationData}
          chartsData={imageData?.charts}
          isLoading={isLoading}
        />
      )}

      {/* showing infestation maps  */}
      {imageData.maps?.phytophthora?.image && (
        <InfestationMaps mapsData={imageData?.maps} isLoading={isLoading} />
      )}
    </div>
  );
};

export default SprayDistanceCalculator;
