import React, { useEffect, useRef, useState } from "react";
import { Container, Nav, Navbar, Overlay, Popover } from "react-bootstrap";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/redux/reduxHooks";

import Footer from "@/components/footer/Footer";
import UserSettingsModal from "@/components/modals/userSettings/UserSettingsModal";
import RedirectComponent from "@/components/redirectComp/RedirectComponent";

import { loginUser, logoutUser } from "@/redux/reducers-or-slices/userSlice";
import { getGeoDetails, resetGeoDetails } from "@/redux/reducers-or-slices/geoDetailsSlice";
import { verifyIsUserEmailExistsAPI } from "@/util/http/auth/auth.index";
import { getGeoDetailsByPostalCodeAPI } from "@/util/http/emailTemplate/emailTemplate.index";
import { getDiseaseListAPI } from "@/util/http/home/home.index";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import PModelBannerSmall from "../../../assets/pmodel-banner-small.svg";
import SettingsIcon from "../../../assets/gear.svg";
import "./style.less";

const HomeLayout = () => {
  const cnt = 6;
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [target, setTarget] = useState(null);
  const [redirectCount, setRedirectCount] = useState<number>(cnt);
  const ref = useRef(null);

  const [show, setShow] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);
  const syngentaDetails = JSON.parse(localStorage.getItem("syngenta"));

  let interval = null;

  const getGeoDetailsByPostalCode = async (postalCode: string) => {
    try {
      // console.log("getGeoDetailsByPostalCodeAPI called");
      const resp = await getGeoDetailsByPostalCodeAPI(postalCode);
      if (resp.status === 200 && resp.data) {
        const dt = JSON.parse(resp.data.geoDetailsData);
        dispatch(
          getGeoDetails({
            latitude: dt[0]?.latitude,
            longitude: dt[0]?.longitude,
            zipCode: dt[0]?.postalCode,
            stationId: resp?.data?.stationId,
          })
        );
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  // to validate the token expiry
  const getDiseaseList = async () => {
    try {
      await getDiseaseListAPI();
      // console.log("token valid");
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        if (redirectCount >= cnt && interval == null) {
          interval = setInterval(() => {
            setRedirectCount((prev) => prev - 1);
          }, 1000);
        }
      }
      logAPIErrorsFromUI(error);
    }
  };

  const verifyIsUserEmailExists = async () => {
    try {
      // console.log("verifyIsUserEmailExistsAPI called");
      const resp = await verifyIsUserEmailExistsAPI(user.email);
      if (resp.status === 200 && resp.data === false) {
        logoutHandler();
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  const getFullNameInitials = (): string => {
    let initials = "";
    initials += user?.firstName[0]?.toUpperCase() || "";
    initials += user?.lastName[0]?.toUpperCase() || "";

    return initials;
  };

  const handleAvatarClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const logoutHandler = () => {
    // console.log("logout clicked");
    //remove userDetails from localstorage
    console.log(localStorage.removeItem("syngenta"));
    sessionStorage.removeItem("pmodel-token");
    //dispatch logout action to redux
    dispatch(logoutUser());
    dispatch(resetGeoDetails());

    //navigate user to login page
    navigate("/auth/login", { replace: true });
  };

  const openSettingsModalHandler = () => {
    // console.log("settings clicked");
    setOpenSettingsModal(true);
  };

  useEffect(() => {
    if (!syngentaDetails && !user.token) {
      sessionStorage.removeItem("pmodel-token");
      navigate("/auth/login", { replace: true });
    } else if (syngentaDetails?.userDetails?.email && !user.token) {
      dispatch(loginUser({ ...syngentaDetails.userDetails }));
      getGeoDetailsByPostalCode(syngentaDetails?.userDetails.zipCode);

      if (location.pathname === "/") {
        navigate("/home", { replace: true });
      }
    } else if (user.token) {
      const postalCode: any = user.zipCode;
      getGeoDetailsByPostalCode(postalCode);
    }
  }, []);

  useEffect(() => {
    if (!user.email) return;

    verifyIsUserEmailExists();
    if (redirectCount != cnt) return;

    getDiseaseList();
  });

  useEffect(() => {
    if (redirectCount <= 0) {
      clearInterval(interval);
      logoutHandler();
    }
  }, [redirectCount]);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`py-3 ${redirectCount < cnt && "cna"}`}
        fixed="top"
        style={{ backgroundColor: "#fff", borderBottom: "1px solid #d3d3d3" }}
      >
        <Container fluid>
          <Navbar.Brand as={Link} to="/home" className="ps-2">
            <img src={PModelBannerSmall} alt="Pmodel banner" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="pmodel-responsive-navbar-nav" />
          <Navbar.Collapse id="pmodel-responsive-navbar-nav" className="ms-5">
            <Nav variant="underline" defaultActiveKey="/home" className="me-auto">
              <Nav.Item>
                <Nav.Link as={Link} to="/home" active={location.pathname === "/home"}>
                  Startseite
                </Nav.Link>
              </Nav.Item>
              {(user.userRole === "Admin" ||
                syngentaDetails?.userDetails?.userRole === "Admin") && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to="/dashboard"
                      active={
                        location.pathname === "/dashboard" ||
                        location.pathname === "/dashboard/users" ||
                        location.pathname === "/dashboard/service-email"
                      }
                    >
                      DashBoard
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to="/email-template"
                      active={location.pathname === "/email-template"}
                    >
                      E-Mail-Vorlage
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
            </Nav>
            <Nav>
              <div className="d-flex justify-content-center aling-items-center">
                <img
                  src={SettingsIcon}
                  alt="settings icon"
                  className="me-3 pointer"
                  onClick={openSettingsModalHandler}
                />

                <div ref={ref}>
                  <div className="avatar-initials pointer" onClick={handleAvatarClick}>
                    {getFullNameInitials()}
                  </div>

                  <Overlay
                    show={show}
                    target={target}
                    placement="bottom"
                    container={ref}
                    containerPadding={20}
                  >
                    <Popover id="popover-contained">
                      <Popover.Body
                        className="pointer"
                        onClick={() => {
                          setShow(!show);
                          logoutHandler();
                        }}
                      >
                        Ausloggen
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="home-page-content">
        <Outlet />
        {redirectCount < cnt && (
          <RedirectComponent count={redirectCount} label="Sitzung abgelaufen!" />
        )}
      </div>

      <Footer />

      <UserSettingsModal
        openSettingsModal={openSettingsModal}
        setOpenSettingsModal={setOpenSettingsModal}
      />
    </>
  );
};

export default HomeLayout;
