import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import * as formik from "formik";
import * as yup from "yup";
import { useAppSelector } from "@/redux/reduxHooks";

import DrawerComponent from "../drawerComponent/DrawerComponent";
import { AddUserDrawerProps } from "@/types/DashboardUsersTypes";
import Loader from "@/components/loader/Loader";

import { emailRegex, zipCodeRegex } from "@/util/regexAll";
import { verifyIsUserEmailExistsDashboardAPI } from "@/util/http/home/home.index";
import { getGeoDetailsByPostalCodeAPI } from "@/util/http/emailTemplate/emailTemplate.index";
import { addUserAPI } from "@/util/http/admin/admin.index";
import { IAddUserTypes } from "@/util/http/admin/admin.requestType";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import "./style.less";

type AddUserValuesProps = {
  salutationName: string;
  firstName: string;
  lastName: string;
  email: string;
  zipCode: string;
  city: string;
  SendInvitationMail: boolean;
};

const AddUsersDrawer = ({ show, handleClose }: AddUserDrawerProps) => {
  const { Formik } = formik;
  const [emailExistsError, setEmailExistsError] = useState<boolean>(false);
  const [isAddUserSuccess, setIsAddUserSuccess] = useState<boolean>(false);
  const [isAddUserError, setIsAddUserError] = useState<boolean>(false);
  const [isAddUserLoading, setIsAddUserLoading] = useState<boolean>(false);
  const [emailInput, setEmailInput] = useState<string>("");
  const user = useAppSelector((state) => state.user);

  const [zipCd, setZipCd] = useState<string>("");
  const [zipCodeValidationError, setZipCodeValidationError] = useState<boolean>(false);

  const schema = yup.object().shape({
    salutationName: yup.string().required("Anrede ist erforderlich"),
    firstName: yup.string().required("Vorname ist erforderlich"),
    lastName: yup.string().required("Nachname ist erforderlich"),
    email: yup
      .string()
      .required("E-Mail-Adresse ist erforderlich")
      .matches(emailRegex.regex, emailRegex.errorMessage),
    zipCode: yup
      .string()
      .required("PLZ ist erforderlich")
      .matches(zipCodeRegex.regex, zipCodeRegex.errorMessage),
    city: yup.string().required("Ort ist erforderlich"),
    SendInvitationMail: yup.bool(),
  });

  const initialValues = {
    salutationName: "",
    firstName: "",
    lastName: "",
    email: "",
    zipCode: "",
    city: "",
    SendInvitationMail: true,
  };

  const handleAddUserSubmit = async (values: AddUserValuesProps) => {
    setIsAddUserLoading(true);
    console.log(values);
    try {
      const data: IAddUserTypes = {
        salutation: values.salutationName,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        zipCode: values.zipCode,
        city: values.city,
        SendInvitationMail: values.SendInvitationMail,
        createdBy: user.email,
      };

      const res = await addUserAPI(data);

      if (res.data.isSuccessStatusCode === true) {
        setIsAddUserSuccess(true);
        setIsAddUserError(false);
        setEmailExistsError(false);
        setZipCodeValidationError(false);
        setTimeout(() => {
          handleClose();
        }, 5000);
      } else {
        setIsAddUserSuccess(false);
        setIsAddUserError(true);
      }
      setIsAddUserLoading(false);
    } catch (error) {
      setIsAddUserSuccess(false);
      setIsAddUserError(true);
      console.log(error);
      setIsAddUserLoading(false);
      logAPIErrorsFromUI(error);
    }
  };

  // to check if same email is already exists or not for uniqueness
  const checkIfEmailExistsOrNot = async (email: string) => {
    // console.log("in check If Email Exists Or Not", email);

    try {
      const res = await verifyIsUserEmailExistsDashboardAPI(email);

      if (res.data === true) {
        setEmailExistsError(true);
        setEmailInput("");
      }
    } catch (error) {
      setEmailExistsError(false);
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  const getGeoDetailsByPostalCode = async (postalCode: string) => {
    try {
      console.log("getGeoDetailsByPostalCodeAPI called");
      const resp = await getGeoDetailsByPostalCodeAPI(postalCode);
      if (resp.status === 200 && resp.data) {
        const dt = JSON.parse(resp.data.geoDetailsData);

        if (!dt[0] || !dt[0].latitude || !dt[0].longitude) {
          setZipCodeValidationError(true);
          setZipCd("");
        }
      }
    } catch (error) {
      console.log(error);
      setZipCodeValidationError(true);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    if (!zipCd) return;

    getGeoDetailsByPostalCode(zipCd);
  }, [zipCd]);

  useEffect(() => {
    if (!emailInput) return;

    const delayInputTimeoutId = setTimeout(() => {
      checkIfEmailExistsOrNot(emailInput);
    }, 500);

    return () => clearTimeout(delayInputTimeoutId);
  }, [emailInput]);

  return (
    <>
      <DrawerComponent show={show} handleClose={handleClose} title="Nutzer hinzufügen">
        <div className="drawer-container">
          <div className="body-text">Nutzerdetails</div>

          <Formik
            validationSchema={schema}
            onSubmit={(values) => handleAddUserSubmit(values)}
            initialValues={initialValues}
          >
            {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
              <>
                <Form noValidate onSubmit={handleSubmit} className="add-user-form">
                  <div className="drawer-form-body">
                    <Row className="mb-2 my-0">
                      <Form.Group controlId="salutations" className="no-padding">
                        <Form.Label className="mb-0">
                          Anrede<span className="required-field">*</span>
                        </Form.Label>

                        <div key="inline-radio">
                          {["Frau", "Herr", "Divers"].map((item, idx) => (
                            <Form.Check
                              key={idx}
                              inline
                              label={item}
                              value={item}
                              name="salutationName"
                              type="radio"
                              id={`inline-radio-${idx}`}
                              onChange={handleChange}
                            />
                          ))}
                        </div>
                        <Form.Control.Feedback type="invalid">
                          <>{errors.salutationName}</>
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-2 no-pl">
                      <Form.Group as={Col} md="6" controlId="firstName">
                        <Form.Label className="mb-0">
                          Vorname<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Vorname"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          isInvalid={!!touched.firstName && !!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          <>{errors.firstName}</>
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} md="6" controlId="lastName">
                        <Form.Label className="mb-0">
                          Nachname<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Nachname"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          isInvalid={!!touched.lastName && !!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          <>{errors.lastName}</>
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-2">
                      <Form.Group as={Col} md="12" controlId="add-user-email">
                        <Form.Label className="mb-0">
                          E-Mail-Adresse<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          size="sm"
                          type="email"
                          placeholder="E-Mail-Adresse"
                          name="email"
                          value={values.email}
                          onChange={async (e) => {
                            setEmailExistsError(false);
                            handleChange(e);
                            setEmailInput(e.target.value);
                          }}
                          isInvalid={(touched.email && !!errors.email) || emailExistsError}
                        />
                        <Form.Control.Feedback type="invalid">
                          <>
                            {emailExistsError
                              ? "Diese E-Mail-Adresse existiert bereits"
                              : errors.email}
                          </>
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3 no-pl">
                      <Form.Group as={Col} md={4} controlId="zipCode">
                        <Form.Label className="mb-0">
                          PLZ<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="--  --  --  --  --"
                          name="zipCode"
                          maxLength={5}
                          minLength={5}
                          value={values.zipCode}
                          onChange={(e) => {
                            const regex = /^[0-9]+$/;
                            const val = e.target.value;
                            if (val.match(regex) || val === "") {
                              handleChange(e);
                              setZipCodeValidationError(false);
                              if (val.length === 5) {
                                setZipCd(val);
                              }
                            }
                          }}
                          isInvalid={
                            (!!touched.zipCode && !!errors.zipCode) || zipCodeValidationError
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          <>
                            {zipCodeValidationError
                              ? "Die Validierung der Postleitzahl ist fehlgeschlagen"
                              : errors.zipCode}
                          </>
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} md={8} controlId="city">
                        <Form.Label className="mb-0">
                          Ort<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Ort"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          isInvalid={!!touched.city && !!errors.city}
                        />
                        <Form.Control.Feedback type="invalid">
                          <>{errors.city}</>
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-2">
                      <Form.Group as={Col} md="12" controlId="sendInvitationEmail">
                        <Form.Check
                          label="Einladungs-E-Mail senden"
                          checked={values.SendInvitationMail}
                          name="SendInvitationMail"
                          type="checkbox"
                          onChange={(e) => setFieldValue("SendInvitationMail", e.target.checked)}
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mb-5">
                      <Form.Label className="required-pflichtfeld-text">
                        <span className="required-field">*</span> Pflichtfeld
                      </Form.Label>
                    </Row>
                  </div>

                  <div className="drawer-form-footer">
                    {isAddUserError && (
                      <div className="drawer-form-footer-message text-center text-danger mb-2">
                        Beim Hinzufügen eines Benutzers ist ein Fehler aufgetreten. Bitte versuchen
                        Sie es später noch einmal!
                      </div>
                    )}
                    {isAddUserSuccess && (
                      <div className="drawer-form-footer-message text-center text-success mb-2">
                        Benutzer erfolgreich hinzugefügt!
                      </div>
                    )}

                    <div className="add-user-drawer-form-footer-buttons">
                      <button className="cancel-button" onClick={handleClose}>
                        Abbrechen
                      </button>
                      <button
                        type="submit"
                        className={`add-user-button ${
                          !values.email ||
                          !values.firstName ||
                          !values.lastName ||
                          !values.salutationName ||
                          !values.city ||
                          !values.zipCode ||
                          !!emailExistsError ||
                          !!zipCodeValidationError
                            ? "disable-button"
                            : ""
                        }`}
                        disabled={
                          !values.email ||
                          !values.firstName ||
                          !values.lastName ||
                          !values.salutationName ||
                          !values.city ||
                          !values.zipCode ||
                          !!emailExistsError ||
                          !!zipCodeValidationError
                        }
                      >
                        {isAddUserLoading ? <Loader /> : "Speichern"}
                      </button>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </DrawerComponent>
    </>
  );
};

export default AddUsersDrawer;
