import { AxiosResponse } from "axios";

import { http } from "../http.token";
import {
  IUpdateEmailTemplateReqTypes,
  IGetGeoDetailsByPostalCodeForEmailTemplateReqTypes,
  TSendTestEmailReqTypes,
  IGetResponsiveUserFarmDataAPIReqTypes,
} from "./emailTemplate.requestType";

// all email template APIs
export const getGeoDetailsByPostalCodeAPI = async (postalCode: string) => {
  const url = `/api/EmailTemplate/GetGeoDetailsByPostalCode?postalCode=${postalCode}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getWeatherStationDataAPI = async (
  postalCode: IGetGeoDetailsByPostalCodeForEmailTemplateReqTypes
) => {
  const url = `/api/EmailTemplate/GetGeoDetailsByPostalCode?postalCode=${postalCode}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getEmailTemplateDetailsAPI = async (zipCode: string) => {
  const url = `/api/EmailTemplate/GetEmailTemplateDetails/${zipCode}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getResponsiveUserFarmDataAPI = async ({
  latitude,
  longitude,
}: IGetResponsiveUserFarmDataAPIReqTypes) => {
  const url = `/api/EmailTemplate/GetResponsiveUserFarmData?latitude=${latitude}&longitude=${longitude}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const updateEmailTemplateDetailsAPI = async (data: IUpdateEmailTemplateReqTypes) => {
  const url = "/api/EmailTemplate/UpdateEmailTemplateDetails";
  const res: AxiosResponse = await http.put(url, data);
  return res;
};

export const sendTestEmailAPI = async (data: TSendTestEmailReqTypes) => {
  const url = "/api/EmailTemplate/SendEmailTemplate";
  const res: AxiosResponse = await http.post(url, data);
  return res;
};

export const sendEmailToAllUsersAPI = async () => {
  const url = "api/EmailTemplate/SendEmailToAllUsers";
  const res: AxiosResponse = await http.get(url);
  return res;
};
