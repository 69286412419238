/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import CustomDropDown from "../../../../components/dropdowns/CustomDropDown";
import Loader from "@/components/loader/Loader";

import { getSprayDistanceCalculatorAPI } from "@/util/http/home/home.index";
import { ISprayDistanceCalculatorResTypes } from "@/util/http/home/home.responseType";
import { OptionType } from "@/types/HomeTypes";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import "./SprayCalculator.less";

const SprayCalculator = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [options1, setOptions1] = useState<OptionType[]>();
  const [selectedOption1, setSelectedOption1] = useState<OptionType>();

  const [options2, setOptions2] = useState<OptionType[]>();
  const [selectedOption2, setSelectedOption2] = useState<OptionType>();

  const [options3, setOptions3] = useState<OptionType[]>();
  const [selectedOption3, setSelectedOption3] = useState<OptionType>();

  const [options4, setOptions4] = useState<OptionType[]>();
  const [selectedOption4, setSelectedOption4] = useState<OptionType>();

  const [options5, setOptions5] = useState<OptionType[]>();
  const [selectedOption5, setSelectedOption5] = useState<OptionType>();

  const getSprayDistanceCalculator = async () => {
    setIsLoading(true);
    try {
      const res = await getSprayDistanceCalculatorAPI();
      if (res.status === 200) {
        const resData: ISprayDistanceCalculatorResTypes[] = res.data;
        const opt1: OptionType[] = [];
        const opt2: OptionType[] = [];
        const opt3: OptionType[] = [];
        const opt4: OptionType[] = [];
        const opt5: OptionType[] = [];

        const selectOption: OptionType = {
          id: 0,
          label: "--Wählen--",
          value: "",
        };

        opt1.push(selectOption);
        opt2.push(selectOption);
        opt3.push(selectOption);
        opt4.push(selectOption);
        opt5.push(selectOption);

        resData.forEach((option) => {
          if (option.title === "Krautwachstum") {
            opt1.push({
              id: option.id,
              value: option.value + "",
              label: option.description,
            });
          } else if (option.title === "Sortenanfälligkeit") {
            opt2.push({
              id: option.id,
              value: option.value + "",
              label: option.description,
            });
          } else if (option.title === "Befallssituation im Feld") {
            opt3.push({
              id: option.id,
              value: option.value + "",
              label: option.description,
            });
          } else if (option.title === "Niederschlagsmenge") {
            opt4.push({
              id: option.id,
              value: option.value + "",
              label: option.description,
            });
          } else if (option.title === "Fungizid") {
            opt5.push({
              id: option.id,
              value: option.value + "",
              label: option.description,
            });
          }
        });

        setOptions1(opt1);
        setSelectedOption1(opt1[0]);

        setOptions2(opt2);
        setSelectedOption2(opt2[0]);

        setOptions3(opt3);
        setSelectedOption3(opt3[0]);

        setOptions4(opt4);
        setSelectedOption4(opt4[0]);

        setOptions5(opt5);
        setSelectedOption5(opt5[0]);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      logAPIErrorsFromUI(error);
    }
  };

  const generateSprayCalculatorText = (value1, value2, value3, value4, value5): string => {
    const dayCalculation = value1 + value2 + value3 + value4 + value5;
    let message =
      "Aufgrund der individuellen Schlagbedingungen sollte der Bestand nach dem empfohlenen witterungsbedingte Spritzabstand behandelt werden.";

    if (dayCalculation > 0) {
      message = `Aufgrund der individuellen Schlagbedingungen sollte der empfohlene witterungsbedingte Spritzabstand um ${Math.abs(
        dayCalculation
      )} verlängert werden.`;
    } else if (dayCalculation < 0) {
      message = `Aufgrund der individuellen Schlagbedingungen sollte der empfohlene witterungsbedingte Spritzabstand um ${Math.abs(
        dayCalculation
      )} verkürzt werden.`;
    }

    return message;
  };

  useEffect(() => {
    getSprayDistanceCalculator();
  }, []);

  return (
    <div className="spray-calculator-div">
      <div className="spray-heading-div">
        <div className="spray-heading">Interaktiver Spritzabstandsrechner</div>
        <div className="spray-subheading">
          Hier können Sie die Empfehlung für den Spritzabstand in der Region an Ihre
          schlagspezifische Situation anpassen.
        </div>
      </div>

      <div className="spray-calculator-content-div">
        {isLoading ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <>
            <Container className="dropdown-container" fluid>
              <Row className="dropdown-row">
                <Col xs={12} sm={6} md={3} lg={2}>
                  <div className="dropdownheading">Krautwachstum</div>

                  <CustomDropDown
                    options={options1}
                    onChange={(value) => setSelectedOption1(value)}
                    selectedOption={selectedOption1}
                    color="gray"
                  />
                </Col>
                <Col xs={12} sm={6} md={4} lg={2}>
                  <div className="dropdownheading">Sortenänfalligkeit</div>
                  <div>
                    <CustomDropDown
                      options={options2}
                      onChange={(value) => setSelectedOption2(value)}
                      selectedOption={selectedOption2}
                      color="gray"
                    />
                  </div>
                </Col>
                <Col xs={12} sm={6} md={5} lg={3}>
                  <div className="dropdownheading">Befallssituation im Feld</div>
                  <div>
                    <CustomDropDown
                      options={options3}
                      onChange={(value) => setSelectedOption3(value)}
                      selectedOption={selectedOption3}
                      color="gray"
                    />
                  </div>
                </Col>
                <Col xs={12} sm={6} md={5} lg={2}>
                  <div className="dropdownheading">Niederschlagsmenge</div>
                  <div>
                    <CustomDropDown
                      options={options4}
                      onChange={(value) => setSelectedOption4(value)}
                      selectedOption={selectedOption4}
                      color="gray"
                    />
                  </div>
                </Col>
                <Col xs={12} sm={6} md={5} lg={3}>
                  <div className="dropdownheading">Fungizid</div>
                  <div>
                    <CustomDropDown
                      options={options5}
                      onChange={(value) => setSelectedOption5(value)}
                      selectedOption={selectedOption5}
                      color="gray"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
            {selectedOption1?.value &&
              selectedOption2?.value &&
              selectedOption3?.value &&
              selectedOption4?.value &&
              selectedOption5?.value && (
                <div className="content-text">
                  {generateSprayCalculatorText(
                    +selectedOption1?.value,
                    +selectedOption2?.value,
                    +selectedOption3?.value,
                    +selectedOption4?.value,
                    +selectedOption5?.value
                  )}
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default SprayCalculator;
