import { AxiosResponse } from "axios";

import { http } from "../http.token";
import { IUpdateUserTypes, IAddUserTypes, ISaveServiceEmailsTypes } from "./admin.requestType";

// all admin APIs
export const getAllUserDetailsAPI = async () => {
  const url = "/api/Admin/GetAllUserDetails";
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const deleteUserAPI = async (email: string) => {
  const url = `/api/Admin/DeleteUser/${email}`;
  const res: AxiosResponse = await http.delete(url);
  return res;
};

export const updateUserAPI = async (data: IUpdateUserTypes) => {
  const url = "/api/Admin/UpdateUserDetails";
  const res: AxiosResponse = await http.put(url, data);
  return res;
};

export const addUserAPI = async (data: IAddUserTypes) => {
  const url = "/api/Admin/AddUser";
  const res: AxiosResponse = await http.post(url, data);
  return res;
};

export const getExportToExcelDataAPI = async () => {
  const url = "/api/Admin/GetAllUserDetails";
  const res: AxiosResponse = await http.get(url);
  return res;
};

// service emails
export const saveServiceEmailsAPI = async (data: ISaveServiceEmailsTypes) => {
  const url = "/api/EmailTemplate/SaveServiceEmails";
  const res: AxiosResponse = await http.post(url, data);
  return res;
};

export const getAllActiveUsersAPI = async () => {
  const url = "/api/EmailTemplate/GetAllActiveUsers";
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getAllServiceEmailsAPI = async () => {
  const url = "/api/EmailTemplate/GetAllServiceEmails";
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getEmailByEmailIdAPI = async (emailId: number) => {
  const url = `/api/EmailTemplate/GetEmailByEmailId${emailId}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const deleteEmailByEMailIdsAPI = async (emailId: number) => {
  const url = `/api/EmailTemplate/DeleteEmailByEMailIds/${emailId}`;
  const res: AxiosResponse = await http.delete(url);
  return res;
};

// Dashboard Index
export const getApplicationUserCountAPI = async (year: string) => {
  const url = `/api/Admin/GetApplicationUserCount?year=${year}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getApplicationUsageCountAPI = async (year: string) => {
  const url = `/api/Admin/GetApplicationUsageCount?year=${year}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getUserStatsCountAPI = async () => {
  const url = "/api/Admin/GetUserStatsCount";
  const res: AxiosResponse = await http.get(url);
  return res;
};
