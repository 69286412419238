import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

import Tabs from "../../tabs/Tabs";
import Footer from "@/components/footer/Footer";
import Loader from "@/components/loader/Loader";

import { getTermsAndConditionsAPI } from "@/util/http/auth/auth.index";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import PModelBanner from "../../../assets/pmodel-banner.svg";
import "./style.css";

const AuthLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pmodelIntro, setPmodelIntro] = useState<string>("");
  const [loading, setLoding] = useState<boolean>(true);

  const tabLinks = [
    {
      title: "REGISTRIEREN",
      value: "register",
      content: <Outlet />,
    },
    {
      title: "ANMELDEN",
      value: "login",
      content: <Outlet />,
    },
  ];

  const pModelIntrolText = async () => {
    setLoding(true);
    try {
      const res = await getTermsAndConditionsAPI();
      if (res.status === 200) {
        const contentArray = res.data;

        const introText =
          contentArray.find((content) => content.content_Name === "PModel Intro")
            ?.content_Description ||
          contentArray.find((cnar) => cnar.contentId == 5)?.content_Description;

        setPmodelIntro(introText);
      }
      setLoding(false);
    } catch (error) {
      console.log(error);
      setLoding(false);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    pModelIntrolText();
  }, []);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/auth") {
      navigate("/auth/login", { replace: true });
    }
  }, [location]);

  return (
    <div className="auth-layout-main-container">
      <div className="auth-layout-content-container">
        <div className="welcome-container">
          <div className="pmodel-img-div">
            <img src={PModelBanner} alt="pmodel-banner" className="pmodel-img" />
          </div>
          <div className="welcome-message">
            <div className="welcome-to-pmodel-text">
              <h2 className="welcome-first">Willkommen beim</h2>
              <h1 className="welcome-second">Phytophthora Modell Weihenstephan</h1>
            </div>

            {loading ? <Loader /> : HTMLReactParser(pmodelIntro || "")}
          </div>
        </div>

        <div className="auth-container">
          <Tabs tabLinks={tabLinks} pageSwitch={true} />
          <div className="tabContent">
            <Outlet />
          </div>
        </div>
      </div>
      <div className="auth-layout-footer-container">
        <Footer />
      </div>
    </div>
  );
};

export default AuthLayout;
