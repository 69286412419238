import { AxiosResponse } from "axios";

import { http } from "../http.token";
import { ISelfResetPasswordType } from "../auth/auth.requestType";

// all home page APIs
export const getDiseaseListAPI = async () => {
  const url = "/api/Disease/GetDiseaseList";
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getListLocationDiseasesAPI = async (attributeId: number) => {
  const crop = "Potato";
  const url = `/api/Disease/ListLocationDiseases/${crop}/${attributeId}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getRegionDataAPI = async (postalCode: string) => {
  const url = `/api/Disease/GetRegionData/${postalCode}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getWeatherStationDataAPI = async (
  latitude: number,
  longitude: number,
  stationId: number
) => {
  const url = `/api/EmailTemplate/GetUserFieldData?latitude=${latitude}&longitude=${longitude}&stationId=${stationId}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getSprayDistanceCalculatorAPI = async () => {
  const url = "/api/Disease/GetSprayDistanceCalculator";
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getMapAndChartImagesAPI = async (latitude: number, longitude: number) => {
  const url = `/api/Disease/GetImage?latitude=${latitude}&longitude=${longitude}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const selfResetPasswordAPI = async (data: ISelfResetPasswordType) => {
  const url = "/api/User/SelfResetPassword";
  const res: AxiosResponse = await http.post(url, data);
  return res;
};

export const verifyIsUserEmailExistsDashboardAPI = async (email: string) => {
  const url = `/api/User/isemailexist/${email}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getUserRegistrationDetailsAPI = async (email: string) => {
  const url = `/api/User/GetUserRegistrationDetails/${email}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getNearbyStationTestAPI = async (lat: number, long: number) => {
  const url = `/api/EmailTemplate/GetNearbyStationTest?latitude=${lat}&longitude=${long}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};
