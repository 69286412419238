import React from "react";

import Loader from "@/components/loader/Loader";

import { IMapsType } from "@/types/HomeTypes";

import RoundGreenIcon from "../../../../assets/round-green.svg";
import RoundYellowIcon from "../../../../assets/round-yellow.svg";
import RoundRedIcon from "../../../../assets/round-red.svg";
import RoundBrownIcon from "../../../../assets/round-brown.svg";

import "./InfestationMaps.less";

const infestationPhytophtoraLegend = [
  { key: "Kein Befall", icon: RoundGreenIcon, value: 0 },
  { key: "Befall in der Region", icon: RoundYellowIcon, value: 1 },
  { key: "Befall im Sprintzfenster", icon: RoundBrownIcon, value: 2 },
  { key: "Befall in Praxisflache", icon: RoundRedIcon, value: 3 },
];

const infestationAlternariaLegend = [
  { key: "Kein Befall", icon: RoundGreenIcon, value: 0 },
  { key: "Geringer Befall", icon: RoundYellowIcon, value: 1 },
  { key: "Mittlerer Befall", icon: RoundBrownIcon, value: 2 },
  { key: "Hoher Befall", icon: RoundRedIcon, value: 3 },
];

type InfestationMapsPropTypes = {
  mapsData: IMapsType;
  isLoading: boolean;
};

const InfestationMaps = ({ mapsData, isLoading }: InfestationMapsPropTypes) => {
  const generateImageDate = (date: string): string => {
    if (!date) return "";
    let dt = "";

    const now = new Date(date);
    const onejan = new Date(now.getFullYear(), 0, 1);
    const week = Math.ceil(
      ((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7
    );

    dt += "Kalenderwoche ";
    dt += week + ", ";
    dt += now.getFullYear();

    return dt;
  };

  return (
    <div className="infestation-maps-div">
      <div className="spray-heading-div text-center">
        <div className="spray-heading">Befallskarten der Monitoringstandorte</div>
        <div className="spray-subheading">
          Hier finden Sie die Ergebnisse der wöchentlichen Befallsbehebungen der Monitoringstandorte
          in der aktuellen Saison.
        </div>
      </div>

      <div className="infestation-maps-cards">
        <div className="infestation-maps-card">
          {isLoading ? (
            <div className="infestation-maps-loading-div">
              <Loader />
            </div>
          ) : (
            <div className="infestation-maps-card-content">
              <div className="infestation-maps-card-header">
                <div className="infestation-maps-card-title">Phytophthora</div>
                <div className="infestation-maps-card-date">
                  {generateImageDate(mapsData.phytophthora.date)}
                </div>
              </div>
              <div className="infestation-maps-graph-image-div">
                <img
                  src={`data:image/png;base64,${mapsData.phytophthora.image}`}
                  alt="phytophtora map"
                  className="infestation-maps-graph-image"
                />
              </div>
              <div className="infestation-maps-legend">
                <div className="infestation-maps-legend-title">Legende</div>
                <div className="infestation-maps-legend-row">
                  {infestationPhytophtoraLegend.map((lg, idx) => (
                    <div className="infestation-maps-legend-item" key={idx}>
                      <img src={lg.icon} alt={lg.key} />
                      <span className="infestation-maps-item-label">{lg.key}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="infestation-maps-card">
          {isLoading ? (
            <div className="infestation-maps-loading-div">
              <Loader />
            </div>
          ) : (
            <div className="infestation-maps-card-content">
              <div className="infestation-maps-card-header">
                <div className="infestation-maps-card-title">Alternaria</div>
                <div className="infestation-maps-card-date">
                  {generateImageDate(mapsData.alternaria.date)}
                </div>
              </div>
              <div>
                <img
                  src={`data:image/png;base64,${mapsData?.alternaria?.image}`}
                  alt="alternaria map"
                  className="infestation-maps-graph-image"
                />
              </div>
              <div className="infestation-maps-legend">
                <div className="infestation-maps-legend-title">Legende</div>
                <div className="infestation-maps-legend-row">
                  {infestationAlternariaLegend.map((lg, idx) => (
                    <div className="infestation-maps-legend-item" key={idx}>
                      <img src={lg.icon} alt={lg.key} />
                      <span className="infestation-maps-item-label">{lg.key}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfestationMaps;
