import React from "react";
import { OptionType } from "@/types/HomeTypes";
import Select, { StylesConfig } from "react-select";
import "./style.less";

interface ICustomDropDownPropTypes {
  options: OptionType[];
  onChange: (value) => void;
  selectedOption: OptionType;
  color?: "gray" | "white";
}

// to create custom dropdown with some features
const CustomDropDown = ({
  options,
  onChange,
  selectedOption,
  color = "white",
}: ICustomDropDownPropTypes) => {
  const dropdownStyles: StylesConfig<OptionType> = {
    control: (styles) => ({
      ...styles,
      backgroundColor: color === "white" ? "#fff" : "#f3f4f6",
      color: color === "white" ? "#000" : "#696f88",
      fontFamily: "Noto Sans Display",
      cursor: "pointer",
    }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#994500" : "transparent",
        color: isSelected ? "#fff" : "#000",
        cursor: "pointer",
        fontFamily: "Noto Sans Display",
      };
    },
    placeholder: (styles) => ({ ...styles }),
    menuList: (base) => ({
      ...base,
      backgroundColor: "#ddd",
      padding: 0,
      border: "1px solid #ccc",
      zIndex: 999,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <Select
      value={selectedOption}
      options={options}
      styles={dropdownStyles}
      isSearchable={false}
      onChange={onChange}
      blurInputOnSelect
      menuPortalTarget={document.body}
    />
  );
};

export default CustomDropDown;
