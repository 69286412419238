import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "@/redux/reduxHooks";

import Loader from "@/components/loader/Loader";

import { IUpdateUserTypes } from "@/util/http/admin/admin.requestType";
import { updateUserAPI } from "@/util/http/admin/admin.index";
import { getGeoDetailsByPostalCodeAPI } from "@/util/http/emailTemplate/emailTemplate.index";
import { loginUser } from "@/redux/reducers-or-slices/userSlice";
import { saveUserDetailsInLocalStorage } from "@/util/saveUserDetailsInLocalStorage";
import { getGeoDetails } from "@/redux/reducers-or-slices/geoDetailsSlice";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import EditIcon from "../../../../assets/edit.svg";
import "./UserProfile.less";

const UserProfile = () => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [editPI, setEditPI] = useState<boolean>(false);
  const [editPILoading, setEditPILoading] = useState<boolean>(false);
  const [editAddress, setEditAddress] = useState<boolean>(false);
  const [editAddressLoading, setEditAddressLoading] = useState<boolean>(false);
  const [isUpdateUserSuccess, setIsUpdateUserSuccess] = useState<boolean>(false);
  const [isUpdateUserError, setIsUpdateUserError] = useState<boolean>(false);

  const [zipCd, setZipCd] = useState<string>("");
  const [zipCodeValidationError, setZipCodeValidationError] = useState<boolean>(false);

  const initialValues = {
    salutation: user.salutation,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    city: user.city,
    zipCode: user.zipCode,
    streetNameAndNumber: user.streetNameAndNumber,
    lastModifiedBy: user.email,
  };

  const [userDetails, setUserDetails] = useState<IUpdateUserTypes>(initialValues);

  const userDetailsChangeHandler = (event) => {
    const { name, value } = event.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  const resetValuesOnCancel = (section: "pi" | "address") => {
    if (section === "pi") {
      setUserDetails((prev) => ({
        ...prev,
        salutation: initialValues.salutation,
        firstName: initialValues.firstName,
        lastName: initialValues.lastName,
      }));
    } else if (section === "address") {
      setUserDetails((prev) => ({
        ...prev,
        zipCode: initialValues.zipCode,
        streetNameAndNumber: initialValues.streetNameAndNumber,
        city: initialValues.city,
      }));
    }
  };

  const getGeoDetailsByPostalCode = async (postalCode: string) => {
    try {
      // console.log("getGeoDetailsByPostalCodeAPI called");
      const resp = await getGeoDetailsByPostalCodeAPI(postalCode);
      if (resp.status === 200 && resp.data) {
        const dt = JSON.parse(resp.data.geoDetailsData);

        if (!dt[0] || !dt[0].latitude || !dt[0].longitude) {
          setZipCodeValidationError(true);
          setZipCd("");
        }
      }
    } catch (error) {
      console.log(error);
      setZipCodeValidationError(true);
      logAPIErrorsFromUI(error);
    }
  };

  const handleUpdateUserDetailsSubmit = async (section: "pi" | "address") => {
    if (section === "pi") {
      setEditPILoading(true);
    } else {
      setEditAddressLoading(true);
    }

    try {
      const data: IUpdateUserTypes = {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        streetNameAndNumber: userDetails.streetNameAndNumber,
        salutation: userDetails.salutation,
        zipCode: userDetails.zipCode,
        city: userDetails.city,
        email: userDetails.email,
        lastModifiedBy: user.email,
      };
      const res = await updateUserAPI(data);

      if (res.data.isSuccessStatusCode === true) {
        setZipCodeValidationError(false);
        const resp = await saveUserDetailsInLocalStorage(userDetails.email, user?.token);
        dispatch(loginUser(resp));
        const geoDetailsRes = await getGeoDetailsByPostalCodeAPI(userDetails.zipCode);
        if (geoDetailsRes.status === 200 && geoDetailsRes.data) {
          const dt = JSON.parse(geoDetailsRes.data.geoDetailsData);
          dispatch(
            getGeoDetails({
              latitude: dt[0]?.latitude,
              longitude: dt[0]?.longitude,
              zipCode: dt[0]?.postalCode,
              stationId: geoDetailsRes?.data?.stationId,
            })
          );
        }

        setIsUpdateUserSuccess(true);
        setTimeout(() => {
          setIsUpdateUserSuccess(false);
        }, 6000);
        setIsUpdateUserError(false);
      } else {
        setIsUpdateUserSuccess(false);
        setIsUpdateUserError(true);
        setTimeout(() => {
          setIsUpdateUserError(false);
        }, 6000);
      }

      if (section === "pi") {
        setEditPILoading(false);
        setEditPI(false);
      } else {
        setEditAddressLoading(false);
        setEditAddress(false);
      }
    } catch (error) {
      setIsUpdateUserSuccess(false);
      setIsUpdateUserError(true);
      setTimeout(() => {
        setIsUpdateUserError(false);
      }, 6000);
      console.log(error);

      if (section === "pi") {
        setEditPILoading(false);
        setEditPI(false);
      } else {
        setEditAddressLoading(false);
        setEditAddress(false);
      }
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    if (!zipCd) return;

    getGeoDetailsByPostalCode(zipCd);
  }, [zipCd]);

  return (
    <div>
      {isUpdateUserSuccess && (
        <div className="user-settings-actions-text-container text-success">
          Details erfolgreich aktualisiert!
        </div>
      )}
      {isUpdateUserError && (
        <div className="user-settings-actions-text-container text-danger">
          Aktualisierung der Details fehlgeschlagen.
        </div>
      )}

      <div className="myprofile-pi">
        <div className="pi-header">
          <div className="fw-bold">Persönliche Informationen</div>
          <div>
            {editPI === false ? (
              <div className="pi-buttons-div">
                <button onClick={() => setEditPI(true)} className="pi-edit-button">
                  <img src={EditIcon} className="me-2" />
                  <span>Bearbeiten</span>
                </button>
              </div>
            ) : (
              <div className="pi-buttons-div">
                <button
                  className="pi-cancel-button"
                  onClick={() => {
                    resetValuesOnCancel("pi");
                    setEditPI(false);
                  }}
                >
                  Abbrechen
                </button>
                <button
                  className="pi-savechanges-button"
                  onClick={() => handleUpdateUserDetailsSubmit("pi")}
                >
                  {editPILoading ? <Loader /> : "Änderungen speichern"}
                </button>
              </div>
            )}
          </div>
        </div>
        {editPI === false ? (
          <div>
            <div className="profile-information">
              <div className="d-block">
                <div className="pi-name-heading">Anrede</div>
                <div className="pi-name">{userDetails.salutation}</div>
              </div>
              <div className="d-block">
                <div>Vorname</div>
                <div className="pi-name">{userDetails.firstName}</div>
              </div>
              <div className="d-block">
                <div>Nachname</div>
                <div className="pi-name">{userDetails.lastName}</div>
              </div>
            </div>

            <div className="d-block">
              <div className="profile-email-address">E-Mail-Adresse</div>
              <div className="pi-name ">{userDetails.email}</div>
            </div>
          </div>
        ) : (
          <div className="afterclick-pi">
            <Row className="mb-1">
              <Form.Group as={Col} md={12} lg={5} controlId="validationCustom01">
                <Form.Group controlId="salutations" className="no-padding">
                  <Form.Label className="mb-0">
                    Anrede<span className="required-field">*</span>
                  </Form.Label>
                  <div key="inline-radio">
                    {["Frau", "Herr", "Divers"].map((item, idx) => (
                      <Form.Check
                        key={idx}
                        inline
                        label={item}
                        value={item}
                        name="salutation"
                        checked={userDetails.salutation === item}
                        type="radio"
                        id={`inline-radio-${idx}`}
                        onChange={userDetailsChangeHandler}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Form.Group>
              <Form.Group as={Col} md={6} lg={3} controlId="validationCustom02">
                <Form.Label className="mb-0">
                  Vorname<span className="required-field">*</span>
                </Form.Label>
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  placeholder="Geben Sie Ihr Vorname"
                  value={userDetails.firstName}
                  onChange={userDetailsChangeHandler}
                  name="firstName"
                />
              </Form.Group>
              <Form.Group as={Col} md={6} lg={4} controlId="validationCustom02">
                <Form.Label className="mb-0">
                  Nachname<span className="required-field">*</span>
                </Form.Label>
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  placeholder="Geben Sie Ihr Nachname"
                  value={userDetails.lastName}
                  name="lastName"
                  onChange={userDetailsChangeHandler}
                />
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col} md="6" controlId="validationCustom03">
                <Form.Label className="mb-0">E-Mail-Adresse</Form.Label>
                <Form.Control
                  className="pi-emailaddress"
                  plaintext
                  readOnly
                  defaultValue={userDetails.email}
                  disabled={true}
                />
              </Form.Group>
            </Row>
          </div>
        )}
      </div>

      <div className="myprofile-address">
        <div className="myprofile-address-edit-button">
          <div className="fw-bold">Anschrift</div>
          <div className="myprofile-address-buttons-div">
            {editAddress === false ? (
              <button onClick={() => setEditAddress(true)} className="pi-edit-button">
                <img src={EditIcon} className="me-2" />
                <span>Bearbeiten</span>
              </button>
            ) : (
              <div className="click-pi-changepassword-button">
                <button
                  className="pi-cancel-button"
                  onClick={() => {
                    resetValuesOnCancel("address");
                    setEditAddress(false);
                  }}
                >
                  Abbrechen
                </button>
                <button
                  onClick={() => handleUpdateUserDetailsSubmit("address")}
                  className={`pi-savechanges-button ${
                    !userDetails.city || !userDetails.zipCode || !!zipCodeValidationError
                      ? "disable-button"
                      : ""
                  }`}
                  disabled={!userDetails.city || !userDetails.zipCode || !!zipCodeValidationError}
                >
                  {editAddressLoading ? <Loader /> : "Änderungen speichern"}
                </button>
              </div>
            )}
          </div>
        </div>
        {editAddress === false ? (
          <div className="profile-adress-information">
            <div className="d-block">
              <div className="pi-name-heading">Straße & Hausnummer</div>
              <div className="fw-bold">{userDetails.streetNameAndNumber}</div>
            </div>
            <div className="d-block">
              <div className="pi-name-heading">PLZ</div>
              <div className="fw-bold">{userDetails.zipCode}</div>
            </div>
            <div className="d-block">
              <div className="pi-name-heading">Ort</div>
              <div className="fw-bold">{userDetails.city}</div>
            </div>
          </div>
        ) : (
          <Row className="mb-2">
            <Form.Group as={Col} md="6" lg="4" controlId="validationCustom01">
              <Form.Label className="form-label mb-0">Straße & Hausnummer</Form.Label>
              <Form.Control
                required
                size="sm"
                type="text"
                placeholder="Geben Sie Ihr Straße & Hausnummer"
                value={userDetails.streetNameAndNumber}
                name="streetNameAndNumber"
                onChange={userDetailsChangeHandler}
              />
              {/* <Form.Control.Feedback type="invalid">
                {profile_error_message.streetNameNumber}
              </Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group as={Col} md="6" lg="4" controlId="validationCustom02">
              <Form.Label className="form-label mb-0">
                PLZ<span className="required-field">*</span>
              </Form.Label>
              <Form.Control
                required
                size="sm"
                type="text"
                placeholder="Geben Sie Ihr PLZ"
                value={userDetails.zipCode}
                name="zipCode"
                maxLength={5}
                minLength={5}
                onChange={(e) => {
                  const regex = /^[0-9]+$/;
                  const val = e.target.value;
                  if (val.match(regex) || val === "") {
                    userDetailsChangeHandler(e);
                    setZipCodeValidationError(false);
                    if (val.length === 5) {
                      setZipCd(val);
                    }
                  }
                }}
                isInvalid={zipCodeValidationError}
              />
              <Form.Control.Feedback type="invalid">
                <>
                  {zipCodeValidationError && "Die Validierung der Postleitzahl ist fehlgeschlagen"}
                </>
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" lg="4" controlId="validationCustom02">
              <Form.Label className="form-label mb-0">
                Ort<span className="required-field">*</span>
              </Form.Label>
              <Form.Control
                required
                size="sm"
                type="text"
                placeholder="Geben Sie Ihr Ort"
                value={userDetails.city}
                name="city"
                onChange={userDetailsChangeHandler}
              />
              {/* <Form.Control.Feedback type="invalid">
                {profile_error_message.city}
              </Form.Control.Feedback> */}
            </Form.Group>
          </Row>
        )}
      </div>

      <Row className="mt-2">
        <Form.Label className="required-pflichtfeld-text">
          <span className="required-field">*</span> Pflichtfeld
        </Form.Label>
      </Row>
    </div>
  );
};

export default UserProfile;
