import React, { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";

import { getTermsAndConditionsAPI } from "@/util/http/auth/auth.index";
import { logAPIErrorsFromUI } from "@/util/errorLog";

const LegalInformation = () => {
  const [copyrightInfo, setCopyrightInfo] = useState<string>("");

  const fetchTermsAndConditionsText = async () => {
    try {
      const res = await getTermsAndConditionsAPI();
      if (res.status === 200) {
        const contentArray = res.data;

        const impressum =
          contentArray.find((content) => content.content_Name === "Copyright Information")
            ?.content_Description ||
          contentArray.find((cnar) => cnar.contentId == 4)?.content_Description;

        setCopyrightInfo(impressum);
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    fetchTermsAndConditionsText();
  }, []);

  return <div>{HTMLReactParser(copyrightInfo || "")}</div>;
};

export default LegalInformation;
