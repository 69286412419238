import React from "react";
import { Link, Outlet } from "react-router-dom";

import Footer from "@/components/footer/Footer";

import BackIcon from "../../../assets/back.png";
import "./style.less";

const LegalsLayout = () => {
  return (
    <div className="legals-layout-div">
      <div className="legals-back-to-app-div">
        <Link to={"/home"} className="legals-back-to-home">
          <img src={BackIcon} alt="back icon" /> Zurück zur Startseite
        </Link>
        <hr />
      </div>
      <div className="legals-layout-content-div">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default LegalsLayout;
