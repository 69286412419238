import React, { useEffect, useMemo, useState } from "react";
import { GoogleMap, useLoadScript, Marker, MarkerF } from "@react-google-maps/api";
import { useAppSelector } from "@/redux/reduxHooks";

import Loader from "@/components/loader/Loader";
import { MarkerType, OptionType } from "@/types/HomeTypes";
import { getMapLegends } from "./mapLegends";

import "./style.less";

type PModelMapPropType = {
  markers: MarkerType[];
  setSelectedMarker: React.Dispatch<React.SetStateAction<MarkerType>>;
  selectedOption: OptionType;
};

const PModelMap = ({ markers, setSelectedMarker, selectedOption }: PModelMapPropType) => {
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });
  const userGeoDetails = useAppSelector((state) => state.geoDetails);

  const [legendsData, setLegendsData] = useState([]);

  const center = useMemo(() => ({ lat: 51, lng: 9.5 }), []);

  useEffect(() => {
    const dt = getMapLegends(selectedOption?.id);

    setLegendsData(dt);
  }, [selectedOption?.id]);

  return (
    <>
      <div className="Gmaps">
        <div className="gmaps-map" id="pmodel-map">
          {!isLoaded ? (
            <div className="map-container-style d-flex justify-content-center align-items-center">
              <span>
                Karten laden...
                <Loader />
              </span>
            </div>
          ) : (
            <GoogleMap mapContainerClassName="map-container-style" center={center} zoom={5.8}>
              {!markers ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Loader />
                </div>
              ) : (
                <>
                  {markers.map((marker, index) => (
                    <Marker
                      key={index}
                      position={{ lat: marker.latitude, lng: marker.longitude }}
                      icon={
                        legendsData?.find((mkrObj) => mkrObj.pressure === marker.pressure.trim())
                          ?.icon || legendsData[0]?.icon
                      }
                      // onLoad={onLoad}
                      onClick={() => setSelectedMarker(marker)}
                    />
                  ))}
                  <MarkerF
                    position={{ lat: +userGeoDetails.latitude, lng: +userGeoDetails.longitude }}
                    zIndex={99}
                  />
                </>
              )}
            </GoogleMap>
          )}

          <div className="map-legends-container">
            <div className="map-main-legend">Legende</div>
            <div className="map-legends">
              <div className="map-legend-row">
                {legendsData?.map((legend, idx) => (
                  <div key={idx} className="map-legend-item">
                    <img src={legend.icon} alt="White" />
                    <span className="item-label">{legend.key}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PModelMap;
