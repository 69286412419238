import React, { useState, useEffect } from "react";
import HTMLReactParser from "html-react-parser";

import { getTermsAndConditionsAPI } from "@/util/http/auth/auth.index";
import { logAPIErrorsFromUI } from "@/util/errorLog";

const TermsAndConditions = () => {
  const [tandcContent, setTandCContent] = useState<string>("");

  const fetchTermsAndConditionsText = async () => {
    try {
      const res = await getTermsAndConditionsAPI();
      if (res.status === 200) {
        const contentArray = res.data;

        const tandc =
          contentArray.find((content) => content.content_Name === "TermsAndConditions")
            ?.content_Description ||
          contentArray.find((cnar) => cnar.contentId == 1)?.content_Description;

        setTandCContent(tandc);
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    fetchTermsAndConditionsText();
  }, []);

  return <div>{HTMLReactParser(tandcContent)}</div>;
};

export default TermsAndConditions;
