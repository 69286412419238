import { useState } from "react";
import RegisterForm from "../../components/forms/register/RegisterForm";
import EmailVerificationModal from "../../components/modals/emailVerification/EmailVerificationModal";
import { EmailVerifyType } from "@/types/RegistrationTypes";
import { Outlet } from "react-router-dom";

const Register = () => {
  const [openEmailVerificationModal, setOpenEmailVerificationModal] = useState(false);

  const [verifyEmailDetails, setVerifyEmailDetails] = useState<EmailVerifyType>({
    email: "",
    firstName: "",
  });

  return (
    <>
      <RegisterForm
        setOpenEmailVerificationModal={setOpenEmailVerificationModal}
        setVerifyEmailDetails={setVerifyEmailDetails}
      />

      {openEmailVerificationModal && (
        <EmailVerificationModal
          openEmailVerificationModal={openEmailVerificationModal}
          setOpenEmailVerificationModal={setOpenEmailVerificationModal}
          verifyEmailDetails={verifyEmailDetails}
        />
      )}
      <Outlet />
    </>
  );
};

export default Register;
