import React, { useEffect, useState } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import * as formik from "formik";
import * as yup from "yup";

import TermsAndConditionsModal from "@/components/modals/termsNConditions/TermsAndConditionsModal";
import ToolTip from "@/components/tooltips/ToolTip";
import Loader from "@/components/loader/Loader";
import ShowPasswordButton from "../../showPassword/ShowPasswordButton";

import { RegistrationFormTypes } from "../../../types/RegistrationTypes";
import {
  getTermsAndConditionsAPI,
  registerUserAPI,
  verifyIsUserEmailExistsAPI,
} from "@/util/http/auth/auth.index";
import { getGeoDetailsByPostalCodeAPI } from "@/util/http/emailTemplate/emailTemplate.index";
import { emailRegex, passwordRegex, zipCodeRegex } from "@/util/regexAll";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import "./style.less";

type registerValuesTypes = {
  salutationName: string;
  firstName: string;
  lastName: string;
  streetName: string;
  zipCode: string;
  city: string;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  dppCheckbox: boolean;
  tandcCheckbox: boolean;
  subscribeForEmailNotifications: boolean;
};

const RegisterForm = ({
  setOpenEmailVerificationModal,
  setVerifyEmailDetails,
}: RegistrationFormTypes) => {
  const { Formik } = formik;
  const [emailExistsError, setEmailExistsError] = useState<boolean>(false);
  const [emailInput, setEmailInput] = useState<string>("");
  const [zipCd, setZipCd] = useState<string>("");
  const [zipCodeValidationError, setZipCodeValidationError] = useState<boolean>(false);

  const [showRegisterPassword, setShowRegisterPassword] = useState<boolean>(false);
  const [showRegisterConfirmPassword, setShowRegisterConfirmPassword] = useState<boolean>(false);

  const [openTandCModal, setOpenTandCModal] = useState<boolean>(false);
  const [openDPPModal, setOpenDPPModal] = useState<boolean>(false);
  const [isErrorOnRegister, setIsErrorOnRegister] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [content, setContent] = useState<{ tandc: string; dpp: string }>({ tandc: "", dpp: "" });

  const schema = yup.object().shape({
    salutationName: yup.string().required("Anrede ist erforderlich"),
    firstName: yup.string().required("Vorname ist erforderlich"),
    lastName: yup.string().required("Nachname ist erforderlich"),
    streetName: yup.string(),
    zipCode: yup
      .string()
      .required("PLZ ist erforderlich")
      .matches(zipCodeRegex.regex, zipCodeRegex.errorMessage),
    city: yup.string().required("Ort ist erforderlich"),

    email: yup
      .string()
      .required("E-Mail-Adresse ist erforderlich")
      .matches(emailRegex.regex, emailRegex.errorMessage),
    confirmEmail: yup
      .string()
      .required("E-Mail-Adresse bestätigen ist erforderlich")
      .oneOf([yup.ref("email"), null], "E-Mail Adressen müssen übereinstimmen"),

    password: yup
      .string()
      .required("Passwort ist erforderlich")
      .min(8, "Ihr Passwort muss mindestens 8 Zeichen lang sein.")
      .max(16, "Ihr Passwort darf maximal 16 Zeichen lang sein.")
      .matches(passwordRegex.regex, passwordRegex.errorMessage),
    confirmPassword: yup
      .string()
      .required("Passwort bestätigen ist erforderlich")
      .oneOf([yup.ref("password"), null], "Passwörter müssen übereinstimmen"),

    dppCheckbox: yup
      .bool()
      .required()
      .oneOf([true], "Die Datenschutzbestimmungen müssen akzeptiert werden."),
    tandcCheckbox: yup
      .bool()
      .required()
      .oneOf([true], "Allgemeine Geschäftsbedingungen müssen akzeptiert werden."),
    subscribeForEmailNotifications: yup.bool(),
  });

  const initialValues = {
    salutationName: "",
    firstName: "",
    lastName: "",
    streetName: "",
    zipCode: "",
    city: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    dppCheckbox: false,
    tandcCheckbox: false,
    subscribeForEmailNotifications: true,
  };

  // register API
  const handleRegisterSubmit = async (values: registerValuesTypes) => {
    setIsLoading(true);
    try {
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        salutation: values.salutationName,
        zipCode: values.zipCode,
        city: values.city,
        streetNameAndNumber: values.streetName,
        createdBy: "user",
        lastModifiedBy: "user",
        subscribeForEmailNotifications: values.subscribeForEmailNotifications,
      };
      const res = await registerUserAPI(data);

      if (res.data.isSuccessStatusCode === true) {
        setVerifyEmailDetails({ email: values.email, firstName: values.firstName });
        setEmailExistsError(false);
        setZipCodeValidationError(false);
        setOpenEmailVerificationModal(true);
      } else {
        setIsErrorOnRegister(true);
        setTimeout(() => {
          setIsErrorOnRegister(false);
        }, 10000);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      setIsErrorOnRegister(true);
      setTimeout(() => {
        setIsErrorOnRegister(false);
      }, 10000);

      logAPIErrorsFromUI(error);
    }
  };

  const checkIfEmailExistsOrNot = async (email: string) => {
    // console.log("in check If Email Exists Or Not", email);

    try {
      const res = await verifyIsUserEmailExistsAPI(email);

      if (res.data === true) {
        setEmailExistsError(true);
        setEmailInput("");
      }
    } catch (error) {
      setEmailExistsError(false);
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  // to check the validity of postal code
  const getGeoDetailsByPostalCode = async (postalCode: string) => {
    try {
      // console.log("getGeoDetailsByPostalCodeAPI called");
      const resp = await getGeoDetailsByPostalCodeAPI(postalCode);
      if (resp.status === 200 && resp.data) {
        const dt = JSON.parse(resp.data.geoDetailsData);

        if (!dt[0] || !dt[0].latitude || !dt[0].longitude) {
          setZipCodeValidationError(true);
          setZipCd("");
        }
      }
    } catch (error) {
      console.log(error);
      setZipCodeValidationError(true);
      logAPIErrorsFromUI(error);
    }
  };

  const fetchTermsAndConditionsText = async () => {
    try {
      const res = await getTermsAndConditionsAPI();
      if (res.status === 200) {
        const contentArray = res.data;

        const tandc =
          contentArray.find((content) => content.content_Name === "TermsAndConditions")
            ?.content_Description || contentArray[0].content_Description;

        const dpp =
          contentArray.find((content) => content.content_Name === "Data Privacy")
            ?.content_Description || contentArray[1].content_Description;

        setContent({
          ...content,
          tandc: tandc,
          dpp: dpp,
        });
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    fetchTermsAndConditionsText();
  }, []);

  useEffect(() => {
    if (!emailInput) return;

    const delayInputTimeoutId = setTimeout(() => {
      checkIfEmailExistsOrNot(emailInput);
    }, 500);

    return () => clearTimeout(delayInputTimeoutId);
  }, [emailInput]);

  useEffect(() => {
    if (!zipCd) return;

    getGeoDetailsByPostalCode(zipCd);
  }, [zipCd]);

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={(values) => handleRegisterSubmit(values)}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
          <>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-2 my-0">
                <Form.Group controlId="salutations" className="no-padding">
                  <Form.Label className="mb-0">
                    Anrede<span className="required-field">*</span>
                  </Form.Label>

                  <div key="inline-radio">
                    {["Frau", "Herr", "Divers"].map((item, idx) => (
                      <Form.Check
                        key={idx}
                        inline
                        label={item}
                        value={item}
                        name="salutationName"
                        type="radio"
                        id={`inline-radio-${idx}`}
                        onChange={handleChange}
                      />
                    ))}
                  </div>
                  <Form.Control.Feedback type="invalid">
                    <>{errors.salutationName}</>
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-2 no-pl">
                <Form.Group as={Col} md="6" controlId="firstName">
                  <Form.Label className="mb-0">
                    Vorname<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Geben Sie Ihr Vorname"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    isInvalid={!!touched.firstName && !!errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    <>{errors.firstName}</>
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="lastName">
                  <Form.Label className="mb-0">
                    Nachname<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Geben Sie Ihr Nachname"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    isInvalid={!!touched.lastName && !!errors.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    <>{errors.lastName}</>
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="12" controlId="streetName">
                  <Form.Label className="mb-0">Straße & Hausnummer</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Geben Sie Ihr Straße & Hausnummer"
                    name="streetName"
                    value={values.streetName}
                    onChange={handleChange}
                    isInvalid={!!touched.streetName && !!errors.streetName}
                  />
                  <Form.Control.Feedback type="invalid">
                    <>{errors.streetName}</>
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-2 no-pl">
                <Form.Group as={Col} md="3" controlId="zipCode">
                  <Form.Label className="mb-0">
                    PLZ<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="--  --  --  --  --"
                    name="zipCode"
                    maxLength={5}
                    minLength={5}
                    value={values.zipCode}
                    onChange={(e) => {
                      const regex = /^[0-9]+$/;
                      const val = e.target.value;
                      if (val.match(regex) || val === "") {
                        handleChange(e);
                        setZipCodeValidationError(false);
                        if (val.length === 5) {
                          setZipCd(val);
                        }
                      }
                    }}
                    isInvalid={(!!touched.zipCode && !!errors.zipCode) || zipCodeValidationError}
                  />
                  <Form.Control.Feedback type="invalid">
                    <>
                      {zipCodeValidationError
                        ? "Die Validierung der Postleitzahl ist fehlgeschlagen"
                        : errors.zipCode}
                    </>
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="9" controlId="city">
                  <Form.Label className="mb-0">
                    Ort<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Ort"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    isInvalid={!!touched.city && !!errors.city}
                  />
                  <Form.Control.Feedback type="invalid">
                    <>{errors.city}</>
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="12" controlId="email">
                  <Form.Label className="mb-0">
                    E-Mail-Adresse<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="email"
                    placeholder="Geben Sie Ihr E-Mail-Adresse"
                    name="email"
                    value={values.email}
                    onChange={async (e) => {
                      setEmailExistsError(false);
                      handleChange(e);
                      setEmailInput(e.target.value);
                    }}
                    isInvalid={(touched.email && !!errors.email) || emailExistsError}
                  />
                  <Form.Control.Feedback type="invalid">
                    <>
                      {emailExistsError ? "Diese E-Mail-Adresse existiert bereits" : errors.email}
                    </>
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="12" controlId="confirmEmail">
                  <Form.Label className="mb-0">
                    E-Mail-Adresse bestätigen<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="email"
                    placeholder="Geben Sie Ihre E-Mail-Adresse erneut ein"
                    name="confirmEmail"
                    value={values.confirmEmail}
                    onChange={handleChange}
                    isInvalid={!!touched.confirmEmail && !!errors.confirmEmail}
                  />
                  <Form.Control.Feedback type="invalid">
                    <>{errors.confirmEmail}</>
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="12" controlId="password">
                  <Form.Label className="mb-0">
                    Passwort<span className="required-field">*</span>
                    <ToolTip tooltipText={passwordRegex.errorMessage} />
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      size="sm"
                      type={showRegisterPassword ? "text" : "password"}
                      placeholder="Geben Sie Ihr Passwort"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!touched.password && !!errors.password}
                    />
                    <ShowPasswordButton
                      showPassword={showRegisterPassword}
                      setShowPassword={setShowRegisterPassword}
                    />
                    {touched.password && (
                      <Form.Control.Feedback type="invalid">
                        <>{errors.password}</>
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="confirmPassword">
                  <Form.Label className="mb-0">
                    Passwort bestätigen<span className="required-field">*</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      size="sm"
                      type={showRegisterConfirmPassword ? "text" : "password"}
                      placeholder="Geben Sie Ihr Passwort erneut ein"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      isInvalid={!!touched.confirmPassword && !!errors.confirmPassword}
                    />
                    <ShowPasswordButton
                      showPassword={showRegisterConfirmPassword}
                      setShowPassword={setShowRegisterConfirmPassword}
                    />
                    {touched.confirmPassword && (
                      <Form.Control.Feedback type="invalid">
                        <>{errors.confirmPassword}</>
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form.Group>
              </Row>

              <Form.Group className="mb-3">
                <div className="form-check">
                  <input
                    required
                    type="checkbox"
                    name="dppCheckbox"
                    className="form-check-input"
                    checked={values.dppCheckbox}
                    onChange={handleChange}
                  />
                  <label
                    title=""
                    className="form-check-label privacy-label"
                    onClick={() => setOpenDPPModal(true)}
                  >
                    Ich akzeptiere die Datenschutzerklärung<span className="required-field">*</span>
                  </label>
                </div>

                <div className="form-check">
                  <input
                    required
                    type="checkbox"
                    name="tandcCheckbox"
                    className="form-check-input"
                    checked={values.tandcCheckbox}
                    onChange={handleChange}
                  />
                  <label
                    title=""
                    className="form-check-label privacy-label"
                    onClick={() => setOpenTandCModal(true)}
                  >
                    Ich akzeptiere die Allgemeinen Geschäftsbedingungen
                    <span className="required-field">*</span>
                  </label>
                </div>

                <Form.Group as={Col} md="12" controlId="subscribeForEmailNotifications">
                  <Form.Check
                    label="Ich möchte E-Mail-Benachrichtigungen erhalten"
                    checked={values.subscribeForEmailNotifications}
                    name="subscribeForEmailNotifications"
                    type="checkbox"
                    onChange={(e) =>
                      setFieldValue("subscribeForEmailNotifications", e.target.checked)
                    }
                  />
                </Form.Group>
              </Form.Group>

              {isErrorOnRegister === true && (
                <div className="text-center text-danger">
                  Bei der Registrierung des Benutzers ist etwas schief gelaufen! Bitte versuchen Sie
                  es später noch einmal.
                </div>
              )}

              <Row className="mb-2">
                <Form.Label className="required-pflichtfeld-text">
                  <span className="required-field">*</span> Pflichtfeld
                </Form.Label>
              </Row>

              <button
                type="submit"
                className={`d-block w-100 register-button ${
                  !values.salutationName ||
                  !values.firstName ||
                  !values.lastName ||
                  !values.email ||
                  !values.confirmEmail ||
                  !values.password ||
                  !values.confirmPassword ||
                  !values.city ||
                  !values.zipCode ||
                  !values.dppCheckbox ||
                  !!emailExistsError ||
                  !!zipCodeValidationError ||
                  !values.tandcCheckbox
                    ? "disable-button"
                    : ""
                }`}
                disabled={
                  !values.salutationName ||
                  !values.firstName ||
                  !values.lastName ||
                  !values.email ||
                  !values.confirmEmail ||
                  !values.password ||
                  !values.confirmPassword ||
                  !values.city ||
                  !values.zipCode ||
                  !values.dppCheckbox ||
                  !values.tandcCheckbox ||
                  !!emailExistsError ||
                  !!zipCodeValidationError
                }
              >
                {isLoading ? <Loader /> : "REGISTRIEREN"}
              </button>
            </Form>

            {openTandCModal && (
              <TermsAndConditionsModal
                setOpenTandCModal={setOpenTandCModal}
                openTandCModal={openTandCModal}
                values={values}
                name="openTandCModal"
                content={content.tandc}
              />
            )}

            {openDPPModal && (
              <TermsAndConditionsModal
                setOpenTandCModal={setOpenDPPModal}
                openTandCModal={openDPPModal}
                values={values}
                name="openDPPModal"
                content={content.dpp}
              />
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default RegisterForm;
