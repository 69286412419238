export const emailRegex = {
  regex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
  errorMessage: "Die E-Mail sollte das richtige Format haben",
};

export const passwordRegex = {
  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z\s]).{8,16}$/,
  errorMessage:
    "Das Passwort muss eine Zahl, Groß- und Kleinbuchstaben und ein Sonderzeichen enthalten.",
};

export const zipCodeRegex = {
  regex: /^[0-9]{5}$/,
  errorMessage: "Die PLZ sollte das richtige Format haben",
};
