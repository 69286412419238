import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
  Chart,
  LinearScale,
  CategoryScale,
  BarElement,
  LineElement,
  PointElement,
  Filler,
} from "chart.js";

import BarChart from "./BarChart";
import { getApplicationUserCountAPI, getUserStatsCountAPI } from "@/util/http/admin/admin.index";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import PeopleIcon from "../../assets/dashboard/people.svg";
import WindowIcon from "../../assets/dashboard/window.svg";
import CalendarIcon from "../../assets/dashboard/calendar.svg";

Chart.register(LinearScale, CategoryScale, BarElement, LineElement, PointElement, Filler);

type dashBoardDataType = {
  totalUsers: { count: number; differenceInPercentage: number };
  activeUsers: { count: number; differenceInPercentage: number };
  totalSubscriptions: { count: number; differenceInPercentage: number };
};

export const getAllYearsSinceApplicationStart = () => {
  const currentYear = new Date().getFullYear();
  let startYear = 2023;
  if (currentYear - startYear > 3) {
    startYear = currentYear - 3;
  }
  const step = 1;

  return Array.from(
    { length: (currentYear - startYear) / step + 1 },
    (value, index) => startYear + index * step
  ).reverse();
};

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState<dashBoardDataType>({
    totalUsers: { count: 0, differenceInPercentage: 0 },
    activeUsers: { count: 0, differenceInPercentage: 0 },
    totalSubscriptions: { count: 0, differenceInPercentage: 0 },
  });
  const [appNewUserData, setAppNewUserData] = useState<{ data: number[]; max: number }>();

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [chartData, setChartData] = useState<any>({
    labels,
    datasets: [
      {
        data: [],
        // backgroundColor: "transparent",
        backgroundColor: (context: any) => {
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradient = ctx.createLinearGradient(40, top, 0, bottom);
          gradient.addColorStop(0, "rgba(250,174,50,0.8)");
          gradient.addColorStop(0.5, "rgba(250,174,50,0.15)");
          gradient.addColorStop(1, "rgba(255,255,255,0.15)");
          return gradient;
        },
        borderColor: "#E6A145",
        pointBorderColor: "transparent",
        tension: 0.5,
        fill: true,
      },
    ],
  });
  const [options, setOptions] = useState<any>({
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        border: { dash: [10] },
        min: 0,
        max: 0,
        ticks: {
          stepSize: 0,
        },
      },
    },
    responsive: true,
  });

  const [activeChartType, setActiveChartType] = useState<"month" | "year">("month");
  const [yearVal, setYearVal] = useState<string>(new Date().getFullYear().toString());

  const generateNumberFormat = (data: number) => {
    const dt = new Intl.NumberFormat().format(data);

    return dt;
  };

  const getDashboardData = async () => {
    try {
      const res = await getUserStatsCountAPI();
      if (res.status === 200) {
        const respData = res.data;
        setDashboardData({
          totalUsers: {
            count: respData.totalUsers,
            differenceInPercentage: 0,
          },
          activeUsers: {
            count: respData.activeUsers,
            differenceInPercentage: 0,
          },
          totalSubscriptions: {
            count: respData.totalSubscriptions,
            differenceInPercentage: 0,
          },
        });
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  const getApplicationUserCount = async () => {
    try {
      const res = await getApplicationUserCountAPI(yearVal);
      if (res.status === 200) {
        const respData = res.data;

        const dataArr = Array(12);
        dataArr.fill(0);

        respData.map((monthData) => {
          const index = labels.findIndex((label) => label === monthData.monthName);
          dataArr[index] = monthData.count;
        });
        const max = Math.max(...dataArr);

        setAppNewUserData({ data: dataArr, max });
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    getApplicationUserCount();
  }, [yearVal]);

  useEffect(() => {
    if (!appNewUserData) return;

    const opts = {
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          border: { dash: [10] },
          min: 0,
          max: (Math.ceil(appNewUserData?.max / 8) * 2 + appNewUserData?.max) | 8,
          ticks: {
            stepSize: Math.ceil(appNewUserData?.max / 8) | 2,
          },
        },
      },
      responsive: true,
    };

    setOptions(opts);
    // setBarOptions(barOpts);

    const chtData = {
      labels,
      datasets: [
        {
          data: appNewUserData.data,
          // backgroundColor: "transparent",
          backgroundColor: (context: any) => {
            if (!context.chart.chartArea) {
              return;
            }
            const {
              ctx,
              chartArea: { top, bottom },
            } = context.chart;
            const gradient = ctx.createLinearGradient(40, top, 0, bottom);
            gradient.addColorStop(0, "rgba(250,174,50,0.8)");
            gradient.addColorStop(0.5, "rgba(250,174,50,0.15)");
            gradient.addColorStop(1, "rgba(255,255,255,0.15)");
            return gradient;
          },
          borderColor: "#E6A145",
          pointBorderColor: "transparent",
          tension: 0.5,
          fill: true,
        },
      ],
    };

    setChartData(chtData);
  }, [appNewUserData]);

  return (
    <div className="admin-dashboard-div">
      <div className="container-fluid">
        <div className="row count-row">
          <div className="col">
            <div className="heading">
              <img src={PeopleIcon} alt="peopleicon" />
              <span>USER INSEGSAMT</span>
            </div>
            <div className="middle">
              <div className="count-value">
                {generateNumberFormat(dashboardData.totalUsers.count)}
              </div>
              <div className="count-percentage-difference">
                <strong
                  className={`${
                    dashboardData.totalUsers.differenceInPercentage >= 0 ? "growth" : "loss"
                  }`}
                >
                  {dashboardData.totalUsers.differenceInPercentage >= 0 ? "+" : "-"}
                  {Math.abs(dashboardData.totalUsers.differenceInPercentage)}%
                </strong>{" "}
                vom letzten Monat
              </div>
            </div>
          </div>

          <div className="col">
            <div className="heading">
              <img src={WindowIcon} alt="desktopicon" />
              <span>JETZT AKTIV</span>
            </div>
            <div className="middle">
              <div className="count-value">
                {generateNumberFormat(dashboardData.activeUsers.count)}
              </div>
              <div className="count-percentage-difference">
                <strong
                  className={`${
                    dashboardData.activeUsers.differenceInPercentage >= 0 ? "growth" : "loss"
                  }`}
                >
                  {dashboardData.activeUsers.differenceInPercentage >= 0 ? "+" : "-"}
                  {Math.abs(dashboardData.activeUsers.differenceInPercentage)}%
                </strong>{" "}
                vom letzten Monat
              </div>
            </div>
          </div>

          <div className="col">
            <div className="heading">
              <img src={CalendarIcon} alt="calendericon" />
              <span>ABONNEMENTS INSGESAMT</span>
            </div>
            <div className="middle">
              <div className="count-value">
                {generateNumberFormat(dashboardData.totalSubscriptions.count)}
              </div>
              <div className="count-percentage-difference">
                <strong
                  className={`${
                    dashboardData.totalSubscriptions.differenceInPercentage >= 0 ? "growth" : "loss"
                  }`}
                >
                  {dashboardData.totalSubscriptions.differenceInPercentage >= 0 ? "+" : "-"}
                  {Math.abs(dashboardData.totalSubscriptions.differenceInPercentage)}%
                </strong>{" "}
                vom letzten Monat
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col md={8}>
            <div className="chart-div chart-col linechart">
              <div className="headingborder">
                <div className="chart-heading">Anwendung Anzahl neuer Benutzer</div>
                <div className="buttonorder">
                  <button
                    className={`dashboard-line-chart-btn ${
                      activeChartType === "month" ? "dashboard-line-chart-activeButton" : ""
                    }`}
                    onClick={() => setActiveChartType("month")}
                  >
                    Monat
                  </button>
                  {/* <button
                    className={`dashboard-line-chart-btn ${
                      activeChartType === "year" ? "dashboard-line-chart-activeButton" : ""
                    }`}
                    onClick={() => setActiveChartType("year")}
                  >
                    Jahr
                  </button> */}
                </div>
              </div>
              <div className="my-3">
                {activeChartType === "month" ? (
                  <Line options={options} data={chartData} />
                ) : (
                  <>{/* <Bar options={barOptions} data={barChartData} /> */}</>
                )}
              </div>
              <label className="year">Jahr: &nbsp;&nbsp;</label>
              <select
                className="dropdownbox"
                value={yearVal}
                onChange={(e) => setYearVal(e.target.value)}
              >
                {getAllYearsSinceApplicationStart().map((year, idx) => (
                  <option key={idx} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col md={4} className="">
            <BarChart />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
