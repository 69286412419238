import React, { useEffect, useState } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/redux/reduxHooks";

import TextEditor from "@/components/textEditor/TextEditor";
import EmailTemplate from "./EmailTemplate";
import Loader from "@/components/loader/Loader";

import {
  getEmailTemplateDetailsAPI,
  updateEmailTemplateDetailsAPI,
  sendTestEmailAPI,
  getResponsiveUserFarmDataAPI,
  sendEmailToAllUsersAPI,
} from "@/util/http/emailTemplate/emailTemplate.index";

import { IGetEmailTemplateResTypes } from "@/util/http/emailTemplate/emailTemplate.responseType";
import {
  TSendTestEmailReqTypes,
  IUpdateEmailTemplateReqTypes,
  IGetResponsiveUserFarmDataAPIReqTypes,
} from "@/util/http/emailTemplate/emailTemplate.requestType";
import {
  EmailRecommendationTypes,
  WeatherStationDataEmailTableDataType,
} from "@/types/EmailTemplateTypes";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import "./style.less";

const EmailTemplateContainerPage = () => {
  const user = useAppSelector((state) => state.user);
  const geoDetails = useAppSelector((state) => state.geoDetails);
  const syngentaDetails = JSON.parse(localStorage.getItem("syngenta"));
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSendToAllChecked, setIsSendToAllChecked] = useState<boolean>(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(null);

  const [isSendTestEmailLoading, setIsSendTestEmailLoading] = useState<boolean>(false);
  const [isSendEmailToAllLoading, setIsSendEmailToAllLoading] = useState<boolean>(false);
  const [isSendTestEmailSuccess, setIsSendTestEmailSuccess] = useState<boolean>(null);
  const [isSendEmailToAllSuccess, setIsSendEmailToAllSuccess] = useState<boolean>(null);

  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [isPreviewed, setIsPreviewed] = useState<boolean>(false);
  const [selectedRegionTab, setSelectedRegionTab] = useState<string>("region1");

  const generateEmailSendDate = (): string => {
    const dt = new Date();

    const date = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
    const mnt = dt.getMonth() + 1;
    const month = mnt < 10 ? "0" + mnt : mnt;
    const year = dt.getFullYear();

    const sendDate = date + "/" + month + "/" + year;
    return sendDate;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emailSendDate, setEmailSendDate] = useState<string>(
    `Stand der Berechnungen, ${generateEmailSendDate()}`
  );
  const [emailTitle, setEmailTitle] = useState<string>("");
  const [emailSalutation, setEmailSalutation] = useState<string>("");
  const [emailTeaserText, setEmailTeaserText] = useState<string>("");

  const [emailRecommendUniversityR1, setEmailRecommendUniversityR1] = useState<string>("");
  const [emailRecommendUniversityR2, setEmailRecommendUniversityR2] = useState<string>("");
  const [emailRecommendUniversityR3, setEmailRecommendUniversityR3] = useState<string>("");

  const [emailRecommendSyngentaR1, setEmailRecommendSyngentaR1] = useState<string>("");
  const [emailRecommendSyngentaR2, setEmailRecommendSyngentaR2] = useState<string>("");
  const [emailRecommendSyngentaR3, setEmailRecommendSyngentaR3] = useState<string>("");
  const [emailFooter, setEmailFooter] = useState<string>("");
  const [emailTableData, setEmailTableData] = useState<WeatherStationDataEmailTableDataType[]>([]);

  const handleSelect = (key: string) => {
    setSelectedRegionTab(key);
  };

  const getEmailRecommendations = (selectedTab: string): EmailRecommendationTypes => {
    const eRecs: EmailRecommendationTypes = {
      syngenta: "",
      university: "",
    };
    if (selectedTab === "region1") {
      eRecs.syngenta = emailRecommendSyngentaR1;
      eRecs.university = emailRecommendUniversityR1;
    } else if (selectedTab === "region2") {
      eRecs.syngenta = emailRecommendSyngentaR2;
      eRecs.university = emailRecommendUniversityR2;
    } else if (selectedTab === "region3") {
      eRecs.syngenta = emailRecommendSyngentaR3;
      eRecs.university = emailRecommendUniversityR3;
    }

    return eRecs;
  };

  const emailRecommendedContent = { ...getEmailRecommendations(selectedRegionTab) };

  const getEmailTemplateDetails = async () => {
    try {
      setIsLoading(true);
      const res = await getEmailTemplateDetailsAPI(user.zipCode);
      const respData: IGetEmailTemplateResTypes = res.data;
      if (res.status === 200 && respData.title) {
        setEmailTitle(respData.title);
        setEmailSalutation(respData.salutation);
        setEmailTeaserText(respData.teaserText);

        setEmailRecommendUniversityR1(respData.universityRecommendationR1);
        setEmailRecommendUniversityR2(respData.universityRecommendationR2);
        setEmailRecommendUniversityR3(respData.universityRecommendationR3);

        setEmailRecommendSyngentaR1(respData.syngentaRecommendationR1);
        setEmailRecommendSyngentaR2(respData.syngentaRecommendationR2);
        setEmailRecommendSyngentaR3(respData.syngentaRecommendationR3);
        setEmailFooter(respData.emailFooterText);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  const updateEmailTemplateDetails = async () => {
    try {
      setIsSaveLoading(true);
      const data: IUpdateEmailTemplateReqTypes = {
        title: emailTitle,
        salutation: emailSalutation,
        teaserText: emailTeaserText,
        universityRecommendationR1: emailRecommendUniversityR1 || "",
        universityRecommendationR2: emailRecommendUniversityR2 || "",
        universityRecommendationR3: emailRecommendUniversityR3 || "",
        syngentaRecommendationR1: emailRecommendSyngentaR1 || "",
        syngentaRecommendationR2: emailRecommendSyngentaR2 || "",
        syngentaRecommendationR3: emailRecommendSyngentaR3 || "",
        emailFooterText: emailFooter,
        last_Modified_By: String(user.email),
        zipCode: user.zipCode,
      };

      const res = await updateEmailTemplateDetailsAPI(data);
      const respData = res.data;
      if (res.status === 200 && respData.isSuccessStatusCode) {
        // console.log("updated successfully");
        setIsUpdateSuccess(true);
        setIsSaveLoading(false);
        setTimeout(() => {
          setIsUpdateSuccess(null);
        }, 5000);
      }
    } catch (error) {
      setIsUpdateSuccess(false);
      setIsSaveLoading(false);
      setTimeout(() => {
        setIsUpdateSuccess(null);
      }, 5000);
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  const sendTestEmailTemplateHandler = async () => {
    try {
      setIsSendTestEmailLoading(true);
      const data: TSendTestEmailReqTypes = [];
      const res = await sendTestEmailAPI(data);
      const respData = JSON.parse(res.data);
      if (respData?.responses[0].messageKey) {
        console.log(JSON.parse(res.data));

        // console.log("Email Sent Successfully");

        setIsSendTestEmailSuccess(true);
        setTimeout(() => {
          setIsSendTestEmailSuccess(null);
        }, 5000);
      } else {
        setIsSendTestEmailSuccess(false);
        setTimeout(() => {
          setIsSendTestEmailSuccess(null);
        }, 5000);
      }

      setIsSendTestEmailLoading(false);
    } catch (error) {
      console.log(error);
      setIsSendTestEmailLoading(false);
      setIsSendTestEmailSuccess(false);
      setTimeout(() => {
        setIsSendTestEmailSuccess(null);
      }, 5000);
      logAPIErrorsFromUI(error);
    }
  };

  const sendEmailTemplateToAllHandler = async () => {
    try {
      setIsSendEmailToAllLoading(true);

      // make async api call
      sendEmailToAllUsersAPI();

      setTimeout(() => {
        setIsSendEmailToAllSuccess(true);
        setTimeout(() => {
          setIsSendEmailToAllSuccess(null);
        }, 8000);
        setIsSendEmailToAllLoading(false);
      }, 1500);
    } catch (error) {
      console.log(error);
      setIsSendEmailToAllLoading(false);
      setIsSendEmailToAllSuccess(false);
      setTimeout(() => {
        setIsSendEmailToAllSuccess(null);
      }, 8000);
      logAPIErrorsFromUI(error);
    }
  };

  const getResponsiveUserFarmData = async () => {
    try {
      if (!geoDetails.latitude || !geoDetails.longitude) {
        return;
      }

      const dt: IGetResponsiveUserFarmDataAPIReqTypes = {
        latitude: geoDetails.latitude,
        longitude: geoDetails.longitude,
      };
      const resp = await getResponsiveUserFarmDataAPI(dt);
      if (resp.status == 200 && resp.data.length > 0) {
        setEmailTableData(resp.data);
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    if (syngentaDetails?.userDetails?.userRole !== "Admin" && user.userRole !== "Admin") {
      navigate("/home", { replace: true });
    }
  }, []);

  useEffect(() => {
    getEmailTemplateDetails();
  }, []);

  useEffect(() => {
    getResponsiveUserFarmData();
  }, [geoDetails]);

  return (
    <>
      {isLoading && (
        <div className="loading-div">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <div className="email-template-container">
          <div className="email-template-container-inside">
            <div className="email-template-header">
              <div className="email-template-header-text">E-Mail-Vorlage bearbeiten</div>
              <hr className="email-template-ruler" />
            </div>

            <div className="email-template-buttons-div">
              <button className="email-template-cancel-btn">Abbrechen</button>
              <button className="email-template-save-btn" onClick={updateEmailTemplateDetails}>
                {isSaveLoading ? <Loader /> : "Vorlage speichern"}
              </button>
            </div>

            {isUpdateSuccess === true && (
              <div className="email-template-message-div text-success text-center mb-3 h4">
                E-Mail-Vorlage erfolgreich aktualisiert!
              </div>
            )}
            {isUpdateSuccess === false && (
              <div className="email-template-message-div text-danger text-center mb-3 h4">
                Aktualisierung der E-Mail-Vorlage fehlgeschlagen!
              </div>
            )}

            <Container fluid className="main-email-template">
              <Row>
                <Col lg={6} md={12} sm={12} className="email-template-text-editor-container">
                  <>
                    <div className="email-template-text-editor header-salutation-container">
                      <div className="editor-container-title">E-Mail-Header & Anrede</div>
                      <div className="editor-container-body">
                        <Row className="mb-3">
                          <Form.Group as={Col} md="12" controlId="email">
                            <Form.Label>E-Mail Sendedatum</Form.Label>
                            <Form.Control
                              type="text"
                              // placeholder={`Stand der Berechnungen, ${generateEmailSendDate()}`}
                              value={emailSendDate}
                              // defaultValue={`Stand der Berechnungen, ${generateEmailSendDate()}`}
                              className="form-control-style"
                              name="emailSendDate"
                              disabled
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} md="12" controlId="emailTitle">
                            <Form.Label>E-Mail-Titel</Form.Label>
                            <TextEditor value={emailTitle} setValue={setEmailTitle} />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} md="12" controlId="emailSalutation">
                            <Form.Label>Anrede</Form.Label>
                            <TextEditor value={emailSalutation} setValue={setEmailSalutation} />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} md="12" controlId="emailTeaserText">
                            <Form.Label>Teaser-Text</Form.Label>
                            <TextEditor value={emailTeaserText} setValue={setEmailTeaserText} />
                          </Form.Group>
                        </Row>
                      </div>
                    </div>

                    <div className="email-template-text-editor body-container">
                      <div className="editor-container-title">E-Mail-Text</div>
                      <div className="editor-container-body second-container-body">
                        <Tabs
                          defaultActiveKey={selectedRegionTab}
                          id="fill-tab"
                          className="mb-3"
                          fill
                          onSelect={handleSelect}
                        >
                          <Tab eventKey="region1" title="REGION 1" className="active-nav-link">
                            <div className="weather-stations-div">
                              <span className="fw-bold">Weather Stations : </span>
                              <span>Ummendorf, Koln Bonn, Langenlipsdorf, Kyritz, Jebnitz </span>
                            </div>
                            <Row className="my-4">
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="emailRecommendationUniversityR1"
                              >
                                <Form.Label>Empfehlungstext der Universität Region 1 : </Form.Label>
                                <TextEditor
                                  value={emailRecommendUniversityR1}
                                  setValue={setEmailRecommendUniversityR1}
                                />
                              </Form.Group>
                            </Row>
                            <Row className="my-4">
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="emailRecommendationSyngentaR1"
                              >
                                <Form.Label>Empfehlungstext von Syngenta Region 1 :</Form.Label>
                                <TextEditor
                                  value={emailRecommendSyngentaR1}
                                  setValue={setEmailRecommendSyngentaR1}
                                />
                              </Form.Group>
                            </Row>
                          </Tab>

                          <Tab eventKey="region2" title="REGION 2" className="active-nav-link">
                            <div className="weather-stations-div">
                              <span className="fw-bold">Weather Stations : </span>
                              <span>Ummendorf, Koln Bonn, Langenlipsdorf, Kyritz, Jebnitz </span>
                            </div>
                            <Row className="my-4">
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="emailRecommendationUniversityR2"
                              >
                                <Form.Label>Empfehlungstext der Universität Region 2 : </Form.Label>
                                <TextEditor
                                  value={emailRecommendUniversityR2}
                                  setValue={setEmailRecommendUniversityR2}
                                />
                              </Form.Group>
                            </Row>
                            <Row className="my-4">
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="emailRecommendationSyngentaR2"
                              >
                                <Form.Label>Empfehlungstext von Syngenta Region 2 :</Form.Label>
                                <TextEditor
                                  value={emailRecommendSyngentaR2}
                                  setValue={setEmailRecommendSyngentaR2}
                                />
                              </Form.Group>
                            </Row>
                          </Tab>

                          <Tab eventKey="region3" title="REGION 3" className="active-nav-link">
                            <div className="weather-stations-div">
                              <span className="fw-bold">Weather Stations : </span>
                              <span>Ummendorf, Koln Bonn, Langenlipsdorf, Kyritz, Jebnitz </span>
                            </div>
                            <Row className="my-4">
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="emailRecommendationUniversityR3"
                              >
                                <Form.Label>Empfehlungstext der Universität Region 3 : </Form.Label>
                                <TextEditor
                                  value={emailRecommendUniversityR3}
                                  setValue={setEmailRecommendUniversityR3}
                                />
                              </Form.Group>
                            </Row>
                            <Row className="my-4">
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="emailRecommendationSyngentaR3"
                              >
                                <Form.Label>Empfehlungstext von Syngenta Region 3 :</Form.Label>
                                <TextEditor
                                  value={emailRecommendSyngentaR3}
                                  setValue={setEmailRecommendSyngentaR3}
                                />
                              </Form.Group>
                            </Row>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </>

                  <div className="email-template-buttons-div">
                    <button
                      className="email-template-preview-btn"
                      onClick={() => {
                        setIsPreviewed(true);
                        window.scrollTo(0, 0);
                      }}
                    >
                      Vorschau E-Mail
                    </button>

                    <button
                      className="email-template-save-btn"
                      onClick={updateEmailTemplateDetails}
                    >
                      {isSaveLoading ? <Loader /> : "Vorlage speichern"}
                    </button>
                  </div>
                </Col>

                {isPreviewed && (
                  <Col lg={6} md={12} sm={12} className="email-template-preview-container">
                    <div className="email-template-preview">
                      <div className="editor-container-title">E-Mail-Vorlage Vorschau</div>
                      <div className="email-container-body">
                        <div className="email-container-body-text mb-1">
                          Sehen Sie hier eine Vorschau Ihrer E-Mail an, um sie vor dem Versand zu
                          visualisieren!
                        </div>
                        <hr className="email-template-ruler" />
                        <EmailTemplate
                          emailRecommendedContent={emailRecommendedContent}
                          emailSendDate={emailSendDate}
                          emailTitle={emailTitle}
                          emailSalutation={emailSalutation}
                          emailTeaserText={emailTeaserText}
                          emailTableData={emailTableData}
                        />
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </Container>

            <div className="email-template-footer-div">
              <div className="email-template-message-div">
                {isSendTestEmailSuccess === true && (
                  <div className="me-2 text-success text-center h4">
                    Test-E-Mail erfolgreich versendet!
                  </div>
                )}
                {isSendTestEmailSuccess === false && (
                  <div className="me-2 text-danger text-center h4">
                    Versenden der Test-E-Mail fehlgeschlagen!
                  </div>
                )}

                {isSendEmailToAllSuccess === true && (
                  <div className="me-2 text-success text-center h4">
                    Begann, wöchentliche E-Mails an Benutzer zu versenden.
                  </div>
                )}
                {isSendEmailToAllSuccess === false && (
                  <div className="me-2 text-danger text-center h4">
                    Der Versand der E-Mail an alle Benutzer ist fehlgeschlagen.
                  </div>
                )}
              </div>

              <div className="email-template-buttons-div">
                <Form.Group controlId="">
                  <Form.Check
                    required
                    label="Aktivieren Sie die Schaltfläche „E-Mail an Benutzer senden“."
                    name="confirmSendCheckbox"
                    checked={isSendToAllChecked}
                    onChange={(e) => {
                      if (isSendToAllChecked === false) {
                        const text = "Sind Sie damit einverstanden, E-Mails an Benutzer zu senden?";
                        if (confirm(text) == false) {
                          return;
                        }
                      }

                      setIsSendToAllChecked(e.target.checked);
                    }}
                  />
                </Form.Group>

                <button
                  className={`email-template-send-test-mail-btn ${
                    !isSendToAllChecked && "disable-button"
                  }`}
                  onClick={sendEmailTemplateToAllHandler}
                  disabled={!isSendToAllChecked}
                >
                  {isSendEmailToAllLoading ? <Loader /> : "E-Mail an Benutzer senden"}
                </button>

                <button
                  className="email-template-send-test-mail-btn"
                  onClick={sendTestEmailTemplateHandler}
                >
                  {isSendTestEmailLoading ? <Loader /> : "Test E-Mail senden"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailTemplateContainerPage;
