import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import * as formik from "formik";
import * as yup from "yup";
import { useAppDispatch } from "@/redux/reduxHooks";

import Loader from "@/components/loader/Loader";
import ShowPasswordButton from "@/components/showPassword/ShowPasswordButton";

import { loginUserAPI } from "@/util/http/auth/auth.index";
import { loginUser as loginUserRedux } from "@/redux/reducers-or-slices/userSlice";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import "./style.less";

type loginValuesTypes = {
  email: string;
  password: string;
  rememberMe: boolean;
};

const LoginForm = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [showLoginPassword, setShowLoginPassword] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { Formik } = formik;

  const schema = yup.object().shape({
    email: yup.string().required("E-Mail-Adresse ist erforderlich"),
    password: yup.string().required("Passwort ist erforderlich"),
    rememberMe: yup.boolean(),
  });

  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  // login API
  const handleLoginSubmit = async (values: loginValuesTypes) => {
    setIsLoading(true);
    try {
      const data = {
        userName: values.email,
        password: values.password,
      };
      const res = await loginUserAPI(data);
      // console.log(res);

      if (res.status === 200) {
        setIsSuccess(true);
        console.log(isSuccess);

        setIsLoading(false);
        // console.log("logged in Successfully.");

        // when user clicked on rememberMe then store data in locastorage
        if (values.rememberMe === true) {
          const userDetails = res.data;
          const syngenta = { userDetails };
          // console.log("Login Remembered");
          localStorage.setItem("syngenta", JSON.stringify(syngenta));
        }

        sessionStorage.setItem("pmodel-token", res.data.token);

        dispatch(loginUserRedux(res.data));

        navigate("/home", { replace: true });
      } else {
        setError(
          "Sie haben einen ungültigen Benutzernamen oder ein ungültiges Passwort eingegeben. Bitte versuchen Sie es erneut."
        );
      }
      setIsLoading(false);
    } catch (error) {
      if (error?.response) {
        setError(error.response?.data?.message || error.response?.data);
      } else {
        setError("Etwas ist schief gelaufen, bitte versuchen Sie es erneut!");
        console.log(error);
      }
      setIsLoading(false);
      logAPIErrorsFromUI(error);
    }
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={(values) => handleLoginSubmit(values)}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="email">
                <Form.Label>
                  E-Mail-Adresse<span className="required-field">*</span>
                </Form.Label>
                <Form.Control
                  size="sm"
                  required
                  type="email"
                  placeholder="Geben Sie Ihr E-Mail-Adresse"
                  name="email"
                  value={values.email}
                  onChange={(e) => {
                    setError("");
                    handleChange(e);
                  }}
                  isInvalid={!!touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  <>{errors.email}</>
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="password">
                <Form.Label>
                  Passwort<span className="required-field">*</span>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    size="sm"
                    required
                    type={showLoginPassword ? "text" : "password"}
                    placeholder="Geben Sie Ihr Passwort"
                    name="password"
                    value={values.password}
                    onChange={(e) => {
                      setError("");
                      handleChange(e);
                    }}
                    isInvalid={!!touched.password && !!errors.password}
                  />
                  <ShowPasswordButton
                    showPassword={showLoginPassword}
                    setShowPassword={setShowLoginPassword}
                  />
                  {touched.password && (
                    <Form.Control.Feedback type="invalid">
                      <>{errors.password}</>
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
              </Form.Group>
            </Row>

            {error && <div className="mb-3 error-message">{error}</div>}

            <Row className="mb-3">
              <Form.Group as={Col} md="7" controlId="rememberMe">
                <Form.Check
                  required
                  label="Login und Passwort merken"
                  name="rememberMe"
                  checked={values.rememberMe}
                  onChange={handleChange}
                />
              </Form.Group>
              <Col md={5} className="text-end">
                <Link to={"/auth/login/recover-password"} className="no-decoration">
                  Passwort vergessen?
                </Link>
              </Col>
            </Row>

            <button
              type="submit"
              className={`d-block w-100 login-button ${
                !values.email || !values.password ? "disable-button" : ""
              }`}
              disabled={!values.email || !values.password}
            >
              {isLoading ? <Loader /> : "ANMELDEN"}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
