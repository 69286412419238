import { logAPIErrorsFromUI } from "./errorLog";
import { getUserRegistrationDetailsAPI } from "./http/home/home.index";

// when user update the details, save the details in app local storage
export const saveUserDetailsInLocalStorage = async (email: string, token?: string) => {
  try {
    const res = await getUserRegistrationDetailsAPI(email);

    const resData = res.data;

    const currentLocalStorageData = JSON.parse(localStorage.getItem("syngenta"));

    if (currentLocalStorageData) {
      const { userDetails } = currentLocalStorageData;

      const syngenta = {
        userDetails: {
          ...userDetails,
          token: token || userDetails.token,
          firstName: resData.firstName,
          lastName: resData.lastName,
          email: resData.email,
          zipCode: resData.zipCode,
          city: resData.city,
          latitude: resData.latitude,
          longitude: resData.longitude,
          salutation: resData.salutationName,
          streetNameAndNumber: resData.streetNameAndNumber,
        },
      };

      localStorage.setItem("syngenta", JSON.stringify(syngenta));
      return syngenta.userDetails;
    } else {
      const dt = {
        firstName: resData.firstName,
        lastName: resData.lastName,
        email: resData.email,
        salutation: resData.salutationName,
        zipCode: resData.zipCode,
        city: resData.city,
        streetNameAndNumber: resData.streetNameAndNumber,
        isActive: resData.isActive,
        isEmailVerified: resData.isEmailVerified,
        last_Login: resData.last_Login,
        createdBy: resData.createdBy,
        lastModifiedBy: resData.lastModifiedBy,
        token: token,
        latitude: resData.latitude,
        longitude: resData.longitude,
        userRole: resData.userRole,
      };

      return dt;
    }
  } catch (error) {
    console.log(error);
    logAPIErrorsFromUI(error);
  }
};
