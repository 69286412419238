import RoundIcon from "../../assets/round.svg";
import RoundDarkGreenIcon from "../../assets/round-dark-green.svg";
import RoundGreenIcon from "../../assets/round-green.svg";
import RoundYellowIcon from "../../assets/round-yellow.svg";
import RoundOrangeIcon from "../../assets/round-orange.svg";
import RoundRedIcon from "../../assets/round-red.svg";

import ArrowDownCircleIcon from "../../assets/arrow-down-circle.svg";
import ArrowUpCircleIcon from "../../assets/arrow-up-circle.svg";
import ArrowRightCircleIcon from "../../assets/arrow-right-circle.svg";
import ArrowRightDownCircleIcon from "../../assets/arrow-right-down-circle.svg";
import ArrowRightUpCircleIcon from "../../assets/arrow-right-up-circle.svg";

export const tendencyData = [
  { tendencyValue: "1", key: "Fallend", icon: ArrowDownCircleIcon },
  { tendencyValue: "2", key: "Gleichbleibend", icon: ArrowRightCircleIcon },
  { tendencyValue: "3", key: "Steigend", icon: ArrowUpCircleIcon },
  { tendencyValue: "4", key: "Leicht fallend", icon: ArrowRightDownCircleIcon },
  { tendencyValue: "5", key: "Leicht steigend", icon: ArrowRightUpCircleIcon },
];

export const explanationPhytophthoraInfestationData = [
  { key: "Kein Befall", icon: RoundIcon },
  { key: "Sehr gering", icon: RoundGreenIcon },
  { key: "Gering", icon: RoundDarkGreenIcon },
  { key: "Mittel", icon: RoundYellowIcon },
  { key: "Hoch", icon: RoundOrangeIcon },
  { key: "Sehr hoch", icon: RoundRedIcon },
];

export const explanationAlternariaInfestationData = [
  { key: "Kein Befall", icon: RoundIcon },
  { key: "Gering", icon: RoundGreenIcon },
  { key: "Mittel", icon: RoundYellowIcon },
  { key: "Hoch", icon: RoundOrangeIcon },
];

export const infestationColor = [
  { key: "0", icon: RoundIcon },
  { key: "1", icon: RoundGreenIcon },
  { key: "2", icon: RoundDarkGreenIcon },
  { key: "3", icon: RoundYellowIcon },
  { key: "4", icon: RoundOrangeIcon },
  { key: "5", icon: RoundRedIcon },
];
