import { useState } from "react";

import ModalComponent from "../modalcomponent/ModalComponent";
import EnvelopIcon from "../../../assets/envelop.svg";
import Loader from "@/components/loader/Loader";

import { EmailVerificationModalProps } from "../../../types/RegistrationTypes";
import { generateMaskedEmail } from "@/util/maskedEmail";
import { resendVerificationMailAPI } from "@/util/http/auth/auth.index";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import "./style.css";

const EmailVerificationModal = ({
  openEmailVerificationModal,
  setOpenEmailVerificationModal,
  verifyEmailDetails,
}: EmailVerificationModalProps) => {
  const [isEmailReSent, setIsEmailReSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const resendVerificationEmail = async () => {
    setIsLoading(true);
    try {
      const res = await resendVerificationMailAPI(verifyEmailDetails.email);
      if (res.data.isSuccessStatusCode === true) {
        setIsEmailReSent(true);

        setTimeout(() => {
          setIsEmailReSent(false);
        }, 10000);
      }
      setIsLoading(false);
    } catch (error) {
      setIsEmailReSent(false);
      console.log(error);
      setIsLoading(false);
      logAPIErrorsFromUI(error);
    }
  };

  return (
    <ModalComponent
      modalShow={openEmailVerificationModal}
      setModalShow={setOpenEmailVerificationModal}
      title=""
      redirectTo="/auth/login"
    >
      <div className="content-1">
        <div className="welcome-user">
          Willkommen {verifyEmailDetails.firstName}! Ihre E-Mail-Adresse muss verifiziert werden.
        </div>
        <div>
          <img src={EnvelopIcon} alt="email-verification icon" />
        </div>
        <div>
          Wir haben eine E-Mail an {generateMaskedEmail(verifyEmailDetails.email || "")} gesendet,
          um Ihre E-Mail-Adresse zu verifizieren und Ihr Konto zu aktivieren. Der Link in der E-Mail
          läuft nach 24 Stunden ab.
        </div>
      </div>

      <div className="content-2">
        {isEmailReSent && (
          <div className="text-success fw-bold">Email wurde erfolgreich Versendet!</div>
        )}

        <div className="didnt-receive-text">Sie haben die E-Mail nicht erhalten?</div>
        <button className="resend-btn" onClick={resendVerificationEmail}>
          {isLoading ? <Loader /> : "Verifizierungs-E-Mail erneut senden"}
        </button>
      </div>
    </ModalComponent>
  );
};

export default EmailVerificationModal;
