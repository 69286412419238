import React, { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";

import { getTermsAndConditionsAPI } from "@/util/http/auth/auth.index";
import { logAPIErrorsFromUI } from "@/util/errorLog";

const CookieGuidelines = () => {
  const [cookieDetails, setCookieDetails] = useState<string>("");

  const fetchTermsAndConditionsText = async () => {
    try {
      const res = await getTermsAndConditionsAPI();
      if (res.status === 200) {
        const contentArray = res.data;

        const cookie =
          contentArray.find((content) => content.content_Name === "Cookie Guideline")
            ?.content_Description ||
          contentArray.find((cnar) => cnar.contentId == 3)?.content_Description;

        setCookieDetails(cookie);
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    fetchTermsAndConditionsText();
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://consent.cookiebot.com/34d5cf29-6582-468c-afb2-494a3ba3f10a/cd.js";
    script.async = true;
    const container = document.querySelector(".legals-layout-content-div");
    if (container) {
      container.appendChild(script);
    }
  }, []);
  return(
    <div>
      {HTMLReactParser(cookieDetails || "")}
    </div>);
};

export default CookieGuidelines;
