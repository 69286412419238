import React, { Component } from "react";
import { logApplicationError } from "@/util/errorLog";

import "./style.less";

type MyProps = {
  children: React.ReactNode;
};

type MyState = {
  hasError: boolean;
  error: any;
};

export default class ErrorBoundary extends Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    // to keep track of when an error occurs
    // and the error itself
    this.state = {
      hasError: false,
      error: undefined,
    };
  }

  // update the component state when an error occurs
  static getDerivedStateFromError(error) {
    // specify that the error boundary has caught an error
    return {
      hasError: true,
      error: error,
    };
  }

  // defines what to do when an error gets caught
  componentDidCatch(error, errorInfo) {
    // log the error
    // console.log("Error caught!");
    console.error(error);
    console.error(errorInfo);

    // to log errors call an API.
    logApplicationError(error.message, "UI", error.stack || errorInfo.componentStack);

    // record the error in an APM tool...
  }

  render() {
    // if an error occurred
    if (this.state.hasError) {
      return (
        <div className={"error-boundary-page"}>
          <div className={"error-boundary-oops"}>Hoppla!</div>
          <div className={"error-boundary-message"}>Etwas ist schief gelaufen...</div>
          <br />
          <div onClick={() => window.location.reload()} className="error-boundary-click-here">
            Klicken Sie hier, um die Anwendung zurückzusetzen
          </div>
          <div>
            Wenn nach dem Zurücksetzen das gleiche Problem auftritt, melden Sie es uns bitte.
          </div>
        </div>
      );
    } else {
      // default behavior
      return this.props.children;
    }
  }
}
