import { createSlice } from "@reduxjs/toolkit";

const initialValues = {
  zipCode: "",
  latitude: "",
  longitude: "",
  stationId: "",
};

const userSlice = createSlice({
  name: "geoDetails",
  initialState: initialValues,
  reducers: {
    getGeoDetails: (state, action) => (state = action.payload),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetGeoDetails: (state) => (state = initialValues),
  },
});

const { actions, reducer } = userSlice;

export const { getGeoDetails, resetGeoDetails } = actions;

export default reducer;
