import React, { useState, useEffect } from "react";
import HTMLReactParser from "html-react-parser";

import { getTermsAndConditionsAPI } from "@/util/http/auth/auth.index";
import { logAPIErrorsFromUI } from "@/util/errorLog";

const DataPrivacy = () => {
  const [dppContent, setDppContent] = useState<string>("");

  const fetchTermsAndConditionsText = async () => {
    try {
      const res = await getTermsAndConditionsAPI();
      if (res.status === 200) {
        const contentArray = res.data;

        const dpp =
          contentArray.find((content) => content.content_Name === "Data Privacy")
            ?.content_Description ||
          contentArray.find((cnar) => cnar.contentId == 2)?.content_Description;

        setDppContent(dpp);
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    fetchTermsAndConditionsText();
  }, []);

  return <div>{HTMLReactParser(dppContent)}</div>;
};

export default DataPrivacy;
