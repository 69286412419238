import React, { useState, useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "@/redux/reduxHooks";
import { loginUser } from "@/redux/reducers-or-slices/userSlice";

import "./style.less";

type Tab = {
  title: string;
  value: string;
  content: React.ReactNode;
};

const tabLinks = [
  {
    title: "DASHBOARD",
    value: "/dashboard",
    content: <Outlet />,
  },
  {
    title: "BENUTZER",
    value: "/dashboard/users",
    content: <Outlet />,
  },
  // {
  //   title: "SERVICE EMAIL",
  //   value: "/dashboard/service-email",
  //   content: <Outlet />,
  // },
];

const DashBoardLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const activeTabValue = tabLinks.find((tab) => tab.value == location.pathname).value;

  const [activeTab, setActiveTab] = useState(activeTabValue);

  const syngentaDetails = JSON.parse(localStorage.getItem("syngenta"));

  const handleTabClick = (tab: Tab) => {
    setActiveTab(tab.value);

    navigate(`${tab.value}`, { replace: true });
  };

  useEffect(() => {
    if (!syngentaDetails && !user.token) {
      navigate("/auth/login", { replace: true });
    } else if (syngentaDetails?.userDetails?.email && !user.token) {
      dispatch(loginUser({ ...syngentaDetails.userDetails }));
    }

    if (syngentaDetails?.userDetails?.userRole !== "Admin" && user.userRole !== "Admin") {
      navigate("/home", { replace: true });
    }
  }, []);

  return (
    <div className="dashboard-container">
      <div className="dashboard-body">
        <div className="tab-container">
          <div className="dashboard-tab">
            {tabLinks.map((tab, idx) => (
              <button
                key={idx}
                className={`dashboard-tablinks${activeTab === tab.value ? " active" : ""}`}
                onClick={() => handleTabClick(tab)}
              >
                {tab.title}
              </button>
            ))}
          </div>
        </div>
        <div className="dashboard-tab-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashBoardLayout;
