import React, { useEffect, useState } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import * as formik from "formik";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "@/redux/reduxHooks";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "@/redux/reducers-or-slices/userSlice";
import { resetGeoDetails } from "@/redux/reducers-or-slices/geoDetailsSlice";

import ShowPasswordButton from "@/components/showPassword/ShowPasswordButton";
import ToolTip from "@/components/tooltips/ToolTip";
import Loader from "@/components/loader/Loader";

import { selfResetPasswordAPI } from "@/util/http/home/home.index";
import { passwordRegex } from "@/util/regexAll";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import EditIcon from "../../../../assets/edit.svg";
import "./UserSecurity.less";

type UserSecurityPasswordType = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const UserSecurity = () => {
  const cnt = 8;
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [editPassword, setEditPassword] = useState<boolean>(false);
  const [isUpdatePasswordSuccess, setIsUpdatePasswordSuccess] = useState<boolean>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirectCount, setRedirectCount] = useState<number>(cnt);

  const [showSelfResetCurrentPassword, setShowSelfResetCurrentPassword] = useState<boolean>(false);
  const [showSelfResetNewPassword, setShowSelfResetNewPassword] = useState<boolean>(false);
  const [showSelfResetConfirmNewPassword, setShowSelfResetConfirmNewPassword] =
    useState<boolean>(false);

  const { Formik } = formik;

  const schema = yup.object().shape({
    currentPassword: yup
      .string()
      .required("Aktuelles Passwort ist erforderlich")
      .notOneOf(
        [yup.ref("newPassword"), null],
        "Aktuelles Passwort und neues Passwort sollten nicht übereinstimmen"
      ),
    newPassword: yup
      .string()
      .required("Neues Passwort ist erforderlich")
      .min(8, "Ihr Passwort muss mindestens 8 Zeichen lang sein.")
      .max(16, "Ihr Passwort darf maximal 16 Zeichen lang sein.")
      .matches(passwordRegex.regex, passwordRegex.errorMessage)
      .notOneOf(
        [yup.ref("currentPassword"), null],
        "Aktuelles Passwort und neues Passwort sollten nicht übereinstimmen"
      ),
    confirmNewPassword: yup
      .string()
      .required("Bestätigen Sie, dass ein neues Passwort erforderlich ist")
      .oneOf([yup.ref("newPassword"), null], "Die Passwörter müssen übereinstimmen."),
  });

  const initialValues: UserSecurityPasswordType = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  let interval = null;

  const handleUpdateUserPassword = async (values: UserSecurityPasswordType) => {
    setIsLoading(true);
    try {
      const data = {
        userEmailId: user.email,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      };
      const res = await selfResetPasswordAPI(data);
      if (res.data.isSuccessStatusCode === true) {
        setIsUpdatePasswordSuccess(true);
        interval = setInterval(() => {
          setRedirectCount((prev) => prev - 1);
        }, 1000);
      } else {
        setIsUpdatePasswordSuccess(false);
      }

      setTimeout(() => {
        setIsUpdatePasswordSuccess(null);
      }, 8000);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsUpdatePasswordSuccess(false);

      setTimeout(() => {
        setIsUpdatePasswordSuccess(null);
      }, 8000);

      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    if (redirectCount < cnt) document.body.classList.add("cna");

    if (redirectCount <= 0) {
      clearInterval(interval);
      document.body.classList.remove("cna");
      localStorage.removeItem("syngenta");
      sessionStorage.removeItem("pmodel-token");
      dispatch(logoutUser());
      dispatch(resetGeoDetails());
      navigate("/auth/login", { replace: true });
    }
  }, [redirectCount]);

  return (
    <div>
      {isUpdatePasswordSuccess === true && (
        <div className="user-settings-actions-text-container text-success">
          Passwort erfolgreich aktualisiert! Sie werden in {redirectCount} Sekunden zur Anmeldeseite
          weitergeleitet.
        </div>
      )}

      {isUpdatePasswordSuccess === false && (
        <div className="user-settings-actions-text-container text-danger">
          Das Passwort konnte nicht aktualisiert werden.
        </div>
      )}
      <div className="security">
        <div className="security-password">
          <Formik
            validationSchema={schema}
            onSubmit={(values) => handleUpdateUserPassword(values)}
            initialValues={initialValues}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="security-password-row">
                  <div className="fw-bold">Passwort</div>

                  <div className="security-changepassword-buttons-div">
                    {editPassword === false ? (
                      <button
                        className="security-changepassword-button"
                        onClick={() => setEditPassword(true)}
                      >
                        <img src={EditIcon} className="me-2" />
                        <span>Passwort ändern</span>
                      </button>
                    ) : (
                      <div className="click-security-changepassword-button">
                        <button
                          className="security-cancel-button"
                          onClick={() => setEditPassword(false)}
                        >
                          Abbrechen
                        </button>

                        <button
                          type="submit"
                          className={`security-save-changes-button ${
                            !values.currentPassword ||
                            !values.newPassword ||
                            !values.confirmNewPassword
                              ? "disable-button"
                              : ""
                          }`}
                          disabled={
                            !values.currentPassword ||
                            !values.newPassword ||
                            !values.confirmNewPassword
                          }
                        >
                          {isLoading ? <Loader /> : "Änderungen speichern"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {editPassword === false ? (
                  <div className="fw-bold">**********</div>
                ) : (
                  <div className="click-password-text">
                    Geben Sie Ihr gewünschtes Passwort ein. Es muss sich von zuvor verwendeten
                    Passwörtern unterscheiden.
                    <Row className="mb-1">
                      <Form.Group as={Col} md={12} controlId="user-security-current-password">
                        <Form.Label className="mb-0">
                          Aktuelles Passwort<span className="required-field">*</span>
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            size="sm"
                            required
                            type={showSelfResetCurrentPassword ? "text" : "password"}
                            placeholder="Geben dein aktuelles Passwort ein"
                            name="currentPassword"
                            value={values.currentPassword}
                            onChange={handleChange}
                            isInvalid={!!touched.currentPassword && !!errors.currentPassword}
                          />
                          <ShowPasswordButton
                            showPassword={showSelfResetCurrentPassword}
                            setShowPassword={setShowSelfResetCurrentPassword}
                          />
                          {touched.currentPassword && (
                            <Form.Control.Feedback type="invalid">
                              <>{errors.currentPassword}</>
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-1">
                      <Form.Group as={Col} md={12} controlId="user-security-new-password">
                        <Form.Label className="mb-0">
                          Neues Passwort<span className="required-field">*</span>
                          <ToolTip tooltipText={passwordRegex.errorMessage} />
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            size="sm"
                            required
                            type={showSelfResetNewPassword ? "text" : "password"}
                            placeholder="Geben Sie Ihr neues Passwort ein"
                            name="newPassword"
                            value={values.newPassword}
                            onChange={handleChange}
                            isInvalid={!!touched.newPassword && !!errors.newPassword}
                          />
                          <ShowPasswordButton
                            showPassword={showSelfResetNewPassword}
                            setShowPassword={setShowSelfResetNewPassword}
                          />
                          {touched.newPassword && (
                            <Form.Control.Feedback type="invalid">
                              <>{errors.newPassword}</>
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-1">
                      <Form.Group as={Col} md={12} controlId="user-security-confirm-new-password">
                        <Form.Label className="mb-0">
                          Bestätige neues Passwort<span className="required-field">*</span>
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            size="sm"
                            required
                            type={showSelfResetConfirmNewPassword ? "text" : "password"}
                            placeholder="Geben Sie Ihr neues Passwort erneut ein"
                            name="confirmNewPassword"
                            value={values.confirmNewPassword}
                            onChange={handleChange}
                            isInvalid={!!touched.confirmNewPassword && !!errors.confirmNewPassword}
                          />
                          <ShowPasswordButton
                            showPassword={showSelfResetConfirmNewPassword}
                            setShowPassword={setShowSelfResetConfirmNewPassword}
                          />
                          {touched.confirmNewPassword && (
                            <Form.Control.Feedback type="invalid">
                              <>{errors.confirmNewPassword}</>
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </Row>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <Row className="mt-2">
        <Form.Label className="required-pflichtfeld-text">
          <span className="required-field">*</span> Pflichtfeld
        </Form.Label>
      </Row>
    </div>
  );
};

export default UserSecurity;
