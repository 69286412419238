import React from "react";

import EyeIcon from "../../assets/eye.svg";
import EyeSlashedIcon from "../../assets/eye-slashed.svg";

import "./style.less";

type ShowPasswordButtonPropTypes = {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
};

// show password button
const ShowPasswordButton = ({ showPassword, setShowPassword }: ShowPasswordButtonPropTypes) => {
  return (
    <button
      type="button"
      className="password-eye-button"
      onClick={(e) => {
        e.stopPropagation();
        setShowPassword((prev) => !prev);
      }}
    >
      {showPassword ? (
        <img src={EyeSlashedIcon} alt="eye slashed icon" />
      ) : (
        <img src={EyeIcon} alt="eye icon" />
      )}
    </button>
  );
};

export default ShowPasswordButton;
