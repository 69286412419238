import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ResetPasswordModal from "@/components/modals/resetPassword/ResetPasswordModal";

const ResetPassword = () => {
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/auth/login/reset-password") {
      setOpenResetPasswordModal(true);
    }
  }, [location]);

  return (
    <ResetPasswordModal
      openResetPasswordModal={openResetPasswordModal}
      setOpenResetPasswordModal={setOpenResetPasswordModal}
    />
  );
};

export default ResetPassword;
