import { ISaveUserDetailsResTypes } from "@/util/http/auth/auth.responseType";
import { createSlice } from "@reduxjs/toolkit";

const initialValues: ISaveUserDetailsResTypes = {
  firstName: "",
  lastName: "",
  email: "",
  // password: "",
  salutation: "",
  zipCode: "",
  city: "",
  streetNameAndNumber: "",
  isActive: false,
  isEmailVerified: false,
  last_Login: "",
  createdBy: "",
  lastModifiedBy: "",
  token: "",
  latitude: 0,
  longitude: 0,
  userRole: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialValues,
  reducers: {
    loginUser: (state, action) => (state = action.payload),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logoutUser: (state) => (state = initialValues),
  },
});

const { actions, reducer } = userSlice;

export const { loginUser, logoutUser } = actions;

export default reducer;
