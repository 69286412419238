import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  LinearScale,
  CategoryScale,
  BarElement,
  LineElement,
  PointElement,
  Filler,
} from "chart.js";

import { getApplicationUsageCountAPI } from "@/util/http/admin/admin.index";
import { getAllYearsSinceApplicationStart } from "./Dashboard";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import "./style.less";

Chart.register(LinearScale, CategoryScale, BarElement, LineElement, PointElement, Filler);

const Barchart = () => {
  const [appUsageData, setAppUsageData] = useState<{ data: number[]; max: number }>();
  const [yearVal, setYearVal] = useState<string>(new Date().getFullYear().toString());
  const [barData, setBarData] = useState<any>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: "#E6A145",
        borderColor: "#E6A145",
        // barThickness: 6,
        // categorySpacing: 0.5,
        // categoryPercentage: 1.0,
        // barPercentage: 0.98,
      },
    ],
  });
  const [options, setOptions] = useState<any>({
    indexAxis: "y" as const,
    maintainAspectRatio: false,
    scales: {
      x: {
        border: { dash: [10] },
        min: 0,
        max: 0,

        ticks: {
          stepSize: 0,
        },

        title: {
          display: true,
          text: "",
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
  });

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getApplicationUsageCount = async () => {
    try {
      const res = await getApplicationUsageCountAPI(yearVal);
      if (res.status === 200) {
        const respData = res.data;

        const dataArr = Array(12);
        dataArr.fill(0);

        respData.map((monthData) => {
          const index = labels.findIndex((label) => label === monthData.monthName);
          dataArr[index] = monthData.count;
        });
        const max = Math.max(...dataArr);

        setAppUsageData({ data: dataArr, max });
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    getApplicationUsageCount();
  }, [yearVal]);

  useEffect(() => {
    if (!appUsageData) return;

    const opts = {
      indexAxis: "y" as const,
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          border: { dash: [10] },
          min: 0,
          max: (Math.ceil(appUsageData?.max / 8) * 2 + appUsageData?.max) | 8,

          ticks: {
            stepSize: Math.ceil(appUsageData?.max / 8) | 2,
          },

          title: {
            display: true,
            text: "",
          },
        },
        y: {
          grid: {
            display: false,
          },
        },
      },
    };

    setOptions(opts);

    const barData = {
      labels,
      datasets: [
        {
          data: appUsageData.data,
          backgroundColor: "#E6A145",
          borderColor: "#E6A145",
          // barThickness: 6,
          // categorySpacing: 0.5,
          // categoryPercentage: 1.0,
          // barPercentage: 0.98,
        },
      ],
    };

    setBarData(barData);
  }, [appUsageData]);

  return (
    <div className="chart-div chart-col barchart">
      <div className="headingborder">
        <div className="chart-heading">Nutzung der Anwendung</div>
        <div>
          <label>Jahr:&nbsp;</label>
          <select
            className="dropdownbox"
            value={yearVal}
            onChange={(e) => setYearVal(e.target.value)}
          >
            {getAllYearsSinceApplicationStart().map((year, idx) => (
              <option key={idx} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="barheight">
        <Bar options={options} data={barData} />
      </div>
    </div>
  );
};

export default Barchart;
