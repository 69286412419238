import React, { useEffect, useState } from "react";
import { useAppSelector } from "@/redux/reduxHooks";
import HTMLReactParser from "html-react-parser";

import Loader from "@/components/loader/Loader";
import ToolTip from "@/components/tooltips/ToolTip";

import {
  IFieldDataStructured,
  ISelectedGeoLocationType,
  ISelectedStationData,
  IWeatherStationDataStructured,
} from "@/types/HomeTypes";

import { getRegionDataAPI, getWeatherStationDataAPI } from "@/util/http/home/home.index";

import {
  explanationAlternariaInfestationData,
  explanationPhytophthoraInfestationData,
  tendencyData,
} from "./weatherLegends";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import "./style.less";

type WeatherStationDataPropTypes = {
  selectedGeoLocation: ISelectedGeoLocationType;
  setSelectedStationData: React.Dispatch<React.SetStateAction<ISelectedStationData>>;
  setSelectedTab: React.Dispatch<React.SetStateAction<"1" | "2">>;
};

const WeatherStationData = ({
  selectedGeoLocation,
  setSelectedStationData,
  setSelectedTab,
}: WeatherStationDataPropTypes) => {
  const [activeButtonValue, setActiveButtonValue] = useState<string>("1");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [weatherStationData, setWeatherStationData] = useState<IWeatherStationData>();
  const user = useAppSelector((state) => state.user);
  const [weatherStationDataStructured, setWeatherStationDataStructured] =
    useState<IWeatherStationDataStructured>();

  const [regionData, setRegionData] = useState<{ syngenta: string; university: string }>({
    syngenta: "",
    university: "",
  });

  const createWeatherStationStructuredData = (data): IWeatherStationDataStructured => {
    const locationSet = new Set();
    data.fieldData.forEach((f) => locationSet.add(f.locationName));

    setSelectedStationData((prev) => ({
      ...prev,
      weatherStationName: data.weatherStationName,
    }));

    const fldData: IFieldDataStructured[] = [...locationSet].map((lcn: string) => ({
      LocationName: lcn,
      entries: data.fieldData.filter((fld) => fld.locationName === lcn),
    }));

    const structuredData: IWeatherStationDataStructured = {
      weatherStationName: data.weatherStationName,
      stationData: [...data.stationData],
      fieldData: fldData,
    };

    return structuredData;
  };

  const getWeatherStationData = async (latitude, longitude, stationId) => {
    setIsLoading(true);
    try {
      const res = await getWeatherStationDataAPI(latitude, longitude, stationId);

      if (res.status == 200) {
        // setWeatherStationData(res.data);
        const dt = createWeatherStationStructuredData(res.data);
        setWeatherStationDataStructured(dt);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      logAPIErrorsFromUI(error);
    }
  };

  const getVarietyText = (variety: string): string => {
    let ans = "";
    if (!variety) return ans;
    variety = variety.split("(01.01)")[0].trim();
    if (variety === ".") ans = "-";
    else ans = variety;

    return ans;
  };

  const getRegionData = async () => {
    try {
      const res = await getRegionDataAPI(user.zipCode);

      if (res.status == 200) {
        const { UniversityRecommendation, SyngentaRecommendation } = res.data;
        setRegionData({
          syngenta: SyngentaRecommendation,
          university: UniversityRecommendation,
        });
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    getRegionData();
  }, []);

  useEffect(() => {
    if (
      !selectedGeoLocation.latitude ||
      !selectedGeoLocation.longitude ||
      !selectedGeoLocation.stationId
    ) {
      return;
    }

    getWeatherStationData(
      selectedGeoLocation.latitude,
      selectedGeoLocation.longitude,
      selectedGeoLocation.stationId
    );

    setSelectedStationData((prev) => ({
      ...prev,
      stationId: selectedGeoLocation.stationId,
      latitude: selectedGeoLocation.latitude,
      longitude: selectedGeoLocation.longitude,
    }));
  }, [selectedGeoLocation]);

  return (
    <div className="weather-station-container">
      {isLoading ? (
        <div className="text-center h-50">
          <Loader />
        </div>
      ) : (
        <>
          <div className="home-weather-station-buttons-section">
            <div className="home-weather-station-button-div">
              <button
                className={`button p-button ${activeButtonValue === "1" ? "activeBtn" : ""}`}
                onClick={() => {
                  setActiveButtonValue("1");
                  setSelectedTab("1");
                }}
              >
                PHYTOPHTHORA
              </button>

              <button
                className={`button a-button ${activeButtonValue === "2" ? "activeBtn" : ""}`}
                onClick={() => {
                  setActiveButtonValue("2");
                  setSelectedTab("2");
                }}
              >
                ALTERNARIA
              </button>
            </div>
            <div className="home-weather-station-link-div">
              <a
                className="spray-weather-link"
                href="https://www.syngenta.de/service-beratung-spritzwetter"
                target="_blank"
                rel="noreferrer"
              >
                Zum Syngenta Spritzwetter
              </a>
            </div>
          </div>

          <div className="weather-station-title">
            Daten basierend auf der Wetterstation {weatherStationDataStructured?.weatherStationName}
          </div>

          {activeButtonValue === "1" && (
            <>
              <div className="main-table-div">
                <table className="weather-station-table-home">
                  <tbody>
                    <tr className="tableHeader1-home">
                      <td className="column-home th-home" colSpan={2}>
                        Witterungsbedingter Epidemiedruck
                      </td>
                      <td className="column-home th-home" colSpan={2}>
                        3-Tages-Prognose
                      </td>
                      <td className="column-home th-home">Spritzabstand</td>
                    </tr>

                    {weatherStationDataStructured?.stationData[0] ? (
                      <tr className="tableData1-home">
                        <td className="data-column-home" colSpan={2}>
                          {
                            explanationPhytophthoraInfestationData.find(
                              (ph) =>
                                ph.value ==
                                weatherStationDataStructured?.stationData[0]?.phytophthoraRisk
                            )?.key
                          }
                        </td>
                        <td className="data-column-home" colSpan={2}>
                          <img
                            src={
                              tendencyData.find(
                                (tnd) =>
                                  tnd.tendencyValue ==
                                  weatherStationDataStructured?.stationData[0]
                                    ?.phytophthoraRiskForecast
                              )?.icon
                            }
                            alt={
                              tendencyData.find(
                                (tnd) =>
                                  tnd.tendencyValue ==
                                  weatherStationDataStructured?.stationData[0]
                                    ?.phytophthoraRiskForecast
                              )?.key
                            }
                          />
                        </td>
                        <td className="data-column-home">
                          {weatherStationDataStructured?.stationData[0]?.phytophthoraSprayInterval}
                        </td>
                      </tr>
                    ) : (
                      <tr className="tbl-row-border-home">
                        <td className="data-column-home" colSpan={5}>
                          Wir können derzeit keine Wetterstationsdaten abrufen. Bitte versuchen Sie
                          es später noch einmal.
                        </td>
                      </tr>
                    )}

                    <tr className="tableHeader2-home">
                      <td className="column-home th-home weatherStationMonitoringText" colSpan={5}>
                        Praxisschlag (Monitoring-Fläche)
                      </td>
                    </tr>

                    <tr className="tableHeader3-home">
                      <td className="column-home">Standort (LKR)</td>
                      <td className="column-home">Sorte (Auflauftermin)</td>
                      <td className="column-home">Befall (unbehandelt)</td>
                      <td className="column-home">Anzahl Spritzungen</td>
                      <th className="column-home">Befall in Praxisschlag</th>
                    </tr>

                    {weatherStationDataStructured?.fieldData.length > 0 ? (
                      weatherStationDataStructured?.fieldData.map((item, idx) =>
                        item.entries.map((dt, index: number) => (
                          <tr
                            key={index}
                            className={`tbl-row-border-home ${idx % 2 == 1 ? "stripped-home" : ""}`}
                          >
                            <td className="data-column-home">{index == 0 && dt.locationName}</td>
                            <td className="data-column-home">
                              {getVarietyText(dt.variety)}
                              {dt.tooltip !== null && (
                                <ToolTip
                                  tooltipText={
                                    dt.tooltip.trim() === "ja" ? "Monitoring beendet" : dt.tooltip
                                  }
                                  isPassword={false}
                                />
                              )}
                            </td>
                            {dt.fieldIsMonitoredText === null ? (
                              <>
                                <td className="data-column-home">
                                  {dt.phythophthoraInfectionUntreatedField}
                                </td>
                                <td className="data-column-home">{dt.numberOfTreatments}</td>
                                <td className="data-column-home">
                                  <img
                                    src={
                                      explanationPhytophthoraInfestationData.find(
                                        (pdt) => pdt.value == dt.phythophthoraInfectionTreatedField
                                      ).icon
                                    }
                                    alt={
                                      explanationPhytophthoraInfestationData.find(
                                        (pdt) => pdt.value == dt.phythophthoraInfectionTreatedField
                                      ).key
                                    }
                                  />
                                </td>
                              </>
                            ) : (
                              <td className="data-column-home" colSpan={3}>
                                {dt.fieldIsMonitoredText}
                              </td>
                            )}
                          </tr>
                        ))
                      )
                    ) : (
                      <tr className="tbl-row-border-home">
                        <td className="data-column-home" colSpan={5}>
                          Wir können derzeit keine Wetterstationsdaten abrufen. Bitte versuchen Sie
                          es später noch einmal.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="weather-legends-container">
                <div className="weather-main-legend">Legende</div>

                <div className="weather-legends">
                  <div className="weather-legend">
                    <div className="weather-legend-title">Erläuterung Befall Phytophthora</div>

                    <div className="weather-legend-row">
                      {explanationPhytophthoraInfestationData.map((legend, idx) => (
                        <div key={idx} className="weather-legend-item">
                          <img src={legend.icon} alt={legend.key} />
                          <span className="weather-item-label">{legend.key}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="weather-legend tendency">
                    <div className="legend-title">Tendenz</div>

                    <div className="weather-legend-row five-legends">
                      {tendencyData.map((tendency, idx) => (
                        <div key={idx} className="weather-legend-item">
                          <img src={tendency.icon} alt={tendency.key} />
                          <span className="weather-item-label">{tendency.key}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {activeButtonValue === "2" && (
            <>
              <div className="main-table-div">
                <table className="weather-station-table-home">
                  <tbody>
                    <tr className="tableHeader1-home">
                      <td className="column-home th-home" colSpan={2}>
                        Witterungsbedingter Epidemiedruck
                      </td>
                      <td className="column-home th-home">3-Tages-Prognose</td>
                    </tr>

                    {weatherStationDataStructured?.stationData[0] ? (
                      <tr className="tableData1-home">
                        <td className="data-column-home" colSpan={2}>
                          {
                            explanationAlternariaInfestationData.find(
                              (ph) =>
                                ph.value ==
                                weatherStationDataStructured?.stationData[0]?.alternariaRisk
                            )?.key
                          }
                        </td>
                        <td className="data-column-home">
                          <img
                            src={
                              tendencyData.find(
                                (tnd) =>
                                  tnd.tendencyValue ==
                                  weatherStationDataStructured?.stationData[0]
                                    ?.alternariaRiskForecast
                              )?.icon
                            }
                            alt={
                              tendencyData.find(
                                (tnd) =>
                                  tnd.tendencyValue ==
                                  weatherStationDataStructured?.stationData[0]
                                    ?.alternariaRiskForecast
                              )?.key
                            }
                          />
                        </td>
                      </tr>
                    ) : (
                      <tr className="tbl-row-border-home">
                        <td className="data-column-home" colSpan={3}>
                          Wir können derzeit keine Wetterstationsdaten abrufen. Bitte versuchen Sie
                          es später noch einmal.
                        </td>
                      </tr>
                    )}

                    <tr className="tableHeader2-home">
                      <td className="column-home th-home weatherStationMonitoringText" colSpan={5}>
                        Praxisschlag (Monitoring-Fläche)
                      </td>
                    </tr>

                    <tr className="tableHeader3-home">
                      <td className="column-home">Standort (LKR)</td>
                      <td className="column-home">Sorte (Auflauftermin)</td>
                      <th className="column-home">Befall in Praxisschlag</th>
                    </tr>

                    {weatherStationDataStructured?.fieldData.length > 0 ? (
                      weatherStationDataStructured?.fieldData.map((item, idx) =>
                        item.entries.map((dt, index: number) => (
                          <tr
                            key={index}
                            className={`tbl-row-border-home ${idx % 2 == 1 ? "stripped-home" : ""}`}
                          >
                            <td className="data-column-home">{index == 0 && dt.locationName}</td>
                            <td className="data-column-home">
                              {getVarietyText(dt.variety)}
                              {dt.tooltip !== null && (
                                <ToolTip
                                  tooltipText={
                                    dt.tooltip.trim() === "ja" ? "Monitoring beendet" : dt.tooltip
                                  }
                                  isPassword={false}
                                />
                              )}
                            </td>
                            {dt.fieldIsMonitoredText === null ? (
                              <td className="data-column-home">
                                <img
                                  src={
                                    explanationAlternariaInfestationData.find(
                                      (pdt) => pdt.value == dt.alternariaInfectionTreatedField
                                    ).icon
                                  }
                                  alt={
                                    explanationAlternariaInfestationData.find(
                                      (pdt) => pdt.value == dt.alternariaInfectionTreatedField
                                    ).key
                                  }
                                />
                              </td>
                            ) : (
                              <td className="data-column-home">{dt.fieldIsMonitoredText}</td>
                            )}
                          </tr>
                        ))
                      )
                    ) : (
                      <tr className="tbl-row-border-home">
                        <td className="data-column-home" colSpan={3}>
                          Wir können derzeit keine Wetterstationsdaten abrufen. Bitte versuchen Sie
                          es später noch einmal.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="weather-legends-container">
                <div className="weather-main-legend">Legende</div>

                <div className="weather-legends">
                  <div className="weather-legend">
                    <div className="weather-legend-title">Erläuterung Befall Phytophthora</div>

                    <div className="weather-legend-row">
                      {explanationAlternariaInfestationData.map((legend, idx) => (
                        <div key={idx} className="weather-legend-item">
                          <img src={legend.icon} alt={legend.key} />
                          <span className="weather-item-label">{legend.key}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="weather-legend tendency">
                    <div className="legend-title">Tendenz</div>

                    <div className="weather-legend-row five-legends">
                      {tendencyData.map((tendency, idx) => (
                        <div key={idx} className="weather-legend-item">
                          <img src={tendency.icon} alt={tendency.key} />
                          <span className="weather-item-label">{tendency.key}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="home-recommendation university">
            <div className="recommendation-title">Empfehlungstext der Universität :</div>
            <div>{HTMLReactParser(regionData.university || "")}</div>
          </div>

          <div className="home-recommendation syngenta">
            <div className="recommendation-title">Empfehlung der Syngenta :</div>
            <div>{HTMLReactParser(regionData.syngenta || "")}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default WeatherStationData;
