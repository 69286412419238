import React, { useState } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import * as formik from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";

import { ResetPasswordModalProps } from "../../../types/LoginTypes";
import ModalComponent from "../modalcomponent/ModalComponent";
import ToolTip from "@/components/tooltips/ToolTip";
import Loader from "@/components/loader/Loader";
import ShowPasswordButton from "@/components/showPassword/ShowPasswordButton";

import { resetPasswordAPI } from "@/util/http/auth/auth.index";
import { passwordRegex } from "@/util/regexAll";
import { IResetPasswordType } from "@/util/http/auth/auth.requestType";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import LockIcon from "../../../assets/lock.svg";
import "./style.less";

type resetPasswordValuesTypes = {
  password: string;
  confirmPassword: string;
};

const ResetPasswordModal = ({
  openResetPasswordModal,
  setOpenResetPasswordModal,
}: ResetPasswordModalProps) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const [showResetConfirmPassword, setShowResetConfirmPassword] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  // get the token
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  // if no token, then redirect user to /auth/login path
  if (!token) {
    navigate("/auth/login", { replace: true });
  }

  // console.log(token);

  const { Formik } = formik;
  const schema = yup.object().shape({
    password: yup
      .string()
      .required("Passwort ist erforderlich")
      .min(8, "Ihr Passwort muss mindestens 8 Zeichen lang sein.")
      .max(16, "Ihr Passwort darf maximal 16 Zeichen lang sein.")
      .matches(passwordRegex.regex, passwordRegex.errorMessage),
    confirmPassword: yup
      .string()
      .required("Passwort bestätigen ist erforderlich")
      .oneOf([yup.ref("password"), null], "Die Passwörter müssen übereinstimmen."),
  });

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const handlePasswordResetSubmit = async (values: resetPasswordValuesTypes) => {
    setIsLoading(true);
    console.log(values);
    try {
      const data: IResetPasswordType = {
        token: token,
        password: values.password,
      };

      const res = await resetPasswordAPI(data);
      if (res.status === 200) {
        setIsSuccess(true);
        setIsError(false);
      } else {
        setIsSuccess(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsSuccess(false);
      console.log(error);
      setIsLoading(false);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 8000);
      logAPIErrorsFromUI(error);
    }
  };

  return (
    <ModalComponent
      modalShow={openResetPasswordModal}
      setModalShow={setOpenResetPasswordModal}
      title={isSuccess ? "" : "Passwort zurücksetzen"}
      redirectTo="/auth/login"
    >
      {isSuccess === false ? (
        <div className="reset-password-container">
          <div className="provide-reset-text">
            Geben Sie Ihr gewünschtes Passwort ein. Es muss sich von zuvor verwendeten Passwörtern
            unterscheiden.
          </div>
          <Formik
            validationSchema={schema}
            onSubmit={(values) => handlePasswordResetSubmit(values)}
            initialValues={initialValues}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit} className="w-100">
                <Row className="mb-3">
                  <Form.Group as={Col} md="9" controlId="reset-password">
                    <Form.Label className="text-start">
                      Passwort<span className="required-field">*</span>
                      <ToolTip tooltipText={passwordRegex.errorMessage} />
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        size="sm"
                        required
                        type={showResetPassword ? "text" : "password"}
                        placeholder="Geben Sie Ihr neues Passwort ein"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!touched.password && !!errors.password}
                      />
                      <ShowPasswordButton
                        showPassword={showResetPassword}
                        setShowPassword={setShowResetPassword}
                      />
                      {touched.password && (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.password}</>
                        </Form.Control.Feedback>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} md="9" controlId="reset-confirm-password">
                    <Form.Label>
                      Passwort bestätigen<span className="required-field">*</span>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        size="sm"
                        required
                        type={showResetConfirmPassword ? "text" : "password"}
                        placeholder="Geben Sie Ihr neues Passwort erneut ein"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        isInvalid={!!touched.confirmPassword && !!errors.confirmPassword}
                      />
                      <ShowPasswordButton
                        showPassword={showResetConfirmPassword}
                        setShowPassword={setShowResetConfirmPassword}
                      />
                      {touched.confirmPassword && (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.confirmPassword}</>
                        </Form.Control.Feedback>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Row>

                {isError && (
                  <div className="text-danger mb-2">
                    Etwas ist schief gelaufen! Bitte versuchen Sie es später noch einmal.
                  </div>
                )}

                <div className="reset-password-button-container">
                  <button
                    type="submit"
                    className={`reset-password-button ${
                      !values.confirmPassword || !values.password ? "disable-button" : ""
                    }`}
                    disabled={!values.confirmPassword || !values.password}
                  >
                    {isLoading ? <Loader /> : "Passwort zurücksetzen"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="reset-password-container-success">
          <div className="reset-password-success-heading">Ihr Passwort wird aktualisiert.</div>
          <div>
            <img src={LockIcon} alt="password reset success icon" />
          </div>

          <div className="provide-reset-success-text">
            Ihr Passwort wurde erfolgreich aktualisiert. Sie können sich nun mit Ihrem neuen
            Passwort anmelden.
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

export default ResetPasswordModal;
