import React from "react";
import InfoIcon from "../../assets/info.svg";

type ToolTipPropTypes = {
  tooltipText: string;
  isPassword?: boolean;
};

const ToolTip = ({ tooltipText, isPassword = true }: ToolTipPropTypes) => {
  return (
    <span className="ms-1">
      <span className="custom-password-tooltip ms-2">
        <img src={InfoIcon} alt="info icon" />
        <span
          className={`custom-password-tooltiptext ${
            isPassword === true ? "" : "custom-other-tooltiptext"
          }`}
        >
          {tooltipText}
        </span>
      </span>
    </span>
  );
};

export default ToolTip;
