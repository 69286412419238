import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/redux/reduxHooks";

import UserProfile from "./userProfile/UserProfile";
import UserSecurity from "./userSecurity/UserSecurity";
import Loader from "@/components/loader/Loader";

import { deleteUserAPI } from "@/util/http/admin/admin.index";
import { logoutUser } from "@/redux/reducers-or-slices/userSlice";
import { resetGeoDetails } from "@/redux/reducers-or-slices/geoDetailsSlice";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import CloseIcon from "../../../assets/close.svg";
import "./style.less";

type UserSettingsModalPropTypes = {
  openSettingsModal: boolean;
  setOpenSettingsModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserSettingsModal = ({
  openSettingsModal,
  setOpenSettingsModal,
}: UserSettingsModalPropTypes) => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isAccountDeleted, setIsAccountDeleted] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [activeSettingsTab, setActiveSettingsTab] = useState<string>("my-profile");

  const accountDeletionHandler = async () => {
    const text = "Sind Sie sicher, dass Sie dieses Konto löschen möchten?";
    if (confirm(text) == false) {
      return;
    }

    setIsDeleteLoading(true);
    try {
      // console.log("accountDeletionHandler called");

      const res = await deleteUserAPI(user.email);
      if (res.status === 200) {
        // console.log("account deleted");
        setIsAccountDeleted(true);
        console.log(localStorage.removeItem("syngenta"));
        sessionStorage.removeItem("pmodel-token");
        //dispatch logout action to redux
        dispatch(logoutUser());
        dispatch(resetGeoDetails());

        //navigate user to register page
        navigate("/auth/register", { replace: true });
      }
      setIsDeleteLoading(false);
    } catch (error) {
      console.log(error);
      setIsDeleteLoading(false);
      logAPIErrorsFromUI(error);
    }
  };

  if (!openSettingsModal) return;

  return (
    <>
      <div className="settings-custom-modal">
        <div className="settings-custom-modal-content">
          <img
            src={CloseIcon}
            alt="close icon"
            className="settings-close-btn pointer"
            onClick={() => setOpenSettingsModal(false)}
          />

          <div className="settings-custom-modal-body">
            <div className="settings-side-nav">
              <div className="settings-tabs">
                <button
                  className={`settings-tab ${
                    activeSettingsTab == "my-profile" && "settings-active"
                  }`}
                  onClick={() => setActiveSettingsTab("my-profile")}
                >
                  Mein Profil
                </button>
                <button
                  className={`settings-tab ${activeSettingsTab == "security" && "settings-active"}`}
                  onClick={() => setActiveSettingsTab("security")}
                >
                  Sicherheit
                </button>
              </div>

              <div className="settings-delete-account">
                <hr className="divider"></hr>

                <div className="settings-delete-account-text">Löschen des Kontos</div>

                <hr className="divider"></hr>

                <div className="settings-delete-account-prompt-text">
                  Sie werden gebeten, die Löschung des Kontos zu bestätigen. Nach dem Löschen des
                  Kontos können Sie sich nicht mehr Ihren persönlichen Anmeldeinformationen
                  anmelden.
                </div>

                <div className="settings-delete-account-button-div">
                  <button
                    className="text-center settings-delete-account-button"
                    type="button"
                    onClick={accountDeletionHandler}
                  >
                    {isDeleteLoading ? <Loader /> : "KONTO LÖSCHEN"}
                  </button>
                </div>

                {isAccountDeleted && (
                  <div className="settings-account-deleted-message">
                    Das Konto wurde erfolgreich gelöscht.
                  </div>
                )}
              </div>
            </div>

            <div className="settings-custom-body">
              <div className="settings-custom-body-title">
                <span className="settings-custom-body-title-text">
                  {activeSettingsTab === "security" ? "Sicherheit" : "Mein Profil"}
                </span>
                <hr className="security-ruler"></hr>
              </div>
              <div className="settings-custom-body-subtitle-text">
                {activeSettingsTab === "security"
                  ? "Aktualisieren Sie hier Ihr Passwort"
                  : "Aktualisieren Sie hier Ihre persönlichen Daten"}
              </div>

              <div className="settings-custom-body-tab-content">
                {activeSettingsTab === "security" ? <UserSecurity /> : <UserProfile />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSettingsModal;
