import ArrowDownCircleIcon from "../../../assets/arrow-down-circle.svg";
import ArrowUpCircleIcon from "../../../assets/arrow-up-circle.svg";
import ArrowRightCircleIcon from "../../../assets/arrow-right-circle.svg";
import ArrowRightDownCircleIcon from "../../../assets/arrow-right-down-circle.svg";
import ArrowRightUpCircleIcon from "../../../assets/arrow-right-up-circle.svg";

import RoundIcon from "../../../assets/round.svg";
import RoundGreenIcon from "../../../assets/round-green.svg";
import RoundDarkGreenIcon from "../../../assets/round-dark-green.svg";
import RoundYellowIcon from "../../../assets/round-yellow.svg";
import RoundOrangeIcon from "../../../assets/round-orange.svg";
import RoundRedIcon from "../../../assets/round-red.svg";

export const explanationPhytophthoraInfestationData = [
  { key: "Kein Befall", icon: RoundIcon, value: 0 },
  { key: "Sehr gering", icon: RoundGreenIcon, value: 1 },
  { key: "Gering", icon: RoundDarkGreenIcon, value: 2 },
  { key: "Mittel", icon: RoundYellowIcon, value: 3 },
  { key: "Hoch", icon: RoundOrangeIcon, value: 4 },
  { key: "Sehr hoch", icon: RoundRedIcon, value: 5 },
];

export const explanationAlternariaInfestationData = [
  { key: "Kein Befall", icon: RoundIcon, value: 0 },
  { key: "Gering", icon: RoundGreenIcon, value: 2 },
  { key: "Mittel", icon: RoundYellowIcon, value: 3 },
  { key: "Hoch", icon: RoundOrangeIcon, value: 4 },
];

export const tendencyData = [
  { tendencyValue: 1, key: "Fallend", icon: ArrowDownCircleIcon },
  { tendencyValue: 2, key: "Leicht fallend", icon: ArrowRightDownCircleIcon },
  { tendencyValue: 3, key: "Gleichbleibend", icon: ArrowRightCircleIcon },
  { tendencyValue: 4, key: "Leicht steigend", icon: ArrowRightUpCircleIcon },
  { tendencyValue: 5, key: "Steigend", icon: ArrowUpCircleIcon },
];
