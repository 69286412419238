import { AxiosResponse } from "axios";
import { http } from "../http";
import {
  ISaveUserRegistrationReqTypes,
  ILoginType,
  IResetPasswordType,
  IVerifyUserEmailFromEmailType,
} from "./auth.requestType";

// all home page APIs
export const registerUserAPI = async (data: ISaveUserRegistrationReqTypes) => {
  const url = "/api/User/SaveUserRegisterData";
  const res: AxiosResponse = await http.post(url, data);
  return res;
};

export const loginUserAPI = async (data: ILoginType) => {
  const url = "/api/User/login";
  const res: AxiosResponse = await http.post(url, data);
  return res;
};

export const verifyUserEmailFromEmailAPI = async (data: IVerifyUserEmailFromEmailType) => {
  const url = "/api/User/verifyemail";
  const res: AxiosResponse = await http.get(url, data);
  return res;
};

export const resendVerificationMailAPI = async (email: string) => {
  const url = `/api/User/ResendVerificationMail/${email}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const verifyIsUserEmailExistsAPI = async (email: string) => {
  const url = `/api/User/isemailexist/${email}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const getTermsAndConditionsAPI = async () => {
  const url = "/api/User/GetStaticContent";
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const sendResetPasswordEmailAPI = async (email: string) => {
  const url = `/api/User/SendResetPasswordEmail?email=${email}`;
  const res: AxiosResponse = await http.get(url);
  return res;
};

export const resetPasswordAPI = async (data: IResetPasswordType) => {
  const url = "/api/User/ResetPassword";
  const res: AxiosResponse = await http.post(url, data);
  return res;
};

// this method is used to log the errors in frontend
export const logApplicationErrorAPI = async (data: any) => {
  const url = "/api/User/LogApplicationError";
  const res: AxiosResponse = await http.post(url, data);
  return res;
};
