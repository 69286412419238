import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./reducers-or-slices/userSlice";
import geoDetailsReducer from "./reducers-or-slices/geoDetailsSlice";

const rootReducer = combineReducers({
  user: userReducer,
  geoDetails: geoDetailsReducer,
});

const reduxStore = configureStore({
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reduxStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof reduxStore.dispatch;

export default reduxStore;
