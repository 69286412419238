import { logApplicationErrorAPI } from "./http/auth/auth.index";

type ApplicationErrorType = {
  errorMessage: string;
  errorFrom: string;
  stackTrace: string;
};

// to log the errors in console and db.
export const logApplicationError = async (
  errorMessage: string,
  errorFrom: "UI" | "API",
  stackTrace: string
) => {
  try {
    const data: ApplicationErrorType = { errorMessage, errorFrom, stackTrace };

    const res = await logApplicationErrorAPI(data);
    if (res.data === true) console.log("Error logged to DB successfully");
    else console.log("Failed to log errors in DB");
  } catch (error) {
    console.log("Failed to log errors in DB", error);
  }
};

export const logAPIErrorsFromUI = (error) => {
  logApplicationError(error.message, "UI", JSON.stringify(error?.response?.data) || error.message);
};
