// import { Suspense, lazy, useEffect, useState } from "react";
import { Suspense, useEffect, useState } from "react";
import { createHashRouter } from "react-router-dom";

import Loader from "@/components/loader/Loader";
import ErrorBoundary from "@/components/errorBoundary/ErrorBoundary";

//layout
import AuthLayout from "../components/layouts/auth/AuthLayout";
import DashBoardLayout from "@/components/layouts/dashboard/DashBoardLayout";
import HomeLayout from "@/components/layouts/home/HomeLayout";
import LegalsLayout from "@/components/layouts/legals/LegalsLayout";

// components
import Register from "../pages/register/Register";
import AccountActivation from "../pages/accountActivation/AccountActivation";
import Login from "../pages/login/Login";
import ResetPassword from "../pages/resetPassword/ResetPassword";
import RecoverPassword from "../pages/recoverPassword/RecoverPassword";

import Home from "../pages/home/Home";
import NotFound from "../pages/notFound/NotFound";
import EmailTemplateContainerPage from "../pages/emailTemplate/EmailTemplateContainerPage";
import Dashboard from "../pages/dashboard/Dashboard";
import DashboardUsers from "../pages/users/DashboardUsers";
// import DashboardServiceEmails from "../pages/serviceEmails/DashboardServiceEmails";

import LegalInformation from "../pages/legals/legalInformation/LegalInformation";
import TermsAndConditions from "../pages/legals/termsAndConditions/TermsAndConditions";
import DataPrivacy from "../pages/legals/dataPrivacy/DataPrivacy";
import CookieGuidelines from "../pages/legals/cookieGuidelines/CookieGuidelines";

// eslint-disable-next-line react/display-name
const Loadable = (Component: React.FC) => (props: object) => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => --prevCount);
    }, 1000);

    if (count === 0) clearInterval(interval);

    return () => {
      clearInterval(interval);
    };
  }, [count]);

  window.scrollTo(0, 0);

  return (
    <ErrorBoundary>
      <Suspense fallback={<></>}>
        {count === 0 ? (
          <Component {...props} />
        ) : (
          <div className="initial-loader">
            <Loader />
          </div>
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

const RegisterPage = Loadable(Register);
const AccountActivationPage = Loadable(AccountActivation);
const LoginPage = Loadable(Login);
const ResetPasswordPage = Loadable(ResetPassword);
const RecoverPasswordPage = Loadable(RecoverPassword);

const HomePage = Loadable(Home);
const NotFoundPage = Loadable(NotFound);
const EmailTemplatePage = Loadable(EmailTemplateContainerPage);

const DashboardPage = Loadable(Dashboard);
const DashboardUsersPage = Loadable(DashboardUsers);
// const DashboardServiceEmailsPage = Loadable(DashboardServiceEmails);

// legals
const LegalInformationPage = Loadable(LegalInformation);
const TermsAndConditionsPage = Loadable(TermsAndConditions);
const DataPrivacyPage = Loadable(DataPrivacy);
const CookieGuidelinesPage = Loadable(CookieGuidelines);

// all the routing logic is written here

const routers = createHashRouter([
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "register",
        element: <RegisterPage />,
        children: [
          { path: "activate-account/:firstName/:lastName", element: <AccountActivationPage /> },
        ],
      },
      {
        path: "login",
        element: <LoginPage />,
        children: [
          { path: "recover-password", element: <RecoverPasswordPage /> },
          { path: "reset-password", element: <ResetPasswordPage /> },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      { path: "home", element: <HomePage /> },
      { path: "email-template", element: <EmailTemplatePage /> },
      {
        path: "dashboard",
        element: <DashBoardLayout />,
        children: [
          { index: true, element: <DashboardPage /> },
          { path: "users", element: <DashboardUsersPage /> },
          // { path: "service-email", element: <DashboardServiceEmailsPage /> },
        ],
      },
    ],
  },
  {
    path: "/unternehmen",
    element: <LegalsLayout />,
    children: [
      { path: "impressum", element: <LegalInformationPage /> },
      { path: "nutzungsbedingungen", element: <TermsAndConditionsPage /> },
      { path: "datenschutz", element: <DataPrivacyPage /> },
      { path: "cookie-richtlinie", element: <CookieGuidelinesPage /> },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default routers;
