import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
// import store from "./redux/reducer/store";
import store from "./redux/store";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
