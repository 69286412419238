import React from "react";
import { DrawerComponentProps } from "@/types/DashboardUsersTypes";
import { Offcanvas } from "react-bootstrap";

import "./style.less";

// right side drawer component for showing all users and one user details
const DrawerComponent = ({ show, handleClose, title, children }: DrawerComponentProps) => {
  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DrawerComponent;
