import React, { useEffect, useState } from "react";
import { Form, Row, Col, Table, Container } from "react-bootstrap";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import AddUsersDrawer from "@/components/drawers/addUsers/AddUsersDrawer";
import UserDetailsDrawer from "@/components/drawers/userDetails/UserDetailsDrawer";
import Loader from "@/components/loader/Loader";

import { getAllUserDetailsAPI, getExportToExcelDataAPI } from "@/util/http/admin/admin.index";
import { getUserRegistrationDetailsAPI } from "@/util/http/home/home.index";

import {
  IUserDetailsResTypes,
  IAllUserDetailsResTypes,
} from "@/util/http/admin/admin.responseType";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import ArrowDownIcon from "../../assets/arrow-down.svg";
import "./style.less";

const DashboardUsers = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExportToExcelLoading, setIsExportToExcelLoading] = useState<boolean>(false);

  const [showAddUserDrawer, setShowAddUserDrawer] = useState<boolean>(false);
  const [showUserDetailsDrawer, setShowUserDetailsDrawer] = useState<boolean>(false);

  const [allUserDetails, setAllUserDetails] = useState<IAllUserDetailsResTypes[]>([]);
  const [selectedUserDetails, setSelectedUserDetails] = useState<IUserDetailsResTypes>();

  //search states
  const [filteredUserDetails, setFilteredUserDetails] = useState<IAllUserDetailsResTypes[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  //sort states
  //const [sortOrder, setSortOrder] = useState<string>("");

  const handleAddUserDrawerClose = () => setShowAddUserDrawer(false);
  const handleAddUserDrawerShow = () => setShowAddUserDrawer(true);

  const handleUserDetailsDrawerClose = () => setShowUserDetailsDrawer(false);
  const handleUserDetailsDrawerShow = () => setShowUserDetailsDrawer(true);

  const getAllUsersDetails = async () => {
    setIsLoading(true);
    try {
      const res = await getAllUserDetailsAPI();
      if (res.status === 200) {
        setAllUserDetails(res.data);
        setFilteredUserDetails(res.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      // setAllUserDetails(data);
      setIsLoading(false);
      logAPIErrorsFromUI(error);
    }
  };

  const fetchSingleUserDetails = async (email: string) => {
    try {
      const res = await getUserRegistrationDetailsAPI(email);
      if (res.status === 200) {
        setSelectedUserDetails(res.data);

        handleUserDetailsDrawerShow();
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
      // const dt = data.find((user) => user.email === userData.email);
      // setUserData(dt);
    }
  };

  const exportToExcelHandler = async () => {
    setIsExportToExcelLoading(true);
    try {
      const res = await getExportToExcelDataAPI();

      const respData = res.data;

      if (res.status === 200 && respData.length > 0) {
        // create a workbook
        const workbook = new ExcelJS.Workbook();

        // create a worksheet
        const worksheet = workbook.addWorksheet("Active Users");

        // get all the keys of the users and assign to worksheet
        const cols = Object.keys(respData[0]).map((col) => ({ key: col, header: col }));

        // styling of a header cols
        cols.forEach((col, idx) => {
          const cell = String.fromCharCode(65 + idx) + "1";
          worksheet.getCell(cell).font = {
            name: "Noto Sans Display",
            size: 10,
            bold: true,
          };
          worksheet.getCell(cell).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF00" },
            bgColor: { argb: "FF0000FF" },
          };
        });

        worksheet.columns = cols;

        // add the data row of all the users
        respData.forEach((user) => {
          worksheet.addRow(user);
        });

        // auto resize the column width based on its content
        worksheet.columns.forEach((column) => {
          let maxLength = 0;
          column["eachCell"]({ includeEmpty: true }, (cell) => {
            const columnLength = cell.value ? cell.value.toString().length + 2 : 10;
            if (columnLength > maxLength) {
              maxLength = columnLength + 2;
            }
          });

          // width limitation
          if (maxLength < 10) {
            maxLength = 10;
          } else if (maxLength > 40) {
            maxLength = 40;
          }
          column.width = maxLength;
        });

        // writeFile fn doesn't work for UI so, writeBuffer
        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const fileExtension = ".xlsx";

        const date = new Intl.DateTimeFormat("de-de", {
          dateStyle: "full",
          timeStyle: "medium",
        }).format(new Date());

        const fileName = "Pmodel_users_" + date + fileExtension;

        // create a blob out of the buffer
        const blob = new Blob([buffer], { type: fileType });

        // use file-saver package to save the file in your local drive
        saveAs(blob, fileName);
        alert("Benutzerdatei erfolgreich heruntergeladen");
      } else {
        alert("Benutzerdatei konnte nicht heruntergeladen werden");
      }

      setIsExportToExcelLoading(false);
    } catch (error) {
      console.log(error);
      setIsExportToExcelLoading(false);
      alert("Benutzerdatei konnte nicht heruntergeladen werden");
      logAPIErrorsFromUI(error);
    }
  };

  // format: dd/mm/yyyy
  const generateDateInFormat = (date): string => {
    if (!date) return "";

    let ans = "";
    const dt = new Date(date);

    // date
    let d = dt.getDate() + "";
    if (Number(d) <= 9) d = "0" + d;

    // month
    let m = Number(dt.getMonth() + 1) + "";
    if (Number(m) <= 9) m = "0" + m;

    // year
    const y = dt.getFullYear();

    ans = `${d}/${m}/${y}`;

    return ans;
  };

  // search logic here
  const filterDataOnSearch = () => {
    const filteredData = allUserDetails.filter(
      (userDt) =>
        `${userDt.firstName} ${userDt.lastName}`.toLowerCase().includes(searchText.toLowerCase()) ||
        userDt.email.toLowerCase().includes(searchText.toLowerCase())
    );

    setFilteredUserDetails(filteredData);
  };

  //sort logic
  //const sortDataFunction = () => {
  //  const sortedDt = [...filteredUserDetails];
  //  if (sortOrder === "asc") {
  //    // sort ascending
  //    sortedDt.sort((a, b) => {
  //      const fullNameOfA = `${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()}`;
  //      const fullNameOfB = `${b.firstName.toLowerCase()} ${b.lastName.toLowerCase()}`;

  //      if (fullNameOfA > fullNameOfB) return 1;
  //      if (fullNameOfA < fullNameOfB) return -1;
  //      return 0;
  //    });
  //  } else {
  //    // sort descending
  //    sortedDt.sort((a, b) => {
  //      const fullNameOfA = `${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()}`;
  //      const fullNameOfB = `${b.firstName.toLowerCase()} ${b.lastName.toLowerCase()}`;

  //      if (fullNameOfA < fullNameOfB) return 1;
  //      if (fullNameOfA > fullNameOfB) return -1;
  //      return 0;
  //    });
  //  }

  //  setFilteredUserDetails(sortedDt);
  //};

  // search effect
  useEffect(() => {
    if (!searchText) {
      setFilteredUserDetails(allUserDetails);
      return;
    }

    filterDataOnSearch();
  }, [searchText]);

  // sort effect
  //useEffect(() => {
  //  sortDataFunction();
  //}, [sortOrder]);

  // when user closes the drawer then this effect will be called
  useEffect(() => {
    if (showUserDetailsDrawer || showAddUserDrawer) return;

    getAllUsersDetails();
  }, [showUserDetailsDrawer, showAddUserDrawer]);

  //sort
  const [sortConfig, setSortConfig] = useState({ key: null, order: "asc" });

  const sortedData = () => {
    const sortedDt = [...filteredUserDetails];

    if (sortConfig.key=="name") {
      sortedDt.sort((a, b) => {
        const valueA = `${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()}`;
        const valueB = `${b.firstName.toLowerCase()} ${b.lastName.toLowerCase()}`;
        if (valueA < valueB) {
          return sortConfig.order === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.order === "asc" ? 1 : -1;
        }
        return 0;
      });
    }else{
      sortedDt.sort((a, b) => {
        const valueA = String(a[sortConfig.key]).toLowerCase();
        const valueB = String(b[sortConfig.key]).toLowerCase();

        if (valueA < valueB) {
          return sortConfig.order === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.order === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredUserDetails(sortedDt);
  };

  const toggleSortOrder = (key) => {
    setSortConfig({
      key,
      order: sortConfig.key === key && sortConfig.order === "asc" ? "desc" : "asc",
    });
  };

  useEffect(() => {
    sortedData();
  }, [sortConfig]);
  return (
    <>
      <Container fluid>
        <div className="users-container">
          <div className="users-header">
            <div className="headers-left">
              <Form>
                <Row>
                  <Form.Group as={Col} md={8} controlId="search-user">
                    <Form.Control
                      type="text"
                      placeholder="Benutzer suchen..."
                      className="no-default-form-control"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Form.Group>
                </Row>
              </Form>
            </div>
            <div className="headers-right">
              <button className="export-to-excel-btn" onClick={exportToExcelHandler}>
                {isExportToExcelLoading ? <Loader /> : "Nach Excel exportieren"}
              </button>
              <button className="add-user-btn" onClick={handleAddUserDrawerShow}>
                + Benutzer hinzufügen
              </button>
            </div>
          </div>
          <div className="users-content">
            <div className="user-table">
              <Table striped responsive>
                <thead>
                  <tr>
                    <th className="pointer table-header" onClick={() => toggleSortOrder("name")}>
                      Name
                      {sortConfig.key === "name" && (
                        <img
                          src={ArrowDownIcon}
                          alt="Arrow Down Icon"
                          className={`ms-2 ${sortConfig.order === "desc" && "rotate180"}`}
                        />
                      )}
                    </th>
                    <th className="pointer table-header" onClick={() => toggleSortOrder("email")}>
                    E-Mail-Adresse
                      {sortConfig.key === "email" && (
                        <img
                          src={ArrowDownIcon}
                          alt="Arrow Down Icon"
                          className={`ms-2 ${sortConfig.order === "desc" && "rotate180"}`}
                        />
                      )}
                    </th>
                    <th className="pointer table-header" onClick={() => toggleSortOrder("isActive")}>
                    Status
                      {sortConfig.key === "isActive" && (
                        <img
                          src={ArrowDownIcon}
                          alt="Arrow Down Icon"
                          className={`ms-2 ${sortConfig.order === "desc" && "rotate180"}`}
                        />
                      )}
                    </th>
                    <th className="pointer table-header" onClick={() => toggleSortOrder("last_Login")}>
                    Letzte Anmeldung
                      {sortConfig.key === "last_Login" && (
                        <img
                          src={ArrowDownIcon}
                          alt="Arrow Down Icon"
                          className={`ms-2 ${sortConfig.order === "desc" && "rotate180"}`}
                        />
                      )}
                    </th>
                    <th className="pointer table-header" onClick={() => toggleSortOrder("created_Date")}>
                    Datum des Abonnements
                      {sortConfig.key === "created_Date" && (
                        <img
                          src={ArrowDownIcon}
                          alt="Arrow Down Icon"
                          className={`ms-2 ${sortConfig.order === "desc" && "rotate180"}`}
                        />
                      )}
                    </th>
                    <th className="pointer table-header" onClick={() => toggleSortOrder("isEmailVerified")}>
                    Email Confirmed
                      {sortConfig.key === "isEmailVerified" && (
                        <img
                          src={ArrowDownIcon}
                          alt="Arrow Down Icon"
                          className={`ms-2 ${sortConfig.order === "desc" && "rotate180"}`}
                        />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <tr>
                      <td colSpan={6} className="loader-div">
                        <Loader />
                      </td>
                    </tr>
                  )}

                  {!isLoading &&
                    filteredUserDetails.map((user) => (
                      <tr key={user.userId}>
                        {/* <td>
                      <Form.Check type="checkbox" id={`default-${user.userId}`} />
                    </td> */}
                        <td className="table-data">
                          <span
                            className="users-name"
                            onClick={() => {
                              fetchSingleUserDetails(user.email);
                            }}
                          >
                            {user.firstName + " " + user.lastName}
                          </span>
                        </td>
                        <td className="table-data">{user.email}</td>
                        <td className="table-data">
                          <span
                            className={`users-status ${(user.isActive
                              ? "ACTIVE"
                              : "INACTIVE"
                            ).toLowerCase()}`}
                          >
                            {user.isActive ? "ACTIVE" : "INACTIVE"}
                          </span>
                        </td>
                        <td className="table-data">{generateDateInFormat(user.last_Login)}</td>
                        <td className="table-data">{generateDateInFormat(user.created_Date)}</td>
                        <td className="table-data">{user.isEmailVerified ? "True" : "False"}</td>

                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>

      {showAddUserDrawer && (
        <AddUsersDrawer show={showAddUserDrawer} handleClose={handleAddUserDrawerClose} />
      )}

      {showUserDetailsDrawer && (
        <UserDetailsDrawer
          show={showUserDetailsDrawer}
          handleClose={handleUserDetailsDrawerClose}
          userData={selectedUserDetails}
          setUserData={setSelectedUserDetails}
        />
      )}
    </>
  );
};

export default DashboardUsers;
