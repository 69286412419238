import React from "react";
import { Link } from "react-router-dom";

import "./style.less";

const NotFound = () => {
  return (
    <div className="not-found-div">
      <div className="text-center">
        <h1>ENTSCHULDIGUNG, SEITE NICHT GEFUNDEN</h1>
        <h1>4🙁4</h1>
        <br />
        Hier klicken, um zur <Link to={"/home"}>Startseite</Link> zu gelangen
      </div>
    </div>
  );
};

export default NotFound;
