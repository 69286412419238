import React from "react";
import HTMLReactParser from "html-react-parser";
import { useAppSelector } from "@/redux/reduxHooks";

import { WeatherStationDataEmailTableDataType } from "@/types/EmailTemplateTypes";
import {
  explanationAlternariaInfestationData,
  explanationPhytophthoraInfestationData,
  infestationColor,
  tendencyData,
} from "./emailLegends";

import PModelBanner from "../../assets/pmodel-banner.svg";
import "./emailTemplate.less";

type EmailTemplatePropType = {
  emailSendDate: string;
  emailTitle: string;
  emailSalutation: string;
  emailTeaserText: string;
  emailRecommendedContent: { university: string; syngenta: string };
  emailTableData: WeatherStationDataEmailTableDataType[];
};

const EmailTemplate = ({
  emailRecommendedContent,
  emailTitle,
  emailSalutation,
  emailTeaserText,
  emailTableData,
  emailSendDate,
}: EmailTemplatePropType) => {
  const user = useAppSelector((state) => state.user);

  return (
    <div className="email-template">
      <div className="email-header">
        <div className="date">{emailSendDate}</div>

        <img src={PModelBanner} alt="PModel Banner" className="banner-img" />
      </div>

      <div className="email-body">
        <div className="email-title" id="email-title">
          {/* {htmlToText(emailTitle)} */}
          {HTMLReactParser(emailTitle || "")}
        </div>
        <div className="email-salutation">
          {/* {htmlToText(emailSalutation)} */}
          {HTMLReactParser(emailSalutation || "")}&nbsp;{user.salutation} {user.firstName}{" "}
          {user.lastName}!
        </div>

        <div className="email-teaser-text" id="email-teaser-text">
          {/* {htmlToText(emailTeaserText)} */}
          {HTMLReactParser(emailTeaserText || "")}
        </div>

        <div className="email-recommendation university">
          <div className="recommendation-title">Empfehlungstext der Universität :</div>

          <div
            className="email-recommendation-text-by-university"
            id="email-recommendation-text-by-university"
          >
            {/* {htmlToText(emailRecommendedContent.university)} */}
            {HTMLReactParser(emailRecommendedContent.university || "")}
          </div>
        </div>

        <div className="email-recommendation syngenta">
          <div className="recommendation-title">Empfehlung der Syngenta :</div>

          <div
            className="email-recommendation-text-by-syngenta"
            id="email-recommendation-text-by-syngenta"
          >
            {/* {htmlToText(emailRecommendedContent.syngenta)} */}
            {HTMLReactParser(emailRecommendedContent.syngenta || "")}
          </div>
        </div>

        <div className="table-container">
          <h4 className="table-title">Alles auf einen Blick</h4>
          <p className="para-text">Ihre regionalen Boniturergebnisse und Prognosen für Berlin</p>

          <div className="tables">
            {emailTableData.map((weatherStationData, idx) => (
              <table key={idx} className="scoutedFieldsTable">
                <tbody>
                  <tr className="weatherStationHeader">
                    <td colSpan={6} className="table-heading weatherStationName">
                      Wetterstation {weatherStationData.weatherStationName}
                    </td>
                  </tr>

                  <tr className="tableHeader1">
                    <td className="column">Witterungsbedingter Epidemiedruck Phytophthora</td>
                    <td className="column">3-Tages-Prognose Phytophthora</td>
                    <td className="column" colSpan={2}>
                      Witterungsbedingter Epidemiedruck Alternaria
                    </td>
                    <td className="column">3-Tages- Prognose Alternaria</td>
                    <td className="column">Spritzabstand</td>
                  </tr>

                  <tr>
                    <td className="data-column">
                      {weatherStationData.pytophthoraEpidemicPressure}
                    </td>
                    <td className="data-column">
                      <img
                        src={
                          tendencyData.find(
                            (tnd) => tnd.tendencyValue == weatherStationData.threeDayForecast
                          )?.icon
                        }
                        alt={
                          tendencyData.find(
                            (tnd) => tnd.tendencyValue == weatherStationData.threeDayForecast
                          )?.key
                        }
                      />
                    </td>
                    <td className="data-column" colSpan={2}>
                      {weatherStationData.alternariaEpidemicPressure}
                    </td>
                    <td className="data-column">
                      <img
                        src={
                          tendencyData.find(
                            (tnd) =>
                              tnd.tendencyValue == weatherStationData.threeDayPrognosisAlternaria
                          )?.icon
                        }
                        alt={
                          tendencyData.find(
                            (tnd) =>
                              tnd.tendencyValue == weatherStationData.threeDayPrognosisAlternaria
                          )?.key
                        }
                      />
                    </td>
                    <td className="data-column">{weatherStationData.sprayDistance}</td>
                  </tr>

                  <tr className="tableHeader2">
                    <td className="table-heading weatherStationMonitoringText" colSpan={6}>
                      Praxisschlag (Monitoring-Fläche)
                    </td>
                  </tr>

                  <tr className="tableHeader3">
                    <td className="column">Standort (LKR)</td>
                    <td className="column">Sorte (Auflauftermin)</td>
                    <td className="column">Befall (unbehandelt)</td>
                    <td className="column">Anzahl Spritzungen</td>
                    <td className="column">Phytophthora Befall</td>
                    <td className="column">Alternaria Befall</td>
                  </tr>

                  {weatherStationData.emailScoutingTables.map((field, idx) => (
                    <tr key={idx}>
                      <td className="data-column" rowSpan={1}>
                        {field.location}
                      </td>
                      <td className="data-column">{field.variety}</td>
                      <td className="data-column">{field.infestation}</td>
                      <td className="data-column">{field.numberOfInjection}</td>
                      <td align="center" className="data-column">
                        <img
                          src={
                            infestationColor.find((inf) => inf.key === field.pytophthoraInfestation)
                              ?.icon
                          }
                          alt={
                            infestationColor.find((inf) => inf.key === field.pytophthoraInfestation)
                              ?.key
                          }
                        />
                      </td>
                      <td className="data-column">
                        <img
                          src={
                            infestationColor.find((inf) => inf.key === field.alternariaInfestation)
                              ?.icon
                          }
                          alt={
                            infestationColor.find((inf) => inf.key === field.alternariaInfestation)
                              ?.key
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}
          </div>
        </div>

        <div className="legends-container">
          <div className="main-legend">Legende</div>

          <div className="legends">
            <div className="legend">
              <div className="legend-title">Erläuterung Befall Phytophthora</div>

              <div className="legend-row">
                {explanationPhytophthoraInfestationData.map((phInfestation, idx) => (
                  <div className="legend-item" key={idx}>
                    <img src={phInfestation.icon} alt={phInfestation.key} />
                    <span className="item-label">{phInfestation.key}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="legend">
              <div className="legend-title">Erläuterung Befall Alternaria</div>

              <div className="legend-row">
                {explanationAlternariaInfestationData.map((phInfestation, idx) => (
                  <div className="legend-item" key={idx}>
                    <img src={phInfestation.icon} alt={phInfestation.key} />
                    <span className="item-label">{phInfestation.key}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="legend tendency">
              <div className="legend-title">Tendenz</div>

              <div className="legend-row five-legends">
                {tendencyData.map((tendency, idx) => (
                  <div className="legend-item" key={idx}>
                    <img src={tendency.icon} alt={tendency.key} />
                    <span className="item-label">{tendency.key}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplate;
