import React from "react";
import { RouterProvider } from "react-router-dom";
import routers from "./router/routers";
import "./App.less";
import "./global.less";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <div className="App">
      <RouterProvider router={routers} />
    </div>
  );
};

export default App;
