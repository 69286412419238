import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RecoverPasswordModal from "@/components/modals/recoverPassword/RecoverPasswordModal";

const RecoverPassword = () => {
  const [openRecoverPasswordModal, setOpenRecoverPasswordModal] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/auth/login/recover-password") {
      setOpenRecoverPasswordModal(true);
    }
  }, [location]);

  return (
    <>
      <RecoverPasswordModal
        openRecoverPasswordModal={openRecoverPasswordModal}
        setOpenRecoverPasswordModal={setOpenRecoverPasswordModal}
      />
    </>
  );
};

export default RecoverPassword;
