import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import * as formik from "formik";
import * as yup from "yup";

import { useAppDispatch, useAppSelector } from "@/redux/reduxHooks";

import DrawerComponent from "../drawerComponent/DrawerComponent";
import Loader from "@/components/loader/Loader";
import { UserDetailsDrawerProps } from "@/types/DashboardUsersTypes";

import { IUpdateUserTypes } from "@/util/http/admin/admin.requestType";
import { emailRegex, zipCodeRegex } from "@/util/regexAll";
import { deleteUserAPI, updateUserAPI } from "@/util/http/admin/admin.index";
import { getUserRegistrationDetailsAPI } from "@/util/http/home/home.index";
import { saveUserDetailsInLocalStorage } from "@/util/saveUserDetailsInLocalStorage";
import { loginUser } from "@/redux/reducers-or-slices/userSlice";
import { getGeoDetailsByPostalCodeAPI } from "@/util/http/emailTemplate/emailTemplate.index";
import { getGeoDetails } from "@/redux/reducers-or-slices/geoDetailsSlice";
import { logAPIErrorsFromUI } from "@/util/errorLog";

import ProfileIcon from "../../../assets/profile-avatar.svg";
import "./style.less";

type userDetailsFormDataTypes = {
  salutationName: string;
  firstName: string;
  lastName: string;
  email: string;
  streetName: string;
  zipCode: string;
  city: string;
  password: string;
  subscribeForEmailNotifications:boolean;
  isEmailVerified:boolean;
};

const UserDetailsDrawer = ({
  show,
  handleClose,
  userData,
  setUserData,
}: UserDetailsDrawerProps) => {
  const { Formik } = formik;
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
  const [isAccountDeleted, setIsAccountDeleted] = useState<boolean>(false);
  const [isUpdateUserSuccess, setIsUpdateUserSuccess] = useState<boolean>(false);
  const [isUpdateUserError, setIsUpdateUserError] = useState<boolean>(false);

  const [zipCd, setZipCd] = useState<string>("");
  const [zipCodeValidationError, setZipCodeValidationError] = useState<boolean>(false);

  const schema = yup.object().shape({
    salutationName: yup.string().required("Anrede ist erforderlich"),
    firstName: yup.string().required("Vorname ist erforderlich"),
    lastName: yup.string().required("Nachname ist erforderlich"),
    streetName: yup.string(),
    zipCode: yup
      .string()
      .required("PLZ ist erforderlich")
      .matches(zipCodeRegex.regex, zipCodeRegex.errorMessage),
    city: yup.string().required("Ort ist erforderlich"),
    email: yup
      .string()
      .required("E-Mail-Adresse ist erforderlich")
      .matches(emailRegex.regex, emailRegex.errorMessage),
    password: yup
      .string()
      .required("Passwort ist erforderlich")
      .min(8, "Ihr Passwort muss mindestens 8 Zeichen lang sein.")
      .max(16, "Ihr Passwort muss mindestens 16 Zeichen lang sein."),
    // .matches(passwordRegex.regex, passwordRegex.errorMessage),
    subscribeForEmailNotifications: yup.bool(),
  });

  const initialValues = {
    salutationName: userData.salutationName,
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    streetName: userData.streetNameAndNumber,
    zipCode: userData.zipCode,
    city: userData.city,
    password: "**********",
    subscribeForEmailNotifications: userData.subscribeForEmailNotifications,
    isEmailVerified: userData.isEmailVerified,
  };

  const handleUpdateUserDetailsSubmit = async (values: userDetailsFormDataTypes) => {
    setIsUpdateLoading(true);
    console.log(values);
    try {
      const data: IUpdateUserTypes = {
        firstName: values.firstName,
        lastName: values.lastName,
        streetNameAndNumber: values.streetName,
        salutation: values.salutationName,
        zipCode: values.zipCode,
        city: values.city,
        email: values.email,
        lastModifiedBy: user.email,
        subscribeForEmailNotifications:values.subscribeForEmailNotifications,
        isEmailVerified:values.isEmailVerified,
      };
      const res = await updateUserAPI(data);
      if (res.data.isSuccessStatusCode === true) {
        if (user.email === values.email) {
          const resp = await saveUserDetailsInLocalStorage(user.email);
          dispatch(loginUser(resp));

          const geoDetailsRes = await getGeoDetailsByPostalCodeAPI(values.zipCode);
          if (geoDetailsRes.status === 200 && geoDetailsRes.data) {
            const dt = JSON.parse(geoDetailsRes.data.geoDetails);
            dispatch(
              getGeoDetails({
                latitude: dt[0]?.latitude,
                longitude: dt[0]?.longitude,
                zipCode: dt[0]?.postalCode,
                stationId: geoDetailsRes?.data?.stationId,
              })
            );
          }
        }

        setIsUpdateUserSuccess(true);
        setIsUpdateUserError(false);
        setZipCodeValidationError(false);
      } else {
        setIsUpdateUserSuccess(false);
        setIsUpdateUserError(true);
      }
      setIsUpdateLoading(false);
    } catch (error) {
      setIsUpdateUserSuccess(false);
      setIsUpdateUserError(true);
      console.log(error);
      setIsUpdateLoading(false);
      logAPIErrorsFromUI(error);
    }
  };

  const accountDeletionHandler = async () => {
    const text = "Sind Sie sicher, dass Sie dieses Konto löschen möchten?";
    if (confirm(text) == false) {
      return;
    }
    setIsDeleteLoading(true);
    try {
      // console.log("accountDeletionHandler called");

      const res = await deleteUserAPI(userData.email);
      if (res.status === 200) {
        // console.log("account deleted");
        setIsAccountDeleted(true);
        setTimeout(() => {
          handleClose();
        }, 5000);
      }
      setIsDeleteLoading(false);
    } catch (error) {
      console.log(error);
      setIsDeleteLoading(false);
      logAPIErrorsFromUI(error);
    }
  };

  const fetchSingleUserDetails = async () => {
    try {
      const res = await getUserRegistrationDetailsAPI(userData.email);
      if (res.status === 200) {
        setUserData(res.data);
      }
    } catch (error) {
      console.log(error);
      logAPIErrorsFromUI(error);
      // const dt = data.find((user) => user.email === userData.email);
      // setUserData(dt);
    }
  };

  const getGeoDetailsByPostalCode = async (postalCode: string) => {
    try {
      // console.log("getGeoDetailsByPostalCodeAPI called");
      const resp = await getGeoDetailsByPostalCodeAPI(postalCode);
      if (resp.status === 200 && resp.data) {
        const dt = JSON.parse(resp.data.geoDetailsData);

        if (!dt[0] || !dt[0].latitude || !dt[0].longitude) {
          setZipCodeValidationError(true);
          setZipCd("");
        }
      }
    } catch (error) {
      console.log(error);
      setZipCodeValidationError(true);
      logAPIErrorsFromUI(error);
    }
  };

  useEffect(() => {
    if (!zipCd) return;

    getGeoDetailsByPostalCode(zipCd);
  }, [zipCd]);

  useEffect(() => {
    fetchSingleUserDetails();
  }, [isAccountDeleted]);

  // to generate last login date in format
  const generateLastLoginDateTimeFormat = (): string => {
    let dateTime = "";

    const dt = new Date(userData.last_Login);

    const date = dt.getDate() + "/" + Number(dt.getMonth() + 1) + "/" + dt.getFullYear();
    dateTime += date;
    dateTime += " | ";

    const time = dt.toLocaleTimeString("de-de");
    dateTime += time;

    return dateTime;
  };

  return (
    <>
      <DrawerComponent show={show} handleClose={handleClose} title="Nutzerdetails">
        <div className="drawer-container">
          <div className="profile-div">
            <div className="profile-details">
              <img src={ProfileIcon} alt="profile Icon" />
              <div className="profile-text">{userData?.firstName + " " + userData?.lastName}</div>
            </div>
            <div className="profile-activity">
              <div className="activity-text">Datum der letzten Aktivität</div>
              <div className="activity-time">{generateLastLoginDateTimeFormat()}</div>
            </div>
          </div>

          <Formik
            validationSchema={schema}
            onSubmit={(values) => handleUpdateUserDetailsSubmit(values)}
            initialValues={initialValues}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <>
                <Form noValidate onSubmit={handleSubmit} className="update-user-form">
                  <div className="drawer-form-body">
                    <Row className="mb-2 my-0">
                      <Form.Group controlId="salutations" className="no-padding">
                        <Form.Label>
                          Anrede<span className="required-field">*</span>
                        </Form.Label>

                        <div key="inline-radio">
                          {["Frau", "Herr", "Divers"].map((item, idx) => (
                            <Form.Check
                              key={idx}
                              inline
                              label={item}
                              value={item}
                              checked={item === values.salutationName}
                              name="salutationName"
                              type="radio"
                              id={`inline-radio-${idx}`}
                              onChange={handleChange}
                            />
                          ))}
                        </div>
                        <Form.Control.Feedback type="invalid">
                          <>{errors.salutationName}</>
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-2 no-pl">
                      <Form.Group as={Col} md="6" controlId="firstName">
                        <Form.Label>
                          Vorname<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          className="no-default-form-control"
                          size="sm"
                          type="text"
                          placeholder="Vorname"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          isInvalid={!!touched.firstName && !!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          <>{errors.firstName}</>
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} md="6" controlId="lastName">
                        <Form.Label>
                          Nachname<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          className="no-default-form-control"
                          size="sm"
                          type="text"
                          placeholder="Nachname"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          isInvalid={!!touched.lastName && !!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          <>{errors.lastName}</>
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-2">
                      <Form.Group as={Col} md="12" controlId="email">
                        <Form.Label>
                          E-Mail-Adresse<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          size="sm"
                          type="email"
                          placeholder="Geben Sie Ihr E-Mail-Adresse"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!touched.email && !!errors.email}
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          <>{errors.email}</>
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-2" >
                      <Form.Group as={Col} md="12" className="email-verified" controlId="isEmailVerified">
                        <Form.Check
                          className="custom-checkbox"
                          label="Email Comfirmed"
                          name="isEmailVerified"
                          checked={values.isEmailVerified}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-2">
                      <Form.Group as={Col} md="12" controlId="streetName">
                        <Form.Label>Straße & Hausnummer</Form.Label>
                        <Form.Control
                          className="no-default-form-control"
                          size="sm"
                          type="text"
                          placeholder="Geben Sie Ihr Straße & Hausnummer"
                          name="streetName"
                          value={values.streetName}
                          onChange={handleChange}
                          isInvalid={!!touched.streetName && !!errors.streetName}
                        />
                        <Form.Control.Feedback type="invalid">
                          <>{errors.streetName}</>
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-2 no-pl">
                      <Form.Group as={Col} md="8" controlId="city">
                        <Form.Label>
                          Ort<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          className="no-default-form-control"
                          size="sm"
                          type="text"
                          placeholder="Ort"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          isInvalid={!!touched.city && !!errors.city}
                        />
                        <Form.Control.Feedback type="invalid">
                          <>{errors.city}</>
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} md="4" controlId="zipCode">
                        <Form.Label>
                          PLZ<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          className="no-default-form-control"
                          size="sm"
                          type="text"
                          placeholder="--  --  --  --  --"
                          name="zipCode"
                          value={values.zipCode}
                          minLength={5}
                          maxLength={5}
                          onChange={(e) => {
                            const regex = /^[0-9]+$/;
                            const val = e.target.value;
                            if (val.match(regex) || val === "") {
                              handleChange(e);
                              setZipCodeValidationError(false);
                              if (val.length === 5) {
                                setZipCd(val);
                              }
                            }
                          }}
                          isInvalid={
                            (!!touched.zipCode && !!errors.zipCode) || zipCodeValidationError
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          <>
                            {zipCodeValidationError
                              ? "Die Validierung der Postleitzahl ist fehlgeschlagen"
                              : errors.zipCode}
                          </>
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} md="12" controlId="password">
                        <Form.Label>
                          Passwort<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          size="sm"
                          type="password"
                          placeholder="Geben Sie Ihr Passwort"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!touched.password && !!errors.password}
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          <>{errors.password}</>
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-2">
                      <Form.Group as={Col} md="12" controlId="subscribeForEmailNotifications">
                        <Form.Check
                          label="E-Mail-Benachrichtigungen abonnieren"
                          name="subscribeForEmailNotifications"
                          checked={values.subscribeForEmailNotifications}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mb-5">
                      <Form.Label className="required-pflichtfeld-text">
                        <span className="required-field">*</span> Pflichtfeld
                      </Form.Label>
                    </Row>
                  </div>

                  {userData.isActive === true && (
                    <div className="delete-account">
                      <hr className="divider"></hr>

                      <div className="delete-account-text">Löschen des Kontos</div>

                      <hr className="divider"></hr>

                      <p>
                        Sie werden gebeten, die Löschung des Kontos zu bestätigen. Nach dem Löschen
                        des Kontos können Sie sich nicht mehr Ihren persönlichen
                        Anmeldeinformationen anmelden.
                      </p>

                      <div className="delete-account-button-div">
                        <button
                          className="text-center delete-account-button"
                          type="button"
                          onClick={accountDeletionHandler}
                        >
                          {isDeleteLoading ? <Loader /> : "KONTO LÖSCHEN"}
                        </button>
                      </div>

                      {isAccountDeleted && (
                        <div className="account-deleted-message">
                          Das Konto wurde erfolgreich gelöscht.
                        </div>
                      )}
                    </div>
                  )}

                  <div className="drawer-form-footer">
                    {isUpdateUserError && (
                      <div className="drawer-form-footer-message text-center text-danger mb-2">
                        Beim Aktualisieren eines Benutzers ist etwas schief gelaufen, bitte
                        versuchen Sie es später noch einmal!
                      </div>
                    )}
                    {isUpdateUserSuccess && (
                      <div className="drawer-form-footer-message text-center text-success mb-2">
                        Benutzer erfolgreich aktualisiert!
                      </div>
                    )}
                    <div className="update-user-drawer-form-footer-buttons">
                      <button className="cancel-button" onClick={handleClose}>
                        Abbrechen
                      </button>
                      <button
                        type="submit"
                        className={`update-user-button ${
                          (isAccountDeleted || !!zipCodeValidationError) && "disable-button"
                        }`}
                        disabled={isAccountDeleted || !!zipCodeValidationError}
                      >
                        {isUpdateLoading ? <Loader /> : "Speichern"}
                      </button>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </DrawerComponent>
    </>
  );
};

export default UserDetailsDrawer;
