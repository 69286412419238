// const API_BASE_URL = "https://services-dev.cehub.syngenta-ais.com/";
// const API_BASE_URL = "https://cehubaws-dev.getreideaktuell.syngenta-ais.com";
// const API_BASE_URL = "https://dev.kartoffelprognose.de";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_PUBLIC_URL = 'https://cehubservices-dev.syngenta-ais.com/';
const API_PUBLIC_URL = process.env.REACT_APP_API_PUBLIC_URL;
const API_LOCAL_URL = "https://localhost:7289/";
const TIME_OUT = 60000;

export { API_BASE_URL, API_PUBLIC_URL, API_LOCAL_URL, TIME_OUT };
